import React from "react";
import Carousel from "react-multi-carousel";
import FoodIcon from "Assests/Icons/foodIcon.png";
import SunIcon from "Assests/Icons/sunIcon.png";
import BookIcon from "Assests/Icons/BookIcon.png";
import BuildingIcon from "Assests/Icons/BuildingIcon.png";
import EyeIcon from "Assests/Icons/EyeIcon.png";
import BeatIcon from "Assests/Icons/beatesicon.png";
import DropIcon from "Assests/Icons/dropIcon.png";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import "./SustainableCarousel.css";

const SustainableCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 601 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="Sustainable-carousel">
      <HeadingUnderline bold="Sustainable  " heading="Development Goals" />

      <Carousel
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        transitionDuration={1000}
      >
        <div className="card text-center">
          <div className="card-img card-image-foodIcon">
            <img src={FoodIcon} alt="foodIcon" />
          </div>
          <h4>ZERO HUNGER</h4>
        </div>
        <div className="card text-center">
          <div className="card-img card-image-BeatIcon">
            <img src={BeatIcon} alt="BeatIcon" />
          </div>
          <h4>
            GOOD HEALTH
            <br />& WEALTH-BEING
          </h4>
        </div>
        <div className="card text-center">
          <div className="card-img card-image-bookIcon">
            <img src={BookIcon} alt="bookIcon" />
          </div>
          <h4>
            QUALITY
            <br />
            EDUCATION
          </h4>
        </div>
        <div className="card text-center">
          <div className="card-img card-image-DropIcon">
            <img src={DropIcon} alt="DropIcon" />
          </div>
          <h4>
            CLEAN WATER
            <br />& SANITATION
          </h4>
        </div>
        <div className="card text-center">
          <div className="card-img card-image-sunIcon">
            <img src={SunIcon} alt="sunIcon" />
          </div>
          <h4>
            AFFORDABLE
            <br />& CLEAN ENERGY
          </h4>
        </div>
        <div className="card text-center">
          <div className="card-img card-image-buildingIcon">
            <img src={BuildingIcon} alt="buildingIcon" />
          </div>
          <h4>
            SUSTAINABLE CITIES
            <br />& COMMUNITIES
          </h4>
        </div>
        <div className="card text-center">
          <div className="card-img card-image-eyeIcon">
            <img src={EyeIcon} alt="eyeIcon" />
          </div>
          <h4>
            CLIMATE
            <br />
            ACTION
          </h4>
        </div>
      </Carousel>
    </div>
  );
};

export default SustainableCarousel;
