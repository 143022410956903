import "./QuizPopUp.css";
import React, { useState } from "react";
import quiz_model_top from "Assests/Images/quiz_model_top.png";
import quiz_model_bottom from "Assests/Images/quiz_model_bottom.png";
import ButtonIcon from "Assests/Icons/button.svg";
import crossMark from "Assests/Icons/cross-mark.png";
import cross from "Assests/Icons/cross.png";
import correct from "Assests/Icons/correct.png";
import withoutSmile from "Assests/Icons/withoutSmile.png";
import smile from "Assests/Icons/smile.png";
import slightlyface from "Assests/Icons/slightly-frowning-face.gif";
import slightlysmile from "Assests/Icons/slightly-smiling-face.gif";
import clapping from "Assests/Icons/clapping.gif";
import congratulation from "Assests/Images/congratulation.png";
import { quizAttempt, retakeQuizAttempt } from "API/post.api";
import { fetchAllQuizResult, fetchAllQuestionQuiz } from "API/get.api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "Components/Button/Button";

const QuizPopUp = ({
  model,
  setModel,
  question,
  setQuestion,
  quizList,
  activeQ,
  setActiveQ,
}) => {
  const token = localStorage.getItem("token");
  const option = ["30 minutes", "60 minutes", "10 minutes", "20 minutes"];
  const [activeAns, setActiveAns] = useState(null);
  const [success, setSuccess] = useState(false);
  const [congrates, setCongrates] = useState(false);
  const [activeKey, setActiveKey] = useState(0);
  const [btnStatus, setBtnStatus] = useState("submit");
  const [allResultData, setAllResultData] = useState(null);

  const user = useSelector((state) => state?.setUserDataReducer);

 
  // const ans = 2;

  const questionSubmit = () => {
    if (!activeAns) {
      toast("Please Select an Option", { type: "warning" });
      return;
    }
    const data = {
      quizQuest: question[activeKey].id,
      correct: activeAns == question[activeKey]?.answer,
    };
    const apiStatus = quizAttempt(data, token);
    apiStatus
      .then((response) => {
        // toast("Logged In Successful", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
        toast(err?.response?.data?.detail, { type: "error" });
      });
    setBtnStatus("next");
  };

  const nextStep = () => {
    if (activeKey < question.length - 1) {
      setActiveKey(activeKey + 1);
      setBtnStatus("submit");
      setActiveAns(null);
    } else {
      const apiStatus = fetchAllQuizResult(
        question[activeKey]?.quiz?.id,
        user?.id,
        token
      );
      apiStatus
        .then((response) => {
          setAllResultData(response.data);
          if (response?.data?.true_attempt == 5) {
            setCongrates(true);
          } else {
            setSuccess(true);
          }
          // toast("Logged In Successful", { type: "success" });
        })
        .catch((err) => {
          console.log(err);
          toast(err?.response?.data?.detail, { type: "error" });
        });
    }
    // setSuccess(true);
  };

  const retakeQuiz = () => {
    // const data = {
    //   quiz_id: question[activeKey]?.quiz?.id,
    //   created_by: user?.id,
    // };
    const apiStatus = retakeQuizAttempt(
      question[activeKey]?.quiz?.id,
      user?.id,
      token
    );
    apiStatus
      .then((response) => {
        const apiStatus = fetchAllQuestionQuiz(
          question[activeKey]?.quiz?.id,
          token
        );
        apiStatus
          .then((response) => {
            setQuestion(response.data);
            setActiveKey(0);
            setBtnStatus("submit");
            setActiveAns(null);
            setSuccess(false);
            setCongrates(false);
            // toast("Logged In Successful", { type: "success" });
          })
          .catch((err) => {
            console.log(err);
            toast(err?.response?.data?.detail, { type: "error" });
          });
      })
      .catch((err) => {
        console.log(err);
        toast(err?.response?.data?.detail, { type: "error" });
      });
  };

  const takeAnotherQuiz = () => {
    let num = activeQ
    if (activeQ < 5) {
      num = activeQ + 1;
      setActiveQ(activeQ + 1);
    } else {
      num = 0;
      setActiveQ(0);
    }
    const apiStatus = fetchAllQuestionQuiz(quizList[num]?.id, token);
    apiStatus
      .then((response) => {
        if (response.data.length > 0) {
          setQuestion(response.data);
          setActiveKey(0);
          setBtnStatus("submit");
          setActiveAns(null);
          setSuccess(false);
          setCongrates(false);
        } else {
          toast("More Quiz not Available", { type: "warning" });
          setModel(false);
        }

        // toast("Logged In Successful", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
        toast(err?.response?.data?.detail, { type: "error" });
        setModel(false);
      });
  };


  const OnSuccess = () => {
    return (
      <div className="quiz-sucess">
        <h1>
          Final <span style={{ fontWeight: "600" }}>Score!</span>
        </h1>
        <div className="ans-correct-wrapper">
          <div>
            <span>{allResultData?.true_attempt} Answers Are Correct</span>
            <img src={slightlysmile} alt="smile" />
          </div>
          <div>
            <span>{allResultData?.false_attempt} Answer Is Incorrect</span>
            <img src={slightlyface} alt="withoutsmile" />
          </div>
        </div>
        <div className="description">
          <p>Note :</p>

          <div>
            Participate in the quiz for{" "}
            <span style={{ color: "#ec0c6e" }}>10 Days </span>or{" "}
            <span style={{ color: "#ec0c6e" }}>30 Days </span>
            regularly to gain more knowledge and{" "}
            <span style={{ color: "#ec0c6e" }}>win interesting goodie</span>
          </div>
        </div>
        <div className="btn-Wrapper">
          <div onClick={retakeQuiz}>
            <Button
              height={"2.6vmax"}
              title="RETAKE QUIZ"
              background={"#f7941d"}
              width={"11vmax"}
              padding={"3.5%"}
              type="button"
              fontSize=".8vmax"
            />
          </div>
          {/* <button onClick={retakeQuiz} className="btn-background">
            <img src={ButtonIcon} alt="button" />
            <p>RETAKE QUIZ</p>
          </button> */}
          {/* <button onClick={takeAnotherQuiz} className="btn-background">
            <img src={ButtonIcon} alt="button" />
            <p>TAKE ANOTHER QUIZ</p>
          </button> */}
          <div onClick={takeAnotherQuiz}>
            <Button
              height={"2.6vmax"}
              title="TAKE ANOTHER QUIZ"
              background={"white"}
              width={"11vmax"}
              padding={"3.5%"}
              type="button"
              fontSize=".8vmax"
              color="#f7941d"
            />
          </div>
        </div>
      </div>
    );
  };

  const onCongratulation = () => {
    return (
      <div className="congratulation">
        <img src={congratulation} alt="congratulation" />
        <img
          onClick={() => setModel(false)}
          src={crossMark}
          className="crossmark"
          alt="crossmark"
        />
        <div className="congrates-content">
          <h1>Congratulations!</h1>
          <div className="all-answer">
            <span>All Answers Are Correct</span>
            <img className="smile" src={clapping} alt="smile" />
          </div>
          <div className="description">
            <p>Note :</p>
            <div>
              Continue participating in the quiz for next{" "}
              <span style={{ fontWeight: "600" }}>10 Days or 30 Days </span>
              regularly to gain more knowledge and
              <span style={{ color: "#f7941d" }}> win interesting goodie</span>
            </div>
          </div>
          <div className="btn-Wrapper">
            <div onClick={retakeQuiz}>
              <Button
                height={"2.6vmax"}
                title="RETAKE QUIZ"
                background={"#f7941d"}
                width={"11vmax"}
                padding={"3.5%"}
                type="button"
                fontSize=".8vmax"
              />
            </div>
            <div onClick={takeAnotherQuiz}>
              <Button
                height={"2.6vmax"}
                title="TAKE ANOTHER QUIZ"
                background={"white"}
                width={"11vmax"}
                padding={"3.5%"}
                type="button"
                fontSize=".8vmax"
                color="#f7941d"
              />
            </div>
            {/* <button onClick={retakeQuiz} className="btn-background">
              <img src={ButtonIcon} alt="button" />
              <p>RETAKE QUIZ</p>
            </button>
            <button onClick={takeAnotherQuiz} className="btn-background">
              <img src={ButtonIcon} alt="button" />
              <p>TAKE ANOTHER QUIZ</p>
            </button> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="quiz-model-parent">
      {!congrates ? (
        <div className="quiz-model">
          <img src={quiz_model_top} alt="quiz_model_top" />
          <img src={quiz_model_bottom} alt="quiz_model_bottom" />
          <img
            onClick={() => setModel(false)}
            src={crossMark}
            className="crossmark"
            alt="crossmark"
          />
          {!success ? (
            <div className="content">
              <h1>{question[activeKey]?.quiz?.quiz_title || "No Title"}</h1>
              <div className="quiz-wrapper">
                <div className="question">
                  {question[activeKey]?.question}
                  <span class="q-no">{`Q${activeKey + 1}.`}</span>
                  {/* How many minutes should an average person walk a day? */}
                </div>
                {/* <span class="q-no">{`Q${activeKey + 1}.`}</span> */}

                <div className="option-wrapper">
                  {/* {option.map((item, i) => (
                    <span
                      className={`${
                        (activeAns == ans) & (activeAns == i)
                          ? "right"
                          : activeAns == i && activeAns != ans
                          ? "wrong"
                          : i == activeAns
                          ? "correct"
                          : ""
                      }`}
                      onClick={() => setActiveAns(i)}
                    >
                      {item}
                    </span>
                  ))} */}
                  <span
                    className={`${
                      activeAns == question[activeKey]?.option_1 &&
                      btnStatus != "next"
                        ? "correct"
                        : activeAns != question[activeKey]?.answer &&
                          question[activeKey]?.option_1 == activeAns &&
                          btnStatus == "next"
                        ? "wrong"
                        : question[activeKey]?.answer ==
                            question[activeKey]?.option_1 && btnStatus == "next"
                        ? "right"
                        : ""
                    }`}
                    onClick={() =>
                      btnStatus != "next" &&
                      setActiveAns(question[activeKey]?.option_1)
                    }
                  >
                    {question[activeKey]?.option_1}
                  </span>

                  <span
                    className={`${
                      activeAns == question[activeKey]?.option_2 &&
                      btnStatus != "next"
                        ? "correct"
                        : activeAns != question[activeKey]?.answer &&
                          question[activeKey]?.option_2 == activeAns &&
                          btnStatus == "next"
                        ? "wrong"
                        : question[activeKey]?.answer ==
                            question[activeKey]?.option_2 && btnStatus == "next"
                        ? "right"
                        : ""
                    }`}
                    onClick={() =>
                      btnStatus != "next" &&
                      setActiveAns(question[activeKey]?.option_2)
                    }
                  >
                    {question[activeKey]?.option_2}
                  </span>
                  <span
                    className={`${
                      activeAns == question[activeKey]?.option_3 &&
                      btnStatus != "next"
                        ? "correct"
                        : activeAns != question[activeKey]?.answer &&
                          question[activeKey]?.option_3 == activeAns &&
                          btnStatus == "next"
                        ? "wrong"
                        : question[activeKey]?.answer ==
                            question[activeKey]?.option_3 && btnStatus == "next"
                        ? "right"
                        : ""
                    }`}
                    onClick={() =>
                      btnStatus != "next" &&
                      setActiveAns(question[activeKey]?.option_3)
                    }
                  >
                    {question[activeKey]?.option_3}
                  </span>

                  <span
                    className={`${
                      activeAns == question[activeKey]?.option_4 &&
                      btnStatus != "next"
                        ? "correct"
                        : activeAns != question[activeKey]?.answer &&
                          question[activeKey]?.option_4 == activeAns &&
                          btnStatus == "next"
                        ? "wrong"
                        : question[activeKey]?.answer ==
                            question[activeKey]?.option_4 && btnStatus == "next"
                        ? "right"
                        : ""
                    }`}
                    onClick={() =>
                      btnStatus != "next" &&
                      setActiveAns(question[activeKey]?.option_4)
                    }
                  >
                    {question[activeKey]?.option_4}
                  </span>
                </div>
                {btnStatus == "next" && (
                  <div className="description">
                    <span>Answer Detail: </span>
                    {question[activeKey]?.answer_detail || " No Details"}
                  </div>
                )}

                {btnStatus == "submit" ? (
                  <div onClick={questionSubmit} className="btn-background">
                    <Button
                      height={"2.6vmax"}
                      title="SUBMIT"
                      background={"#f7941d"}
                      width={"200px"}
                      padding={"3.5%"}
                      type="button"
                      fontSize=".8vmax"
                    />
                    {/* <img src={ButtonIcon} alt="button" />
                    <p>SUBMIT</p> */}
                  </div>
                ) : (
                  <div onClick={nextStep} className="btn-background">
                    <Button
                      height={"2.6vmax"}
                      title="NEXT STEP"
                      background={"#f7941d"}
                      width={"200px"}
                      padding={"3.5%"}
                      type="button"
                      fontSize=".8vmax"
                    />
                    {/* <img src={ButtonIcon} alt="button" />
                    <p>NEXT STEP</p> */}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>{OnSuccess()}</div>
          )}
        </div>
      ) : (
        <div>{onCongratulation()}</div>
      )}
    </div>
  );
};

export default QuizPopUp;
