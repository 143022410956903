import axios from "axios";
let host = "http://3.6.49.72/";
if (window.location.origin.includes("www.blue-nudge.com")) {
  host = "https://backend.blue-nudge.com/";
}
let httpPathName = axios.create({
  // baseURL: "http://3.6.49.72/",
  baseURL: host,
  withCredentials: false,
});

// httpPathName.interceptors.response?.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error?.response?.data?.error == "jwt expired") {
//       store.dispatch(signout());
//       store.dispatch(setAuthenticationModel(true))
//     }

//     return error;
//   }
// );

export default httpPathName;
