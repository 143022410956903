import React, { useEffect } from "react";
import "./AboutUs.css";
import aboutBanner from "Assests/Images/Aboutusbanner.png";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import Footer from "Components/Footer/Footer";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import bookTree from "Assests/Images/bookTree.png";
import bluePlanet from "Assests/Icons/blue-planet-logo.svg";
import rightArrow from "Assests/Icons/right-arrow-svgrepo-com.svg";
import aboutBookTree from "Assests/Images/aboutBookTree.png";
import handHoldingEarth from "Assests/Images/blue-planet-logo.svg";
import aboutBackground from "Assests/Icons/aboutBackground.png";
import TestimonialCarousel from "Components/Carousel/3DCarousel/TestimonialCarousel/TestimonialCarousel";
import { useLocation } from "react-router-dom";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";
import TestimonialCarousal2 from "Components/Carousel/3DCarousel/TestimonialCarousel/TestimonialCarousal2";
import Button from "Components/Button/Button";
import blueplanet2024 from "Assests/Icons/blueplanet-logo-2024.svg";

const AboutUs = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const openWebsite = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="about-us">
      <Header />
      <HeadingBanner
        image={aboutBanner}
        heading="About Us"
        detail="Empowering Change: An Ed Act (Education to Action) Platform Committed to Sustainability, Environmental Awareness, Innovative Waste Management Technologies, and Nudging Students Towards a Greener Future by focusing on Behavioural Change and Community Engagement.
        "
      />

      <div className="book-tree">
        <div className="left">
          <img src={bookTree} alt="book tree" className="overlay" />
          <img src={aboutBookTree} alt="background" />
        </div>
        <div className="right">
          <HeadingUnderline bold="Blue " heading="Nudge" align="left" />

          <p>
            Blue Nudge is an Education to Action (EdAct) platform that focuses
            on community engagement and behavioral change to address
            environmental challenges. We aim at instilling a sense of
            responsibility in students towards their planet and environment and
            encourage them to take up sustainability as a way of living.
          </p>

          <p>
            Blue Nudge intends to integrate waste management, sustainability and
            academic curriculums. It is a simple yet effective way to reach the
            basic unit of a society, a household. Through these
            students/learners/individuals connect with their families to
            encourage sustainable living and better management of waste.
          </p>
        </div>
      </div>

      <div className="blue-planet">
        <div className="left">
          <img src={blueplanet2024} alt="book tree" className="overlay" />
          <img src={aboutBackground} alt="background" />
          {/* blueplanet2024 */}
        </div>
        <div className="right">
          <HeadingUnderline
            bold="Blue Planet  "
            heading=" Environmental Solutions"
            align="left"
          />

          {/* <p>
            Founded in 2017 and headquartered in Singapore, Blue Planet
            Environmental Solutions is one of Asia’s fastest-growing waste
            management companies. We lead the regional waste management markets,
            deploying technologies spanning the entire waste management value
            chain.
          </p> */}
          <p>
            Founded in 2017 and headquartered in Singapore, Blue Planet
            Environmental Solutions is one of Asia’s fastest-growing waste
            management companies. We lead the regional waste management markets,
            deploying technologies spanning the entire waste management value
            chain. Our milestones characterise our journey: from a humble
            beginning in 2017 to become the leading regional player today.{" "}
          </p>

          <p>
            In a short span, we have steadily expanded through organic growth
            and acquisitions. Our company strategy reflects our legacy and our
            continuing commitment to meet the needs of our customers and the
            communities in which we live and work. Blue Planet will contribute
            to fifteen of the seventeen United Nations Sustainable Development
            Goals either directly or indirectly
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <img src={bluePlanet} alt="bluePlanet" className="blueplanet" /> */}
            <div
              className="btn brn-primary"
              onClick={() => openWebsite("https://blueplanet.asia/")}
            >
              <Button
                height={window.innerWidth < 991 ? "auto" : "2vw"}
                title="Go to Website"
                background={"#FFA500"}
                width={window.innerWidth < 991 ? "fit-cotent" : "10vmax"}
                padding={window.innerWidth < 991 ? "0.5rem 1rem" : "3.5%"}
                type="button"
                fontSize={
                  window.innerWidth < 991 ? "var(--base-fz)" : "0.8vmax"
                }
                color="#fff"
              />
            </div>
            {/* <a style={{color:"blue",alignItems:"center",justifyContent:'center',position:"relative",fontSize:"0.83vmax"}} href="https://blueplanet.asia/" target="blank">
              Go to the website  <span>&#8594;</span>
            </a> */}
          </div>
        </div>
      </div>

      {/* <TestimonialCarousel /> */}
      <TestimonialCarousal2 />

      <Footer />
    </div>
  );
};

export default AboutUs;
