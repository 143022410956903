// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .Offering-4 .card_container .card .card-body p {
    max-height: 140px;
} */

.Offering .About-us .About-us-contaian p {
    font-size: 0.875rem;
    text-align: justify;
}

@media(min-width: 991px) {
    .Offering-4 .card_container .card .card-body p {
        max-height: 140px;
        overflow-y: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Offering/Offering4.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI;QACI,iBAAiB;QACjB,gBAAgB;IACpB;AACJ","sourcesContent":["/* .Offering-4 .card_container .card .card-body p {\n    max-height: 140px;\n} */\n\n.Offering .About-us .About-us-contaian p {\n    font-size: 0.875rem;\n    text-align: justify;\n}\n\n@media(min-width: 991px) {\n    .Offering-4 .card_container .card .card-body p {\n        max-height: 140px;\n        overflow-y: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
