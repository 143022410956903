// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* 

  .card-item .upper p,
.card-item .upper b {
  font-size: 14px !important;
} */
 
/* .card-item img {
  object-fit: cover;
  width: 100% !important;
  border-radius: 8px;
  
}
.card-item {
  position: relative;
 
  width: 100% !important;
  height: 300px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
} */

/* .members-list .card-item img {
  object-fit: cover;
  width: 100% !important;
  border-radius: 8px;}

  .members-list .card-item {
    position: relative;
   
    width: 100% !important;
    height: 280px !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 10px;
  } 
  
  .members-list .content{ padding: 10px !important;}
  .members-list .content img{ width: 22px !important; height: 22px !important;} */
 `, "",{"version":3,"sources":["webpack://./src/Components/Carousel/MultiCarousel/OurTeamCarousel/OurTeamCarousel.css"],"names":[],"mappings":";;AAEA;;;;;GAKG;;AAEH;;;;;;;;;;;;;GAaG;;AAEH;;;;;;;;;;;;;;;iFAeiF","sourcesContent":["\n\n/* \n\n  .card-item .upper p,\n.card-item .upper b {\n  font-size: 14px !important;\n} */\n \n/* .card-item img {\n  object-fit: cover;\n  width: 100% !important;\n  border-radius: 8px;\n  \n}\n.card-item {\n  position: relative;\n \n  width: 100% !important;\n  height: 300px !important;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);\n  border-radius: 10px;\n} */\n\n/* .members-list .card-item img {\n  object-fit: cover;\n  width: 100% !important;\n  border-radius: 8px;}\n\n  .members-list .card-item {\n    position: relative;\n   \n    width: 100% !important;\n    height: 280px !important;\n    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);\n    border-radius: 10px;\n  } \n  \n  .members-list .content{ padding: 10px !important;}\n  .members-list .content img{ width: 22px !important; height: 22px !important;} */\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
