import React, { useEffect, useState } from "react";
import "./OurTeam.css";
import aboutBanner from "Assests/Images/OurTeamBanner.png";
import linkdln from "Assests/Icons/icons8-linkedin.svg";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import Header from "Components/Header/Header";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import Footer from "Components/Footer/Footer";
import Pagination from "Components/Pagination/Pagination";
import { getTeamMember } from "API/get.api";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";
import OurTeamcar from "Components/Carousel/MultiCarousel/OurTeamCarousel/OurTeamCarousel";
import PaginationTwo from "Components/Pagination/PaginationTwo";
import { Link } from "react-router-dom";

const OurTeam = () => {
  const [teamList, setTeamList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(8);

  useEffect(() => {
    const apiStatus = getTeamMember();
    apiStatus
      .then((response) => {
        setTeamList(response?.data);
        // toast("Logged In Successful", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ismobel = () => {
    return (
      <div className="isMobile">
        <OurTeamcar />
      </div>
    );
  };

  const CardItem = ({
    image,
    firstName,
    lastName,
    desgination,
    linkdlnLink,
  }) => {
    return (
      <div className="card-item">
        <img src={image} alt="member" />

        <div className="content">
          <div className="content-top">
            <div className="content-left">
              <HeadingUnderline
                bold={firstName}
                heading={lastName}
                align="left"
              />
            </div>
            <div className="content-right">
              <Link to={linkdlnLink !== '' || linkdlnLink !== null? linkdlnLink: '/'} target="_blank">

                
              <img
                src={linkdln}
                alt="linkdln"
                />
                </Link>
            </div>
          </div>

          <div className="content-bottom">
            <p>{desgination}</p>
          </div>
        </div>
      </div>
    );
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = teamList.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(teamList.length / recordsPerPage);

  return (
    <div className="about-us our-team">
      <Header />
      {/* {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="Our Team"/>)} */}
      <HeadingBanner
        image={aboutBanner}
        heading="Our Team"
        detail="Meet the Heartbeats: Dedicated Individuals Crafting Sustainable  Futures, One Team, One 
        Dream"
      />

      {window.innerWidth > 600 ? (
        <div className="members-list">
          <HeadingUnderline bold="Team " heading="Members" />
          {currentRecords?.length > 0 ? (
            <div className="wrapper">
              {currentRecords?.map((item, i) => (
                <CardItem
                  key={i}
                  image={item.image}
                  firstName={item?.name.split(" ")[0] + " "}
                  lastName={item?.name.split(" ")[1]}
                  desgination={item?.designation}
                  linkdlnLink={item?.linkedin_url}
                />
              ))}
            </div>
          ) : (
            <div className="no-data-found">No Data Found</div>
          )}
          {/* <Pagination  /> */}
          <PaginationTwo
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      ) : (
        ismobel()
      )}

      <Footer />
    </div>
  );
};

export default OurTeam;
