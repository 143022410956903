import React from "react";
import "./ParticipatingSchoolCarousel.css";
import Carousel from "react-multi-carousel";
import SchoolIcon1 from "Assests/Images/icons/offerings-4/participating-schools/img-1.png";
import SchoolIcon2 from "Assests/Images/icons/offerings-4/participating-schools/img-2.png";
import SchoolIcon3 from "Assests/Images/icons/offerings-4/participating-schools/img-3.png";
import SchoolIcon4 from "Assests/Images/icons/offerings-4/participating-schools/img-4.png";

import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";

const ParticipatingSchoolCarouselOPFour = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="School-carousel school-carousel-eco-xr">
      <HeadingUnderline bold="Participating " heading="Schools" />

      <Carousel
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        transitionDuration={1000}
        partialVisbile={true}
      // centerMode={true}
      >
        <div className="school-carousel-logo">
          <img src={SchoolIcon1} alt="SchoolIcon1" width="200px" />
        </div>
        <div className="school-carousel-logo">
          <img src={SchoolIcon2} alt="SchoolIcon1" width="200px" />
        </div>
        <div className="school-carousel-logo">
          <img src={SchoolIcon3} alt="SchoolIcon1" width="200px" />
        </div>
        <div className="school-carousel-logo">
          <img src={SchoolIcon4} alt="SchoolIcon1" width="200px" />
        </div>
      </Carousel>
    </div>
  );
};

export default ParticipatingSchoolCarouselOPFour;
