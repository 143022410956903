import React, { useState, useEffect } from "react";
import "./TestimonialCarousal2.css";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import Carousel from "react-multi-carousel";
import latestGalleryImg1 from "Assests/Images/latestGalleryImg1.png";
import { fetchPlanetwarrior } from "API/get.api";
import { useNavigate } from "react-router-dom";

const TestimonialCarousal2 = () => {
  const navigate = useNavigate();
  const [planetWarriorList, setplantWarriorList] = useState([]);

  useEffect(() => {
    const apiStatus = fetchPlanetwarrior();
    apiStatus
      .then((response) => {
        setplantWarriorList(response.data);
        // toast("fetch sucess full", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 601 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 0, // optional, default to 1.
    },
  };
  return (
    <div className="testimonial-carousel2">
      <HeadingUnderline bold="Planet Warriors" />
      <div className="wrapper">
        <Carousel
          draggable={false}
          centerMode={window.innerWidth < 1024 ? false : true}
          responsive={responsive}
          infinite={true}
          arrows={false}
          autoPlay={true}
          partialVisbile={false}
          autoPlaySpeed={500}
          transitionDuration={1000}
        >
          {planetWarriorList.map((item, i) => (
            <div
              className="card"
              key={i}
              onClick={() => navigate("/planet-warrior")}
              style={{ cursor: "pointer" }}
            >
              <div className="image-wrapper">
                <img src={item?.image} alt={item?.name} />
                <div>
                  <p>{item?.name}</p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default TestimonialCarousal2;
