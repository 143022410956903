import React from "react";
import "./MethologyCarouel.css";
import Carousel from "react-multi-carousel";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import MethodologyIcon1 from "Assests/Icons/MethodologyIcon1.png";
import MethodologyIcon2 from "Assests/Icons/MethodologyIcon2.png";
import MethodologyIcon3 from "Assests/Icons/MethodologyIcon3.png";
import MethodologyIcon4 from "Assests/Icons/MethodologyIcon4.png";
import MethodologyIcon5 from "Assests/Icons/MethodologyIcon5.png";
import MethodologyIcon6 from "Assests/Icons/MethodologyIcon6.png";

const MethodologyCarousel = () => {
  const responsive = {
    desktoplG: {
      breakpoint: { max: 3000, min: 992 },
      items: 6,
      slidesToSlide: 1, // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 991, min: 768 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 767, min: 465 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="MethodologyCarousel">
      <HeadingUnderline bold="Methodology " heading="and Assessment" />

      <Carousel
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        transitionDuration={1000}
      >
        <div className="card">
          <div className="imgcontainer">
            <img src={MethodologyIcon1} alt="Saral" />
          </div>
          <h4>
            Complements <br />
            Curriculum
          </h4>
        </div>
        <div className="card">
          <div className="imgcontainer">
            <img src={MethodologyIcon2} alt="Saral" />
          </div>
          <h4>
            Training
            <br />
            Workshops
          </h4>
        </div>
        <div className="card">
          <div className="imgcontainer">
            <img src={MethodologyIcon3} alt="Saral" />
          </div>
          <h4>
            Friendly
            <br />
            Nudges
          </h4>
        </div>
        <div className="card">
          <div className="imgcontainer">
            <img src={MethodologyIcon4} alt="Saral" />
          </div>
          <h4>
            Blue Nudge
            <br />
            Ambassadors
          </h4>
        </div>
        <div className="card">
          <div className="imgcontainer">
            <img src={MethodologyIcon5} alt="Saral" />
          </div>
          <h4>
            Self-paced
            <br />
            Modules
          </h4>
        </div>
        <div className="card">
          <div className="imgcontainer">
            <img src={MethodologyIcon6} alt="Saral" />
          </div>
          <h4>
            Performance
            <br />
            Review
          </h4>
        </div>
      </Carousel>
    </div>
  );
};

export default MethodologyCarousel;
