import React from "react";
import "./InformationCarousel.css";
import Handshake from "Assests/Icons/handshake.svg";
import Community from "Assests/Icons/community.svg";
import Letter from "Assests/Icons/letter.svg";
import ambassdor from 'Assests/Icons/blue_ambassdor.png'
import teacherTrained from "Assests/Icons/teacher_trained.png"
import Carousel from "react-multi-carousel";
import OrangeCircle from "Assests/Icons/orangeCircle.svg";
import collegeOnboarded from "Assests/Icons/college_onboarded.svg"

const InformationCarousel = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1199 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    desktop: {
      breakpoint: { max: 1199, min: 769 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 768, min: 577 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const CardItem = ({ icon, number, tag }) => {
    return (
      <div className="card-item">
        <div className="position-relative">
          <img src={icon} alt={tag} />
          <img src={OrangeCircle} alt="circle" className="background" />
        </div>

        <div>
          <p className="big">{number}</p>
          <p>{tag}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="info-carousel">
       {/* { (window.innerWidth<600) ? (
      {(window.innerWidth < 600) ? (
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <CardItem icon={Community} number="1500+" tag="Schools On-board" />
          <CardItem icon={collegeOnboarded} number="25+" tag="Universities / Colleges onboarded" />
          <CardItem icon={teacherTrained} number="5500+" tag="Teachers Trained" />

          <CardItem icon={ambassdor} number="8500+" tag="Blue Nudge Ambassadors" />
          <CardItem icon={Letter} number="1 Million+" tag="Students Nudged" />


          <CardItem icon={Handshake} number="10+" tag="Corporates onboarded" />
        </div>
      ): ( */}
      {/* <Carousel */}
      {/* ) : ( */}
        <Carousel
        draggable={false}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={2500}
        transitionDuration={1000}
      >
        {/* <CardItem icon={Handshake} number="7" tag="Corporate participated" />
        <CardItem icon={Community} number="2106" tag="Schools On-board" />
        <CardItem icon={Letter} number="306k+" tag="Corporate participated" /> */}

        <CardItem icon={Community} number="1500+" tag="Schools On-board" />
        <CardItem icon={collegeOnboarded} number="25+" tag="Universities / Colleges onboarded" />
        <CardItem icon={teacherTrained} number="5500+" tag="Teachers Trained" />

        <CardItem icon={ambassdor} number="8500+" tag="Blue Nudge Ambassadors" />
        <CardItem icon={Letter} number="1 Million+" tag="Students Nudged" />


        <CardItem icon={Handshake} number="10+" tag="Corporates onboarded" />

      </Carousel>
      
    </div>
  );
};

export default InformationCarousel;
