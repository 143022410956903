import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import testimonialSubSection from "Assests/Icons/testimonialSubSection.svg";
import charu from "Assests/Images/charu.jpg"
import rupa from "Assests/Images/rupa.jpg"
import React, { useEffect, useRef, useState } from "react";
import "./TestimonialCarousel.css";

const TestimonialCarousel = () => {
  const intervalRef = useRef(null);
  const testimonialUser1 =
    "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80";
  const sliderArr = [
    // {
    //   userImage: charu,
    //   name: "Ms. Charu Maini",
    //   review: (
    //     <div>
    //       This Blue Nudge Initiative which has been brought by Blue Planet
    //       Environmental Solutions to the school students is I would say a really
    //       a wonderful initiative because I would it is like a chain reaction
    //       that has set that has been set up from students to parents and from
    //       parents to Society at Large. The Blue Nudge initiative of Blue Planet
    //       organization is already doing a wonderful job of converting waste to
    //       benches. The blue nudge program has really helped in sensitizing our
    //       students towards their roles and responsibilities to save the planet
    //       Earth and we from DAV organization following the Vedic Culture that we
    //       know it from thousands of years highlighting that the human existence
    //       is possible only and only if we are coexisting with the biotic and
    //       abiotic components but we had forgotten this and Blue Nudge has again
    //       brought this to the students. We thank Blue Nudge for nudging students
    //       to come back and unite with this environment.
    //     </div>
    //   ),
    // },
    // {
    //   userImage: rupa,
    //   name: "Ms. Rupa Chakravarty",
    //   review: (
    //     <div>
    //       Let me Begin by first congratulating Blue Nudge which is a part of
    //       Blue Planet Environmental Solutions for starting this initiative. The
    //       world needs it. The planet is suffering and who better than children
    //       to bring about this awareness amongst adults and the community. It
    //       reminds me of an axiom which says that we have not inherited the Earth
    //       but we have borrowed it from our children and thus if children or the
    //       students along with adults coming together, they make sure that the
    //       community works as one to implement this only then it is going to be a
    //       beautiful Blue Planet that we can envisage in the future.
    //     </div>
    //   ),
    // },
    // {
    //   userImage: charu,
    //   name: "Ms. Charu Maini",
    //   review: (
    //     <div>
    //       This Blue Nudge Initiative which has been brought by Blue Planet
    //       Environmental Solutions to the school students is I would say a really
    //       a wonderful initiative because I would it is like a chain reaction
    //       that has set that has been set up from students to parents and from
    //       parents to Society at Large. The Blue Nudge initiative of Blue Planet
    //       organization is already doing a wonderful job of converting waste to
    //       benches. The blue nudge program has really helped in sensitizing our
    //       students towards their roles and responsibilities to save the planet
    //       Earth and we from DAV organization following the Vedic Culture that we
    //       know it from thousands of years highlighting that the human existence
    //       is possible only and only if we are coexisting with the biotic and
    //       abiotic components but we had forgotten this and Blue Nudge has again
    //       brought this to the students. We thank Blue Nudge for nudging students
    //       to come back and unite with this environment.
    //     </div>
    //   ),
    // },
    // {
    //   userImage: rupa,
    //   name: "Ms. Rupa Chakravarty",
    //   review: (
    //     <div>
    //       Let me Begin by first congratulating Blue Nudge which is a part of
    //       Blue Planet Environmental Solutions for starting this initiative. The
    //       world needs it. The planet is suffering and who better than children
    //       to bring about this awareness amongst adults and the community. It
    //       reminds me of an axiom which says that we have not inherited the Earth
    //       but we have borrowed it from our children and thus if children or the
    //       students along with adults coming together, they make sure that the
    //       community works as one to implement this only then it is going to be a
    //       beautiful Blue Planet that we can envisage in the future.
    //     </div>
    //   ),
    // },
    {
      userImage: testimonialUser1,
      name: "1",
      review:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      userImage: testimonialUser1,
      name: "2",
      review:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      userImage: testimonialUser1,
      name: "3",
      review:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      userImage: testimonialUser1,
      name: "4",
      review:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ];
  const angle = 360 / sliderArr?.length;
  const [isMouseHover, setisMouseHover] = useState(false);
  const [rotationVar, setRotationVar] = useState(0);

  useEffect(() => {
    rotationFn();
  }, [rotationVar]);

  //   autoplay && stop on mouse hover
  useEffect(() => {
    if (!isMouseHover) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(() => {
        setRotationVar((prev) => prev + angle);
      }, 2000);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [rotationVar, isMouseHover]);

  const rotationFn = () => {
    const carouselElement = document.querySelector(".testi-carousel");
    const itemElement = document.querySelectorAll(".testi-item");

    if (carouselElement)
      carouselElement.style.transform = `rotateY(-${Math.abs(rotationVar)}deg)`;
    if (itemElement) {
      for (let i = 0; i < itemElement.length; i++) {
        itemElement[i].style.transform = `rotateY(${Math.abs(rotationVar)}deg)`;
      }
    }
  };

  const CardItem = ({ data }) => {
    return (
      <div className="card-item">
        <img src={data?.userImage} alt="User" className="overlay" />
        <img src={testimonialSubSection} alt="Background" />
        <div className="content">
          <p className="heading">{data?.name || "No Name"}</p>
          <p className="review">{data?.review || "No Review"}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="testimonial-carousel">
      <HeadingUnderline bold="Testimonials" />

      <div
        className="container"
        onMouseEnter={() => setisMouseHover(true)}
        onMouseLeave={() => setisMouseHover(false)}
      >
        <div className="testi-carousel">
          {sliderArr?.map((cur, idx) => (
            <div
              style={{
                transform: `rotateY(${
                  angle * idx
                }deg) translateZ(450px) rotateY(-${angle * idx}deg)`,
              }}
              key={idx}
            >
              <div className="testi-item">
                <CardItem key={idx} data={cur} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
