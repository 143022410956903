import React, { useState } from "react";
import LogoIcon from "Assests/Icons/mainLogo.svg";
import ButtonIcon from "Assests/Icons/button.svg";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signOutAction } from "Redux/Actions/set.action";
import { toast } from "react-toastify";
import Button from "Components/Button/Button";
import HambergerIcon from "Assests/Images/hambergerIcon.png";
import CloseIcon from "Assests/Icons/Back-buttton-2.svg";
import CSRIcon from "Assests/Icons/CSR_menu.svg";
import OfferingMenuIcon from "Assests/Icons/Offering_menu.svg";
import OurJournyIcon from "Assests/Icons/Ourjourny_menu.svg";
import ContactIcon from "Assests/Icons/Contact_menu.svg";
import BlogIcon from "Assests/Icons/blog_menu.svg";
import PlantIcon from "Assests/Icons/planet_menu.svg";
import AboutUsIcon from "Assests/Icons/About-Us-Menu.svg";
import profilephoto from "Assests/Icons/ProfileIcon.svg";
import gallaryIcon from "Assests/Icons/galleryIcon.svg";
import LogoutIcon from "Assests/Icons/logoutIcon.svg";
import ProfilelogoutIcon from "Assests/Icons/logoutProfileIcon-2.png";
import beforelogin from "Assests/Icons/SigninIcon.png";
import homemenuIcon from "Assests/Icons/HomemenuIcon.png";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Mobilemenu, setMobilemenu] = useState(false);
  const toggleSlider = () => {
    setMobilemenu(!Mobilemenu);
  };

  if (Mobilemenu === true) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }

  const [symbol, setSymbol] = useState({ about: false, offering: false });
  const activePage = window.location.pathname;
  const user = useSelector((state) => state?.setUserDataReducer);

  const logout = () => {
    dispatch(signOutAction());
    toast("Sign Out Successful", { type: "success" });
  };

  const ishome = activePage.includes("/home") ? true : false;

  return (
    <>
      <div className="mobile-menu">
        <div className="button-hamberger">
          <button
            onClick={toggleSlider}
            className="hamburger"
            style={{
              zIndex: "11",
            }}
          >
            <img
              src={HambergerIcon}
              alt="Hamberger Icon"
              className="hamburger"
            />
          </button>
        </div>
        {ishome ? (
          <div>
            <div
              className="logo"
              style={{
                display: "flex",
                position: "absolute",
                zIndex: "1",
                // width:'100%'
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <img
                src={LogoIcon}
                alt="Logo"
                onClick={() => navigate("/home")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "9vh",
                  margin: "2vh auto",
                }}
              />
            </div>
            {user && user?.username ? null : (
              <div
                onClick={() => navigate("/signin")}
                style={{
                  display: "flex",
                  position: "absolute",
                  zIndex: "1",
                  width: "100%",
                  left: "calc(100% - 70px)",
                  top: "1vmax",
                }}
              >
                <img
                  src={beforelogin}
                  alt="Hamberger Icon"
                  className="MenuIcon"
                />
              </div>
            )}
          </div>
        ) : null}

        {Mobilemenu ? <div className="overlay menu-overlay"></div> : null}

        {Mobilemenu ? (
          <div className="menu">
            <div className="menu-top">
              <button onClick={toggleSlider} className="closebutton">
                <img src={CloseIcon} alt="Close Icon" className="CloseIcon" />
              </button>
              {user && user?.username ? (
                <div className="profile-photo">
                  <div className="profile-menu">
                    <img
                      src={profilephoto}
                      alt="Close Icon"
                      className="profilephoto"
                    />
                  </div>
                  <p className="sign-in">
                    {user.username}
                    <br></br>
                    Call Us At: 011-40059100
                  </p>
                </div>
              ) : (
                <div className="profile-photo">
                  <div className="profile-menu">
                    <img
                      src={ProfilelogoutIcon}
                      alt="Close Icon"
                      className="profilephoto"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="menu-mid">
              <ul className="down">
                <li
                  className={activePage.includes("/home") ? "active" : ""}
                  onClick={() => navigate("/home")}
                >
                  <span className="MenuIcon">
                    <svg width="20" height="20" viewBox="0 0 20 20">
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_12204"
                            data-name="Rectangle 12204"
                            width="20"
                            height="20"
                            transform="translate(39 237)"
                            fill="#fff"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Mask_Group_52623"
                        data-name="Mask Group 52623"
                        transform="translate(-39 -237)"
                        clip-path="url(#clip-path)"
                      >
                        <path
                          className="icon--stroke"
                          id="home"
                          d="M6.307,16.894v-2.76a1.319,1.319,0,0,1,1.349-1.277H10.39a1.319,1.319,0,0,1,1.358,1.277h0V16.9A1.132,1.132,0,0,0,12.887,18H14.71A3.2,3.2,0,0,0,18,14.906h0V7.054a2.161,2.161,0,0,0-.911-1.714L10.855.617a3.138,3.138,0,0,0-3.737,0L.911,5.348A2.144,2.144,0,0,0,0,7.063v7.843A3.2,3.2,0,0,0,3.29,18H5.113a1.142,1.142,0,0,0,1.176-1.106h0"
                          transform="translate(40 238)"
                          fill="none"
                          stroke="#024769"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                  </span>

                  <p className="menu-tetx">Home</p>
                </li>
                <li
                  onMouseEnter={() => setSymbol({ ...symbol, about: true })}
                  onMouseLeave={() => setSymbol({ ...symbol, about: false })}
                // className={activePage.includes("/about") ? "active" : ""}
                >
                  <span className="MenuIcon">
                    <svg width="19.8" height="17.8" viewBox="0 0 19.8 17.8">
                      <g
                        id="Group_52586"
                        data-name="Group 52586"
                        transform="translate(-2.1 -3.1)"
                      >
                        <path
                          id="Path_55409"
                          data-name="Path 55409"
                          d="M3,18.433A4.074,4.074,0,0,1,6.432,14.41l.178-.028a15.163,15.163,0,0,1,4.78,0l.178.028A4.074,4.074,0,0,1,15,18.433,1.567,1.567,0,0,1,13.433,20H4.567A1.567,1.567,0,0,1,3,18.433Z"
                          fill="none"
                          className="icon--stroke"
                          stroke="#024769"
                          stroke-width="1.8"
                        />
                        <path
                          id="Path_55410"
                          data-name="Path 55410"
                          d="M12.5,7.5A3.5,3.5,0,1,1,9,4,3.5,3.5,0,0,1,12.5,7.5Z"
                          fill="none"
                          stroke="#024769"
                          className="icon--stroke"
                          stroke-width="1.8"
                        />
                        <path
                          id="Path_55411"
                          data-name="Path 55411"
                          d="M15,11a3.5,3.5,0,0,0,0-7m2.39,16h2.043A1.567,1.567,0,0,0,21,18.433a4.074,4.074,0,0,0-3.432-4.023h0a2.281,2.281,0,0,0-.359-.028h-.968"
                          fill="none"
                          stroke="#024769"
                          stroke-linecap="round"
                          className="icon--stroke"
                          stroke-width="1.8"
                        />
                      </g>
                    </svg>
                  </span>

                  <p className="menu-tetx">
                    {" "}
                    About {symbol?.about ? "-" : "+"}
                  </p>
                  <ul className="wrapper">
                    <li onClick={() => navigate("/about/us")}>About Us</li>
                    <li onClick={() => navigate("/about/mission")}>
                      Vision & Mission
                    </li>
                    <li onClick={() => navigate("/about/team")}>Our Team</li>
                    {/* <li onClick={() => navigate("/about/partners")}>Partners</li> */}
                  </ul>
                </li>
                <li
                  className={
                    activePage.includes("/our-journey") ? "active" : ""
                  }
                  onClick={() => navigate("/our-journey")}
                >
                  <span className="MenuIcon">
                    <svg width="17.1" height="20.599" viewBox="0 0 17.1 20.599">
                      <g id="journey" transform="translate(-4.4 -0.4)">
                        <g
                          id="Group_52594"
                          data-name="Group 52594"
                          transform="translate(4.4 7.66)"
                        >
                          <path
                            id="Path_55413"
                            className="icon--fill"
                            data-name="Path 55413"
                            d="M20.669,29.289H5.275a.877.877,0,0,1-.875-.875V17.875A.877.877,0,0,1,5.275,17H20.626a.877.877,0,0,1,.875.875v10.54A.841.841,0,0,1,20.669,29.289ZM6.149,27.584H19.795V18.749H6.149Z"
                            transform="translate(-4.4 -17)"
                            fill="#024769"
                          />
                        </g>
                        <g
                          id="Group_52595"
                          data-name="Group 52595"
                          transform="translate(8.861 0.444)"
                        >
                          <path
                            id="Path_55414"
                            data-name="Path 55414"
                            d="M21.947,9.466H15.475a.877.877,0,0,1-.875-.875V1.375A.877.877,0,0,1,15.475.5h6.473a.877.877,0,0,1,.875.875V8.591A.877.877,0,0,1,21.947,9.466ZM16.306,7.76h4.767V2.206H16.306Z"
                            transform="translate(-14.6 -0.5)"
                            className="icon--fill"
                            fill="#024769"
                          />
                        </g>
                        <g
                          id="Group_52598"
                          data-name="Group 52598"
                          transform="translate(6.324 18.2)"
                        >
                          <g
                            id="Group_52596"
                            data-name="Group 52596"
                            transform="translate(0)"
                          >
                            <path
                              id="Path_55415"
                              data-name="Path 55415"
                              d="M9.675,43.9a.877.877,0,0,1-.875-.875v-1.05a.875.875,0,0,1,1.749,0v1.05A.907.907,0,0,1,9.675,43.9Z"
                              transform="translate(-8.8 -41.1)"
                              className="icon--fill"
                              fill="#024769"
                            />
                          </g>
                          <g
                            id="Group_52597"
                            data-name="Group 52597"
                            transform="translate(11.546)"
                          >
                            <path
                              id="Path_55416"
                              data-name="Path 55416"
                              d="M36.075,43.9a.877.877,0,0,1-.875-.875v-1.05a.875.875,0,0,1,1.749,0v1.05A.877.877,0,0,1,36.075,43.9Z"
                              transform="translate(-35.2 -41.1)"
                              className="icon--fill"
                              fill="#024769"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_52599"
                          data-name="Group 52599"
                          transform="translate(7.461 0.4)"
                        >
                          <path
                            id="Path_55417"
                            data-name="Path 55417"
                            d="M21.546,2.149H12.275a.875.875,0,0,1,0-1.749h9.272a.875.875,0,0,1,0,1.749Z"
                            transform="translate(-11.4 -0.4)"
                            className="icon--fill"
                            fill="#024769"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>

                  <p className="menu-tetx"> Our Journey </p>
                </li>
                <li
                  onMouseEnter={() => setSymbol({ ...symbol, offering: true })}
                  onMouseLeave={() => setSymbol({ ...symbol, offering: false })}
                  className={activePage.includes("/offering") ? "active" : ""}
                >
                  <span className="MenuIcon">
                    <svg width="18" height="18.759" viewBox="0 0 18 18.759">
                      <g id="discount" transform="translate(-10.359 0.002)">
                        <g
                          id="Group_52601"
                          data-name="Group 52601"
                          transform="translate(10.359 -0.002)"
                        >
                          <g
                            id="Group_52600"
                            data-name="Group 52600"
                            transform="translate(0 0)"
                          >
                            <path
                              className="icon--fill"
                              id="Path_55418"
                              data-name="Path 55418"
                              d="M27.491,9.621a.55.55,0,0,1,0-.486l.7-1.431a1.628,1.628,0,0,0-.7-2.163l-1.407-.747A.55.55,0,0,1,25.8,4.4L25.52,2.832A1.629,1.629,0,0,0,23.68,1.5L22.1,1.719a.55.55,0,0,1-.462-.15L20.5.461a1.628,1.628,0,0,0-2.274,0L17.077,1.568a.55.55,0,0,1-.462.15L15.038,1.5A1.628,1.628,0,0,0,13.2,2.832L12.923,4.4a.55.55,0,0,1-.286.393l-1.407.747a1.629,1.629,0,0,0-.7,2.163l.7,1.431a.55.55,0,0,1,0,.486l-.7,1.431a1.628,1.628,0,0,0,.7,2.163l1.407.747a.55.55,0,0,1,.286.393l.275,1.569A1.627,1.627,0,0,0,14.8,17.277a1.673,1.673,0,0,0,.234-.017l1.577-.223a.55.55,0,0,1,.462.15l1.145,1.108a1.628,1.628,0,0,0,2.274,0l1.145-1.108a.55.55,0,0,1,.462-.15l1.577.223a1.628,1.628,0,0,0,1.84-1.337l.275-1.569a.55.55,0,0,1,.286-.393l1.407-.747a1.629,1.629,0,0,0,.7-2.163Zm-.51,2.638-1.407.747a1.625,1.625,0,0,0-.844,1.162l-.275,1.569a.551.551,0,0,1-.622.452l-1.577-.223a1.624,1.624,0,0,0-1.366.444l-1.145,1.108a.551.551,0,0,1-.769,0l-1.145-1.108a1.623,1.623,0,0,0-1.133-.46,1.661,1.661,0,0,0-.233.016l-1.577.223a.551.551,0,0,1-.622-.452l-.275-1.569a1.625,1.625,0,0,0-.844-1.162l-1.407-.747a.551.551,0,0,1-.238-.732l.7-1.431a1.625,1.625,0,0,0,0-1.436l-.7-1.431a.551.551,0,0,1,.238-.732l1.407-.747a1.625,1.625,0,0,0,.844-1.162l.275-1.569a.551.551,0,0,1,.622-.452l1.577.223a1.625,1.625,0,0,0,1.366-.444l1.145-1.108a.551.551,0,0,1,.769,0l1.145,1.108a1.625,1.625,0,0,0,1.366.444l1.577-.223a.551.551,0,0,1,.622.452l.275,1.569a1.625,1.625,0,0,0,.844,1.162l1.407.747a.551.551,0,0,1,.238.732l-.7,1.431a1.625,1.625,0,0,0,0,1.436l.7,1.431A.551.551,0,0,1,26.981,12.259Z"
                              transform="translate(-10.359 0.002)"
                              fill="#024769"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_52603"
                          data-name="Group 52603"
                          transform="translate(14.539 4.558)"
                        >
                          <g
                            id="Group_52602"
                            data-name="Group 52602"
                            transform="translate(0)"
                          >
                            <path
                              className="icon--fill"
                              id="Path_55419"
                              data-name="Path 55419"
                              d="M153.246,143.977a.634.634,0,0,0-.9,0l-8.372,8.372a.634.634,0,1,0,.9.9l8.372-8.372A.634.634,0,0,0,153.246,143.977Z"
                              transform="translate(-143.791 -143.791)"
                              fill="#024769"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_52605"
                          data-name="Group 52605"
                          transform="translate(14.975 4.3)"
                        >
                          <g id="Group_52604" data-name="Group 52604">
                            <path
                              className="icon--fill"
                              id="Path_55420"
                              data-name="Path 55420"
                              d="M145.128,123.116a2.326,2.326,0,1,0,2.326,2.326A2.328,2.328,0,0,0,145.128,123.116Zm0,3.383a1.057,1.057,0,1,1,1.057-1.057A1.058,1.058,0,0,1,145.128,126.5Z"
                              transform="translate(-142.802 -123.116)"
                              fill="#024769"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_52607"
                          data-name="Group 52607"
                          transform="translate(19.092 9.804)"
                        >
                          <g id="Group_52606" data-name="Group 52606">
                            <path
                              className="icon--fill"
                              id="Path_55421"
                              data-name="Path 55421"
                              d="M263.248,280.61a2.326,2.326,0,1,0,2.326,2.326A2.328,2.328,0,0,0,263.248,280.61Zm0,3.383a1.057,1.057,0,1,1,1.057-1.057A1.058,1.058,0,0,1,263.248,283.993Z"
                              transform="translate(-260.922 -280.61)"
                              fill="#024769"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>

                  <p className="menu-tetx">
                    {" "}
                    Offerings {symbol?.offering ? "-" : "+"}{" "}
                  </p>
                  <ul className="wrapper">
                    <li onClick={() => navigate("/offering/page1")}>
                      Waste To Wealth - Green Steps
                    </li>
                    <li onClick={() => navigate("/offering/page2")}>
                      My Social Responsibility (MSR)
                    </li>
                    <li onClick={() => navigate("/offering/page3")}>
                      Environmental Studies Program
                    </li>
                    <li onClick={() => navigate("/offering/page4")}>
                      EcoXRclub
                    </li>
                  </ul>
                </li>
                <li
                  className={activePage.includes("/gallery") ? "active" : ""}
                  onClick={() => navigate("/gallery")}
                >
                  <span className="MenuIcon">
                    <svg width="18" height="15.231" viewBox="0 0 18 15.231">
                      <g id="gallery" transform="translate(-3 -5)">
                        <path
                          className="icon--fill"
                          id="Path_60360"
                          data-name="Path 60360"
                          d="M19.055,5H4.945A1.945,1.945,0,0,0,3,6.945v11.34a1.945,1.945,0,0,0,1.945,1.945H19.055A1.945,1.945,0,0,0,21,18.285V6.945A1.945,1.945,0,0,0,19.055,5ZM4.385,6.945a.561.561,0,0,1,.561-.561H19.055a.561.561,0,0,1,.561.561v4.285L17.227,8.046a.692.692,0,0,0-1.108,0l-3.164,4.223-1.094-1.454a.692.692,0,0,0-1.108,0l-6,8.031a.568.568,0,0,1-.374-.561Zm11.769,11.9H6.462l4.846-6.459Zm2.9,0h-1.17l-4.071-5.421,2.859-3.808,2.942,3.918v4.749a.561.561,0,0,1-.561.561Z"
                          transform="translate(0 0)"
                          fill="#024769"
                        />
                        <circle
                          className="icon--fill"
                          id="Ellipse_72"
                          data-name="Ellipse 72"
                          cx="2"
                          cy="2"
                          r="2"
                          transform="translate(5.545 7.496)"
                          fill="#024769"
                        />
                      </g>
                    </svg>
                  </span>

                  <p className="menu-tetx"> Gallery </p>
                </li>
                <li
                  className={activePage.includes("/csr") ? "active" : ""}
                  onClick={() => navigate("/csr")}
                >
                  <span className="MenuIcon">
                    <svg width="18.2" height="20.28" viewBox="0 0 18.2 20.28">
                      <g id="responsibility" transform="translate(-5.9 -2.891)">
                        <path
                          id="Path_60351"
                          data-name="Path 60351"
                          d="M11.943,21.5l.235-.651A8.307,8.307,0,0,1,15,4.728a.347.347,0,0,0,.346-.346v-.2L16.589,5.42,15.346,6.663v-.2A.347.347,0,0,0,15,6.113a6.9,6.9,0,0,0-4.272,1.482l.429.543a6.222,6.222,0,0,1,3.5-1.322V7.5a.342.342,0,0,0,.215.318.347.347,0,0,0,.377-.076l.834-.834a6.227,6.227,0,0,1-.734,12.351v-.682a.346.346,0,0,0-.592-.242l-.834.834a6.259,6.259,0,0,1-5.151-6.13A6.176,6.176,0,0,1,9.828,9.567L9.254,9.18a6.876,6.876,0,0,0-1.177,3.856,6.962,6.962,0,0,0,5.255,6.715l-.654.654a.345.345,0,0,0,0,.488l2.077,2.077a.349.349,0,0,0,.246.1.29.29,0,0,0,.132-.028.347.347,0,0,0,.215-.318v-.7a9,9,0,0,0,2.71-17.457l-.235.651A8.307,8.307,0,0,1,15,21.34a.347.347,0,0,0-.346.346v.2l-1.243-1.243,1.243-1.243v.2a.347.347,0,0,0,.346.346A6.921,6.921,0,0,0,16.668,6.317l.654-.654a.345.345,0,0,0,0-.488L15.246,3.1a.346.346,0,0,0-.592.242v.7A9,9,0,0,0,11.943,21.5Z"
                          transform="translate(0 0)"
                          className="icon--fill-stroke"
                          fill="#024769"
                          stroke="#024769"
                          stroke-width="0.2"
                        />
                        <path
                          className="icon--fill-stroke"
                          id="Path_60352"
                          data-name="Path 60352"
                          d="M19.379,22.795a.381.381,0,0,0-.379.379v1.518a.381.381,0,0,0,.379.379h.539a4.1,4.1,0,0,0,.372.9l-.379.379a.378.378,0,0,0,0,.535l1.074,1.074a.378.378,0,0,0,.535,0l.379-.379a4.1,4.1,0,0,0,.9.372v.539a.381.381,0,0,0,.379.379h1.518a.381.381,0,0,0,.379-.379v-.539a4.1,4.1,0,0,0,.9-.372l.379.379a.378.378,0,0,0,.535,0l1.074-1.074a.378.378,0,0,0,0-.535l-.379-.379a4.1,4.1,0,0,0,.372-.9h.539a.381.381,0,0,0,.379-.379V23.174a.381.381,0,0,0-.379-.379h-.539a4.1,4.1,0,0,0-.372-.9l.379-.379a.378.378,0,0,0,0-.535l-1.074-1.074a.378.378,0,0,0-.535,0l-.379.379a4.1,4.1,0,0,0-.9-.372v-.539A.381.381,0,0,0,24.692,19H23.174a.381.381,0,0,0-.379.379v.539a4.1,4.1,0,0,0-.9.372l-.379-.379a.378.378,0,0,0-.535,0l-1.074,1.074a.378.378,0,0,0,0,.535l.379.379a4.1,4.1,0,0,0-.372.9h-.539Zm1.207.455a3.4,3.4,0,0,1,.5-1.2.379.379,0,0,0-.049-.478l-.323-.323.535-.535.323.323a.379.379,0,0,0,.478.049,3.4,3.4,0,0,1,1.2-.5.375.375,0,0,0,.3-.372v-.455h.759v.455a.375.375,0,0,0,.3.372,3.4,3.4,0,0,1,1.2.5.379.379,0,0,0,.478-.049l.323-.323.535.535-.323.323a.379.379,0,0,0-.049.478,3.4,3.4,0,0,1,.5,1.2.375.375,0,0,0,.372.3H28.1v.759h-.455a.375.375,0,0,0-.372.3,3.4,3.4,0,0,1-.5,1.2.379.379,0,0,0,.049.478l.323.323-.535.535-.323-.323a.379.379,0,0,0-.478-.049,3.4,3.4,0,0,1-1.2.5.375.375,0,0,0-.3.372V28.1H23.55v-.455a.375.375,0,0,0-.3-.372,3.4,3.4,0,0,1-1.2-.5.379.379,0,0,0-.478.049l-.323.323-.535-.535.323-.323a.379.379,0,0,0,.049-.478,3.4,3.4,0,0,1-.5-1.2.375.375,0,0,0-.372-.3h-.455v-.759h.455A.375.375,0,0,0,20.586,23.25Z"
                          transform="translate(-8.933 -10.897)"
                          fill="#024769"
                          stroke="#024769"
                          stroke-width="0.5"
                        />
                        <path
                          id="Path_60353"
                          data-name="Path 60353"
                          d="M27.662,30.312a2.582,2.582,0,0,0,1.465-.444,2.63,2.63,0,0,0,.835-.888L29.3,28.6a1.817,1.817,0,0,1-.6.63,1.857,1.857,0,0,1-1.044.315,1.9,1.9,0,1,1,1.871-2.208,1.666,1.666,0,0,1,.027.311,2.064,2.064,0,0,1-.027.311l.748.14a2.487,2.487,0,0,0,.042-.448,2.716,2.716,0,0,0-.038-.44,2.656,2.656,0,1,0-2.618,3.1Z"
                          transform="translate(-12.663 -14.62)"
                          className="icon--fill"
                          fill="#024769"
                        />
                      </g>
                    </svg>
                  </span>

                  <p className="menu-tetx">CSR</p>
                </li>
                <li
                  className={
                    activePage.includes("/planet-warrior") ? "active" : ""
                  }
                  onClick={() => navigate("/planet-warrior")}
                >
                  <span className="MenuIcon">
                    <svg
                      width="18.546"
                      height="18.528"
                      viewBox="0 0 18.546 18.528"
                    >
                      <g id="planet" transform="translate(-1.725 -1.722)">
                        <path
                          id="Path_60354"
                          data-name="Path 60354"
                          d="M18.608,10.994a7.576,7.576,0,0,0-.441-2.531c1.547-2.426,2.455-4.848,1.333-5.97-.965-.964-3.14-.474-5.974,1.331a7.477,7.477,0,0,0-5.054,0A18.322,18.322,0,0,0,6.838,2.9a1.027,1.027,0,0,0-1.66-.672c-1.23-.36-2.134-.275-2.675.267-1.08,1.08-.32,3.407,1.33,5.979a7.475,7.475,0,0,0,0,5.048c-1.8,2.836-2.3,5.012-1.331,5.976a1.87,1.87,0,0,0,1.378.5,9.7,9.7,0,0,0,4.593-1.831,7.469,7.469,0,0,0,5.043,0A9.657,9.657,0,0,0,18.11,20a1.889,1.889,0,0,0,1.392-.5c.963-.964.472-3.14-1.332-5.976a7.56,7.56,0,0,0,.44-2.526ZM17.923,11a6.931,6.931,0,0,1-4.306,6.406q-1.074-.709-2.077-1.518.975-.822,1.89-1.711a2.05,2.05,0,0,0,1.032.285,2.073,2.073,0,0,0,1.708-3.254q.8-.967,1.51-2A6.89,6.89,0,0,1,17.923,11Zm-2.077,1.385A1.385,1.385,0,1,1,14.461,11,1.386,1.386,0,0,1,15.846,12.385Zm3.172-9.4c.586.587.27,2.305-1.173,4.7a7.652,7.652,0,0,0-3.531-3.531C16.556,2.806,18.374,2.336,19.018,2.982Zm-1.613,5.4a27.611,27.611,0,0,1-1.725,2.328,2.058,2.058,0,0,0-1.218-.4,2.066,2.066,0,0,0-1.569,3.422q-.916.89-1.892,1.713-1.206-1.01-2.319-2.123A31.755,31.755,0,0,1,4.594,8.384a6.918,6.918,0,0,1,12.812,0ZM5.808,2.692a.346.346,0,1,1-.347.346A.346.346,0,0,1,5.808,2.692Zm-2.826.289c.316-.317.959-.351,1.807-.135A1.036,1.036,0,0,0,6.68,3.6q.485.249,1,.559A7.652,7.652,0,0,0,4.155,7.684C2.777,5.39,2.348,3.615,2.982,2.982ZM8.191,13.809q1.09,1.088,2.269,2.083-1,.807-2.068,1.516A6.881,6.881,0,0,1,4.319,9.217a34.507,34.507,0,0,0,3.872,4.593ZM2.982,19.018c-.645-.645-.175-2.463,1.173-4.7a7.652,7.652,0,0,0,3.54,3.534C5.392,19.243,3.623,19.661,2.982,19.018ZM11,17.923a6.889,6.889,0,0,1-1.791-.244q.918-.637,1.788-1.342.866.705,1.782,1.345A6.888,6.888,0,0,1,11,17.923Zm8.018,1.1c-.632.631-2.407.2-4.7-1.174a7.652,7.652,0,0,0,3.528-3.529C19.193,16.556,19.663,18.373,19.018,19.018Z"
                          transform="translate(0 0)"
                          className="icon--fill-stroke"
                          fill="#024769"
                          stroke="#024769"
                          stroke-width="0.5"
                        />
                        <path
                          id="Path_60355"
                          data-name="Path 60355"
                          d="M14.654,6.308a2.467,2.467,0,0,1,2.252,1.468.154.154,0,0,0,.281-.125A2.775,2.775,0,0,0,14.654,6a.154.154,0,1,0,0,.308Z"
                          transform="translate(-1.401 -0.765)"
                          className="icon--fill-stroke"
                          fill="#024769"
                          stroke="#024769"
                          stroke-width="0.5"
                        />
                      </g>
                    </svg>
                  </span>

                  <p className="menu-tetx">Planet Warriors</p>
                </li>
                <li
                  className={activePage.includes("/blogs") ? "active" : ""}
                  onClick={() => navigate("/blogs")}
                >
                  <span className="MenuIcon">
                    <svg width="18" height="15.429" viewBox="0 0 18 15.429">
                      <path
                        id="blog"
                        d="M16.143,14.442V17.5a1.929,1.929,0,0,1-1.929,1.929H3.929A1.929,1.929,0,0,1,2,17.5V5.929A1.929,1.929,0,0,1,3.929,4H14.214a1.929,1.929,0,0,1,1.929,1.929V8.987l.753-.753a1.285,1.285,0,0,1,1.818,0l.909.909a1.285,1.285,0,0,1,0,1.818Zm-1.286-4.17V8.5H3.286v9a.643.643,0,0,0,.643.643H14.214a.643.643,0,0,0,.643-.643V15.728l-.505.505a.647.647,0,0,1-.24.151l-2.182.774A.964.964,0,0,1,10.7,15.927l.774-2.182a.647.647,0,0,1,.151-.24l3.233-3.233Zm0-3.058V5.929a.643.643,0,0,0-.643-.643H3.929a.643.643,0,0,0-.643.643V7.214Zm-2.721,8.506,1.409-.5,5.169-5.169-.909-.909-5.169,5.169ZM5.214,11.071a.643.643,0,1,1,0-1.286h7.714a.643.643,0,0,1,0,1.286Zm0,2.571a.643.643,0,1,1,0-1.286h5.143a.643.643,0,1,1,0,1.286Zm0,2.571a.643.643,0,1,1,0-1.286H9.071a.643.643,0,1,1,0,1.286Z"
                        transform="translate(-2 -4)"
                        className="icon--fill"
                        fill="#024769"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </span>

                  <p className="menu-tetx">Blogs</p>
                </li>
                <li
                  className={activePage.includes("/contact") ? "active" : ""}
                  onClick={() => navigate("/contact")}
                >
                  <span className="MenuIcon">
                    <svg
                      width="27.971"
                      height="28.008"
                      viewBox="0 0 27.971 28.008"
                    >
                      <path
                        id="_09_contact"
                        data-name="09 contact"
                        d="M24.19,23.29a.31.31,0,0,0-.09-.07.88.88,0,0,0-.28-.17h-.05L17.6,21.18a1,1,0,0,0-.94.2,5.36,5.36,0,0,0-.7.73,10.63,10.63,0,0,1-3.69-2.39A10.46,10.46,0,0,1,9.89,16a5.13,5.13,0,0,0,.72-.68,1,1,0,0,0,.21-.95L9,8.23V8.17a1,1,0,0,0-.15-.26l-.08-.1a1,1,0,0,0-.37-.24,4.61,4.61,0,0,0-1.46-.23H6.35a3,3,0,0,0-.5.09,3.28,3.28,0,0,0-.61.18,2,2,0,0,0-.34.13H4.8a3.8,3.8,0,0,0-.55.33,4.13,4.13,0,0,0-.55.41,2.4,2.4,0,0,0-.32.33A4.69,4.69,0,0,0,2.1,11.25a2.77,2.77,0,0,1-.06.38,3.77,3.77,0,0,0,0,.58,16.43,16.43,0,0,0,.11,1.92,17.8,17.8,0,0,0,15.7,15.76,16.731,16.731,0,0,0,1.93.11,4.22,4.22,0,0,0,.53,0l.43-.07h.22l.27-.09.34-.12h.1a2.72,2.72,0,0,0,.35-.16.81.81,0,0,0,.18-.1l.11-.07.19-.12a4.87,4.87,0,0,0,.94-.83,5.21,5.21,0,0,0,.69-1v-.06a4.14,4.14,0,0,0,.19-.49,3.5,3.5,0,0,0,.14-.49,2.21,2.21,0,0,0,.07-.3.973.973,0,0,1,.05-.36,1.482,1.482,0,0,0,0-.47,4.46,4.46,0,0,0-.22-1.45.88.88,0,0,0-.17-.53Zm-1.55,2.12v.15a.43.43,0,0,0,0,.11,1.3,1.3,0,0,0,0,.13,1.29,1.29,0,0,1-.06.21,1,1,0,0,0,0,.1.52.52,0,0,1-.06.16l-.05.1a2.88,2.88,0,0,1-.42.65,2.94,2.94,0,0,1-.56.49l-.11.07-.1.06a1.48,1.48,0,0,1-.25.12l-.21.08h-.91a9.18,9.18,0,0,1-1.69-.09,15.83,15.83,0,0,1-14-14A14.87,14.87,0,0,1,4,12.21a2.6,2.6,0,0,1,0-.35,1.43,1.43,0,0,1,0-.23,2.89,2.89,0,0,1,.73-1.39l.1-.09L5,10a1.69,1.69,0,0,1,.29-.22,2.26,2.26,0,0,1,.26-.16l.11-.05.12-.06h.14a1.12,1.12,0,0,1,.26-.08h.07a.768.768,0,0,0,.3-.05,2.65,2.65,0,0,1,.63,0l1.51,5a2.92,2.92,0,0,1-.54.38l-.07.06A.93.93,0,0,0,8,15a.81.81,0,0,0-.12.14,1.3,1.3,0,0,0-.08.17.64.64,0,0,0-.06.18,1,1,0,0,0,0,.39v.09a12.58,12.58,0,0,0,8.33,8.33h.3a.93.93,0,0,0,.23,0h.07a1,1,0,0,0,.31-.16A.46.46,0,0,1,17,24a1.2,1.2,0,0,0,.17-.21,2.68,2.68,0,0,1,.36-.52l5,1.5v.35c0,.12.11.21.11.29ZM22.5,9A3.5,3.5,0,1,0,19,5.5,3.5,3.5,0,0,0,22.5,9Zm0-5A1.5,1.5,0,1,1,21,5.5,1.5,1.5,0,0,1,22.5,4ZM25,10H20a5,5,0,0,0-5,5v1a1,1,0,0,0,1,1H29a1,1,0,0,0,1-1V15A5,5,0,0,0,25,10Zm-8,5a3,3,0,0,1,3-3h5a3,3,0,0,1,3,3Z"
                        transform="translate(-2.029 -2)"
                        className="icon--fill"
                        fill="#024769"
                      />
                    </svg>
                  </span>

                  <p className="menu-tetx">Contact Us</p>
                </li>
                {/* {user && user?.username ? (
          <li  onClick={logout} className="logout">
            <img src={LogoutIcon} alt="Hamberger Icon" className="MenuIcon" />
            <p className="menu-tetx"> LOG OUT</p>
          </li>
          ):null} */}
              </ul>
            </div>
            <div className="menu-end">
              {user && user.username ? (
                <div
                  className="end"
                  style={{
                    display: "flex",
                  }}
                >
                  <span className="MenuIcon">
                    <svg width="16" height="20" viewBox="0 0 16 20">
                      <path
                        id="logout"
                        d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,1,0,1.42,1.42l4-4a1.034,1.034,0,0,0,0-1.42l-4-4a1,1,0,0,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"
                        transform="translate(-4 -2)"
                        className="icon--fill"
                        fill="#024769"
                      />
                    </svg>
                  </span>

                  <p onClick={logout}>Logout</p>
                </div>
              ) : (
                <div
                  className="end"
                  style={{
                    display: "flex",
                  }}
                >
                  <span className="MenuIcon">
                    <svg width="16" height="20" viewBox="0 0 16 20">
                      <path
                        id="logout"
                        d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,1,0,1.42,1.42l4-4a1.034,1.034,0,0,0,0-1.42l-4-4a1,1,0,0,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"
                        transform="translate(-4 -2)"
                        className="icon--fill"
                        fill="#024769"
                      />
                    </svg>
                  </span>

                  <p onClick={() => navigate("/signin")}>Sign-in</p>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>

      <div className="header">
        <div className="left">
          <div className="up">
            Call Us At: <b>011-40059100</b>
          </div>
          <ul className="down">
            <li
              onMouseEnter={() => setSymbol({ ...symbol, about: true })}
              onMouseLeave={() => setSymbol({ ...symbol, about: false })}
              className={activePage.includes("/about") ? "active" : ""}
            >
              About {symbol?.about ? "-" : "+"}
              <ul className="wrapper">
                <li onClick={() => navigate("/about/us")}>About Us</li>
                <li onClick={() => navigate("/about/mission")}>
                  Vision & Mission
                </li>
                <li onClick={() => navigate("/about/team")}>Our Team</li>
                {/* <li onClick={() => navigate("/about/partners")}>Partners</li>  */}
              </ul>
            </li>
            <li
              className={activePage.includes("/our-journey") ? "active" : ""}
              onClick={() => navigate("/our-journey")}
            >
              Our Journey
            </li>
            <li
              onMouseEnter={() => setSymbol({ ...symbol, offering: true })}
              onMouseLeave={() => setSymbol({ ...symbol, offering: false })}
              className={activePage.includes("/offering") ? "active" : ""}
            >
              Offerings {symbol?.offering ? "-" : "+"}
              <ul className="wrapper">
                <li onClick={() => navigate("/offering/page1")}>
                  Waste To Wealth - Green Steps
                </li>
                <li onClick={() => navigate("/offering/page2")}>
                  My Social Responsibility (MSR)
                </li>
                <li onClick={() => navigate("/offering/page3")}>
                  Environmental Studies Program
                </li>
                <li onClick={() => navigate("/offering/page4")}>
                  EcoXRclub
                </li>
              </ul>
            </li>
            <li
              className={activePage.includes("/gallery") ? "active" : ""}
              onClick={() => navigate("/gallery")}
            >
              Gallery
            </li>
          </ul>
        </div>
        <div className="logo">
          <img src={LogoIcon} alt="Logo" onClick={() => navigate("/home")} />
        </div>
        <div className="right">
          {user && user?.username ? (
            <div className="up">
              <div className="profile-menu">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.0018 11.75C12.9413 11.75 13.8596 11.4714 14.6408 10.9495C15.4219 10.4275 16.0307 9.6857 16.3902 8.81775C16.7498 7.9498 16.8438 6.99473 16.6605 6.07332C16.4773 5.15191 16.0249 4.30554 15.3606 3.64124C14.6963 2.97695 13.8499 2.52455 12.9285 2.34127C12.0071 2.15799 11.052 2.25206 10.1841 2.61157C9.31612 2.97109 8.57427 3.57991 8.05233 4.36104C7.5304 5.14218 7.25181 6.06054 7.25181 7C7.25181 8.25978 7.75226 9.46796 8.64306 10.3588C9.53385 11.2496 10.742 11.75 12.0018 11.75ZM12.0018 3.75C12.6446 3.75 13.273 3.94061 13.8074 4.29773C14.3419 4.65484 14.7584 5.16242 15.0044 5.75628C15.2504 6.35014 15.3148 7.00361 15.1894 7.63405C15.064 8.26448 14.7544 8.84358 14.2999 9.2981C13.8454 9.75262 13.2663 10.0622 12.6359 10.1876C12.0054 10.313 11.352 10.2486 10.7581 10.0026C10.1642 9.75663 9.65665 9.34006 9.29954 8.8056C8.94242 8.27115 8.75181 7.64279 8.75181 7C8.75445 6.13886 9.09771 5.31374 9.70663 4.70482C10.3156 4.0959 11.1407 3.75264 12.0018 3.75ZM20.0018 14.76C14.855 12.7655 9.14867 12.7655 4.00181 14.76C3.48593 14.9592 3.04302 15.311 2.73215 15.7684C2.42128 16.2257 2.25721 16.767 2.26181 17.32V19C2.26181 19.7276 2.55017 20.4256 3.06373 20.941C3.57729 21.4565 4.2742 21.7474 5.00181 21.75H19.0018C19.7312 21.75 20.4306 21.4603 20.9464 20.9445C21.4621 20.4288 21.7518 19.7293 21.7518 19V17.32C21.7556 16.7658 21.5901 16.2237 21.2774 15.7662C20.9646 15.3087 20.5196 14.9576 20.0018 14.76ZM20.2518 19C20.2518 19.3315 20.1201 19.6495 19.8857 19.8839C19.6513 20.1183 19.3333 20.25 19.0018 20.25H5.00181C4.67029 20.25 4.35235 20.1183 4.11793 19.8839C3.88351 19.6495 3.75181 19.3315 3.75181 19V17.32C3.74949 17.0692 3.82393 16.8236 3.96513 16.6163C4.10633 16.4089 4.30756 16.2497 4.54181 16.16C9.33749 14.28 14.6661 14.28 19.4618 16.16C19.6961 16.2497 19.8973 16.4089 20.0385 16.6163C20.1797 16.8236 20.2541 17.0692 20.2518 17.32V19Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="sign-in" onClick={logout}>
                LOG OUT
              </p>
            </div>
          ) : (
            <div className="up">
              <div onClick={() => navigate("/signin?active=true")}>
                <Button
                  height={"1.6vmax"}
                  title="SIGN UP"
                  background={"#f7941d"}
                  width={"6.3vmax"}
                  padding={"3%"}
                  type="submit"
                  fontSize=".7vmax"
                />
              </div>
              {/* <button
              onClick={() => navigate("/signin?active=true")}
              className="btn-background"
            >
              <img src={ButtonIcon} alt="button" />
              SIGN UP
            </button> */}
              <div className="custom-btn">
                <button className="sign-in" onClick={() => navigate("/signin")}>
                  SIGN IN
                </button>
              </div>
            </div>
          )}

          <ul className="down">
            <li
              className={activePage.includes("/csr") ? "active" : ""}
              onClick={() => navigate("/csr")}
            >
              CSR
            </li>
            <li
              className={activePage.includes("/planet-warrior") ? "active" : ""}
              onClick={() => navigate("/planet-warrior")}
            >
              Planet Warriors
            </li>
            <li
              className={activePage.includes("/blogs") ? "active" : ""}
              onClick={() => navigate("/blogs")}
            >
              Blogs
            </li>
            <li
              className={activePage.includes("/contact") ? "active" : ""}
              onClick={() => navigate("/contact")}
            >
              Contact Us
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
