import React from "react";
import calendarIcon from "Assests/Icons/calendarIcon.svg";
// import LinkdinIconIcon from "Assests/Icons/LinkdinIcon.png";
// import TwitterIcon from "Assests/Icons/twitterIcon.png";
import moment from "moment";

const BlogTitle = ({ Title, Date }) => {
  const date = moment(Date).format("DD MMM YYYY");

  return (
    <div className="blog-title" >
      <div
        className="title-top"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%",
          marginLeft: "8%"
        }}
      >
        <div
          className="title-date"
          style={{
            display: "flex",
          }}
        >
          <img
            src={calendarIcon}
            alt="calendar"
            className="calendar"
            style={{
              height: "1vmax",
              width: "1vmax",
            }}
          />
          <p
            style={{
              fontWeight: "500",
              color: "#f7941d",
              fontSize: window.innerWidth<600? "14px":"1vmax",
              paddingLeft: "0.5vmax",
              paddingTop: "2px",
            }}
          >
            {date}
          </p>
        </div>
        {/* <div className="Social-icon">
          <img
            src={LinkdinIconIcon}
            alt="linkdinicon"
            className="linkdinicon"
            style={{
                paddingRight: "0.5vmax",
                width: "50%",
              }}
          />
          <img
            src={TwitterIcon}
            alt="TwitterIcon"
            className="TwitterIcon"
            style={{
                width: "36%",
              }}s
          />
        </div> */}
      </div>
      <div className="title-bottom" >
        <h2
          style={{
            width: "85%",
            fontWeight: "600",
            fontSize: window.innerWidth<600? "14px":"1.2vmax",
            color: "#024769",
            marginLeft: "8%"
          }}
        >
          {Title}
        </h2>
      </div>
    </div>
  );
};
export default BlogTitle;
