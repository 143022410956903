import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import { useParams, useLocation } from "react-router-dom"; // Import useParams to access URL parameters
import { fetchBlogDetail } from "API/get.api";
import "./BlogContains.css";
import NoImg from "Assests/Images/NoImg.png";
import { useEffect, useState } from "react";
import MoreBlog from "Components/Carousel/MultiCarousel/MoreBlog/MoreBlog";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";



const BlogContainer = ({bloG}) => {
  const {id,slug} = useParams(); // Get the id and slug from the URL parameters
  const [blog, setBlog] = useState(null);
 
useEffect(() => {
  window.scrollTo({
    top: 0,
    left: 0,

  });
}, []);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
  
    });

    const apiStatus = fetchBlogDetail(id);
    apiStatus
      .then((response) => {
        setBlog(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog details:", error);
      });
  }, [id]);

  

  return (
    <div className="blog-container">

      <Header />
       {/* {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="Blogs"/>)} */}

      {blog ? (
        <HeadingBanner
          // You can customize the banner with blog details, e.g., title and image
          heading=" "
          image={blog?.image || NoImg}
          detail=" "
        />
      ) : null}

      <div className="blog-content">
        {blog ? (
          <div>
            <HeadingUnderline bold= {blog.title.split(':')[0] + ":"} heading={blog.title.split(':')[1]} align="left" />
            <div className="content-data" dangerouslySetInnerHTML={{ __html: blog.content }}  />
            <div className="blog-author">
            <h3 className="blog-author-title">{"by: "+ blog.author.username}</h3>
            </div>
          </div>
        ) : (
          <p className="loading-text">Content Not Found</p>
        )}
        
      </div>
      <MoreBlog />
      
      <Footer />
    </div>
  );
};

export default BlogContainer;
