import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import React, { useState, useEffect } from "react";
import QuizBanner from "Assests/Images/QuizBanner.png";
import "./Quiz.css";
// import ButtonIcon from "Assests/Icons/buttonWhite.svg";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import Pagination from "Components/Pagination/Pagination";
import Footer from "Components/Footer/Footer";
import QuizPopUp from "Components/PopUp/Quiz/QuizPopUp";
import { fetchQuizList, fetchAllQuestionQuiz } from "API/get.api";
import { toast } from "react-toastify";
import Button from "Components/Button/Button";

const Quiz = () => {
  const [model, setModel] = useState(false);
  const [quizList, setQuizList] = useState([]);
  const [question, setQuestion] = useState([]);
  const [activeQ, setActiveQ] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const apiStatus = fetchQuizList();
    apiStatus
      .then((response) => {
        setQuizList(response.data);
        // toast("Logged In Successful", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const quizModelOpen = (index,item) => {
    if (!token) {
      toast("Sign In to Take Quiz", { type: "warning" });
      return;
    }
    setActiveQ(index)
    const apiStatus = fetchAllQuestionQuiz(item.id, token);
    apiStatus
      .then((response) => {
        setQuestion(response.data);
        if (response.data.length > 0) {
          setModel(true);
        } else {
          toast("You Already Attempt Questions", { type: "error" });
        }
        // toast("Logged In Successful", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const QuizCard = ({ image, backgroundColor, item,index }) => {
    return (
      <div className="quiz-card">
        <img
          src={item?.picture || "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"}
          alt={item?.quiz_title || "No Title"}
        />
        <h6>{item?.quiz_title || "No Title"}</h6>
        <p>{item?.description || "No Description"}</p>

        <div style={{  }} onClick={() => quizModelOpen(index,item)}>
          <Button
            height={window.innerWidth<600?"4vw":"2vw"}
            title="TAKE QUIZ"
            background={"white"}
            width={"7vmax"}
            padding={"3.5%"}
            type="button"
            fontSize=".8vmax"
            color="#024769"
          />
        </div>

        {/* <button onClick={() => quizModelOpen(item)} className="btn-background">
          <img src={ButtonIcon} alt="button" />
          <p>TAKE QUIZ</p>
        </button> */}
      </div>
    );
  };

  return (
    <div className="quiz-page">
      <Header />

      <HeadingBanner
        image={QuizBanner}
        heading="Quiz"
        detail="Test Your Eco IQ: Dive into our Green Quiz, where knowledge meets nature in a fun, interactive 
        challenge. Share your results with your friends and encourage others to take part in the quiz too. Let 
        us strengthen each other’s knowledge and win goodies."
        // detail="Take part in these short Quiz - a fun way to enhance our knowledge. Share your result with your friends and encourage others to take part in the quiz too. Let’s strengthen each other’s knowledge and win goodies."
      />

      <div className="body">
        <HeadingUnderline bold="Blue Nudge " heading="Quiz" />

        <div className="wrapper">
          {quizList?.length > 0 &&
            quizList?.map((item, i) => (
              <QuizCard
                key={i}
                index={i}
                item={item}
                backgroundColor="linear-gradient(to bottom, #ffbcd9, #ec0c6e)"
              />
            ))}
          {/* <QuizCard backgroundColor="linear-gradient(to bottom, #ffbcd9, #ec0c6e)" />
          <QuizCard backgroundColor="linear-gradient(to bottom, #ffe569 -9%, #ff7518 129%)" />
          <QuizCard backgroundColor="linear-gradient(to bottom, #ffbcd9, #ec0c6e)" />
          <QuizCard backgroundColor="linear-gradient(to bottom, #fff2b4 40%, #ffd402 130%)" />
          <QuizCard backgroundColor="linear-gradient(179deg, #b6ff80 0%, #84b95c 111%)" />
          <QuizCard backgroundColor="linear-gradient(to bottom, #fff2b4 40%, #ffd402 130%)" /> */}
        </div>

        <Pagination />
      </div>

      <Footer />
      {model && (
        <QuizPopUp
          model={model}
          setModel={setModel}
          question={question}
          setQuestion={setQuestion}
          quizList={quizList}
          activeQ={activeQ}
          setActiveQ={setActiveQ}
        />
      )}
    </div>
  );
};

export default Quiz;
