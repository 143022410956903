import React, { useEffect, useState, useRef } from "react";
// import Blog1 from "Assests/Images/blog-1.png";
// import Blog2 from "Assests/Images/blog-2.png";
// import Blog3 from "Assests/Images/blog-3.png";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import "./RecentBLog.css";
import { fetchBlogList } from "API/get.api";
import BlogArrow from "Assests/Images/BlogArrow.png";
import Button from "Components/Button/BlogButton";
// import LinkdinIconIcon from "Assests/Icons/LinkdinIcon.png";
// import TwitterIcon from "Assests/Icons/twitterIcon.png";
import moment from "moment";
import clanderIcon from "Assests/Icons/calendarIcon.svg";
import { useNavigate } from "react-router-dom";
import BlogTitle from "Components/BlogTitle/BlogTitle";

const RecentBlog = () => {
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0); // Index of the currently displayed card
  const [cardHeight, setCardHeight] = useState(0);
  const [BlogList, setBlogList] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);

  const [blog, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const apiStatus = fetchBlogList();
    apiStatus
      .then((response) => {
        const recentBlogs = response.data.filter((blog) => blog.category === "recent");
        setBlogs(recentBlogs);
        // toast("fetch sucess full", { type: "success" });

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [isInitialTransition, setIsInitialTransition] = useState(true);
  const handleBlogItemClick = (blog) => {
    const { id, slug } = blog;
    setSelectedBlog(blog);
    navigate(`/blogs/${id}/${slug}`);
  };

  useEffect(() => {

    const moveCardsAutomatically = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 2);
      setIsInitialTransition(false);
    };


    const intervalId = setInterval(moveCardsAutomatically, 3000);


    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const firstCard = containerRef.current.querySelector(".card");
    if (firstCard) {
      const height = firstCard.getBoundingClientRect().height;
      setCardHeight(height);
    }
  }, []);
  const date = moment(blog.created_on).format("DD MMM YYYY");

  // // Calculate the translateY value based on whether it's the initial transition or subsequent transitions
  // const translateY = isInitialTransition ? -100 : -45;
  // // const translateY = -100;

  return (
    <div
      className="RecentBlog"
      style={{
        display: "flex",
        justifyContent: "space",
        overflow: "hidden",
        width: "80vw",
        margin: "0 auto",
      }}
    >
      <div className="recent-blog" style={{ flexBasis: "40%" }}>
        <HeadingUnderline bold="Recent " heading="Blogs" align="left" />
        <div className="recent-blog-content">
          <p>
            Discover a Greener Tomorrow! Dive into our sustainable reads,
            unravelling eco-conscious living, environmental awareness, and
            cutting-edge waste management technologies. Stay ahead, stay green!
          </p>
        </div>
      </div>
      <div
        className="wrapper"
        style={{
          overflow: "hidden",
          flexBasis: "60%",
          marginTop: "2vmax",
          height: "30vmax",
        }}
      >
        <div
          className="recentblogCarousel"
          style={{
            transform: `translate3D(0, -${currentIndex * 18}rem, 0)`, // Use translateY for vertical translation
            transition: "transform 0.5s ease-in-out",
            height: "24vmax", // Set the height of the container to match your card height
          }}
          ref={containerRef}
        >
          {blog.map((item) => (
            <div
              className="card card--blog"
              key={item.id}
              style={{
                height: "auto",
                paddingBottom: "1vmax",

              }}
            >

              <div className="card--blog--upper">
                <div className="card--upper--img">
                  <img
                    src={item.image}
                    className="blog-img"
                    alt={item.title}
                  // style={{
                  //   height: "19vmax",
                  //   width: "40vmax",
                  //   paddingBottom: "1vmax",
                  //   paddingTop: "1vmax",
                  //   position: "relative",
                  //   objectFit:"cover",
                  // }}
                  />
                </div>

                <div onClick={() => handleBlogItemClick(item)} className="blogbutton">
                  {/* <p>&#8599;</p> */}
                  <Button
                    height={"3vmax"}
                    background={"#f7941d"}
                    width={"3vmax"}
                    type="button"
                    color="#fff"
                  />
                </div>

              </div>

              <div className="card--blog--bottom">
                <BlogTitle Title={item.title} Date={date} />
              </div>

              {/* <div
                className="blog-date"
                style={{
                  display: "flex",
                }}
              >
                <div
                  className="blog-date-line"
                  style={{
                    display: "flex",
                    flexBasis: "50%",
                    color: "#f7941d",
                    fontWeight: "500",
                    fontSize: "0.875rem",
                  }}
                >
                  <img src={clanderIcon} alt="linkdin" />
                  <p
                    style={{
                      paddingLeft: "1vmax",
                      fontSize: "0.875rem",
                    }}
                  >
                    {date}
                  </p>
                </div>

                <div
                  className="socailmedia"
                  style={{
                    position: "relative",
                    right: "-26%",
                    flexBasis: "50%",
                  }}
                >
                  <img
                    src={LinkdinIconIcon}
                    alt="linkdin"
                    style={{
                      paddingRight: "1vmax",
                    }}
                  />
                  <img src={TwitterIcon} alt="twitter" />
                </div>
              </div>
              <h4>
                {item.title}
              </h4> */}

              {/* <button
                onClick={() => {
                  handleBlogItemClick(item);
                }}
              >
                <img src={BlogArrow} style={{}} />
              </button> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecentBlog;
