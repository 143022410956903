import React from "react";
import facebook from "Assests/Icons/facebook.svg";
import linkedIn from "Assests/Icons/linkedIn.svg";
import twitter from "Assests/Icons/twitter.svg";
import instagram from "Assests/Icons/instagram.svg";
import "./HeadingBanner.css";

const HeadingBanner = ({ image, heading, detail, subHeading }) => {
  return (
    <div className="heading-background">
      <img src={image} alt="banner" className="banner-img" />

      <div className="content">
        <h4>{heading}</h4>
        <h5>{subHeading}</h5>  
        <p>{detail}</p>
      </div>

      <div className="banner-social">
        <a
          href="https://www.facebook.com/bluenudge"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebook} alt="facebook" />
        </a>
        <a
          href="https://twitter.com/NudgeBlue"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitter} alt="twitter" />
        </a>
        <a
          href="https://www.linkedin.com/company/blue-nudge/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedIn} alt="linkedIn" />
        </a>
        <a
          href="https://www.instagram.com/bluenudge/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagram} alt="instagram"/>
        </a>
      </div>
    </div>
  );
};

export default HeadingBanner;
