import React, { useState, useEffect } from "react";
import "./OurTeamCarousel.css";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import Carousel from "react-multi-carousel";
import latestGalleryImg1 from "Assests/Images/latestGalleryImg1.png";
import { getTeamMember } from "API/get.api";
import linkdln from "Assests/Icons/icons8-linkedin.svg";
import { Link } from "react-router-dom";

const TestimonialCarousal2 = () => {
  const [teamList, setTeamList] = useState([]);

  useEffect(() => {
    const apiStatus = getTeamMember();
    apiStatus
      .then((response) => {
        setTeamList(response?.data);
        // toast("Logged In Successful", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 601 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 0, // optional, default to 1.
    },
  };

  const CardItem = ({
    image,
    firstName,
    lastName,
    desgination,
    linkdlnLink,
  }) => {
    return (
      <div className="card-item">
        <img src={image} alt="member" />

        <div className="content" >

          <div className="content-top">
            <div className="content-left">
              <HeadingUnderline
                bold={firstName}
                heading={lastName}
                align="left"
              />
            </div>
            <div className="content-right">

              <Link to={linkdlnLink !== '' || linkdlnLink !== null ? linkdlnLink : '/'} target="_blank">

                <img
                  src={linkdln}
                  alt="linkdln"
                />
              </Link>
            </div>
          </div>

          <div className="content-bottom">
            <p>{desgination}</p>
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className="ourTeamcarousel">
      <div className="members-list">
        <HeadingUnderline bold="Team " heading="Members" />
        {teamList?.length > 0 && (
          <Carousel
            draggable={false}
            centerMode={window.innerWidth < 1024 ? false : true}
            responsive={responsive}
            infinite={true}
            arrows={false}
            autoPlay={true}
            partialVisbile={false}
            autoPlaySpeed={500}
            transitionDuration={1000}
          >
            {teamList.map((item, i) => (
              <CardItem
                key={i}
                image={item.image}
                firstName={item?.name.split(" ")[0] + " "}
                lastName={item?.name.split(" ")[1]}
                desgination={item?.designation}
                linkdlnLink={item?.linkedin_url}
              />
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default TestimonialCarousal2;
