// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us.partners .top-industry {
  display: flex;
  padding: 7vw 10vmax;
}

.about-us.partners .top-industry .heading-underline {
  flex: 1 1;
  width: 100%;
}

.about-us.partners .top-industry > p {
  flex: 1 1;
  font-size: 1vmax;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/About/Partners/Partners.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,WAAW;AACb;;AAEA;EACE,SAAO;EACP,gBAAgB;AAClB","sourcesContent":[".about-us.partners .top-industry {\n  display: flex;\n  padding: 7vw 10vmax;\n}\n\n.about-us.partners .top-industry .heading-underline {\n  flex: 1;\n  width: 100%;\n}\n\n.about-us.partners .top-industry > p {\n  flex: 1;\n  font-size: 1vmax;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
