import React from "react";
import "../WhyThisProgramCarousel/WhyThisProgram.css";
import Carousel from "react-multi-carousel";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";

const WhyThisMSR = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 991, min: 577 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="WhyThisProgramCarousel">
      <HeadingUnderline bold="Why " heading="This MSR?" align="left" />

      <Carousel
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        transitionDuration={1000}
        partialVisbile={true}
      >
        <div className="Cards">
          <h4>01.</h4>
          <p>
          Master the technical and human skills necessary for effective leadership.
          </p>
        </div>
        <div className="Cards">
          <h4>02.</h4>
          <p>
          Learn proactively with our hands-on methodol-ogy and real case applications.
          </p>
        </div>
        <div className="Cards">
          <h4>03.</h4>
          <p>
          Explore the latest knowledge through our inter disciplinary modules.
          </p>
        </div>
        <div className="Cards">
          <h4>04.</h4>
          <p>
          Build a solid network with from diverse fields and nationalities.
          </p>
        </div>
      </Carousel>
    </div>
  );
};

export default WhyThisMSR;
