import AboutUs from "Pages/About/AboutUs/AboutUs";
import MissionVision from "Pages/About/MissionVision/MissionVision";
import OurTeam from "Pages/About/OurTeam/OurTeam";
import Partners from "Pages/About/Partners/Partners";
import ContactUs from "Pages/ContactUs/ContactUs";
import Csr from "Pages/Csr/Csr";
import Gallery from "Pages/Gallery/Gallery";
import Home from "Pages/Home/Home";
import OurJourney from "Pages/OurJourney/OurJourney";
import Quiz from "Pages/Quiz/Quiz";
import Signin from "Pages/SignIn/Signin";

import Offering from "Pages/Offering/Offering";
import OfferingPage2 from "Pages/Offering/OfferingPage2";
import OfferingPage3 from "Pages/Offering/OfferingPage3";
import OfferingPage4 from "Pages/Offering/OfferingPage4";
import PlanetWarrior from "Pages/PlanetWarriors/PlanetWarrior";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Blogs from "Pages/Blogs/Blogs";
import BlogContainer from "Pages/Blogs/BlogContains";
import Privacy from "Pages/Privacy/Privacy";

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const _path = location.pathname;

  useEffect(() => {
    // First time automatic redirection to home page
    if (_path === "/") {
      navigate("home");
    }
    // eslint-disable-next-line
  }, [_path]);

  return (
    <Routes>
      <Route path="/home" element={<Home />}></Route>

      <Route path="/about/us" element={<AboutUs />}></Route>
      <Route path="/about/mission" element={<MissionVision />} />
      <Route path="/about/team" element={<OurTeam />} />
      {/* <Route path="/about/partners" element={<Partners />} /> */}
      <Route path="/offering/page1" element={<Offering />} />
      <Route path="/offering/page2" element={<OfferingPage2 />} />
      <Route path="/offering/page3" element={<OfferingPage3 />} /> 
      <Route path="/offering/page4" element={<OfferingPage4 />} /> 
      <Route path="/planet-warrior" element={<PlanetWarrior />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/privacy-policy" element={<Privacy />} />

      <Route path="/our-journey" element={<OurJourney />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/quiz" element={<Quiz />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/csr" element={<Csr />} />
      <Route path="/blogs/:id/:slug" element={<BlogContainer />} />

      <Route path="/signin" element={<Signin />} />
    </Routes>
  );
};

export default AppRoutes;
