import React, { useState } from "react";
import "./Pagination.css";

const Pagination = ({ total = 10 }) => {
  const [activeBtn, setActiveBtn] = useState(1);

  const handlePaginationClick = (pageNumber) => {
    setActiveBtn(pageNumber);
  };

  const renderPageButtons = () => {
    const pageButtons = [];

    pageButtons.push(
      <button
        key="prev"
        onClick={() => {
          if (activeBtn > 1) setActiveBtn((prev) => prev - 1);
        }}
      >
        Prev
      </button>
    );

    for (let i = 1; i <= total; i++) {
      pageButtons.push(
        <button
          key={i}
          className={activeBtn === i ? "active" : ""}
          onClick={() => handlePaginationClick(i)}
        >
          {i}
        </button>
      );
    }

    pageButtons.push(
      <button
        key="next"
        onClick={() => {
          if (activeBtn < total) setActiveBtn((prev) => prev + 1);
        }}
      >
        Next
      </button>
    );

    return pageButtons;
  };

  return (
    <div className="pagination">
      <div className="pwrapper">{renderPageButtons()}</div>
    </div>
  );
};

export default Pagination;
