// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-content p.loading-text {
  font-size: 2vmax;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  color: white;
  height: 50vmax;
  background: gray;
}
.blog-content {
  width: 80vmax;
  margin: 0 auto;
  padding: 5vmax 0;
  padding-right: 15%;
}
.blog-content .content-data p{
  text-align: justify !important;
}
.blog-content  .heading-underline {
  padding-bottom: 2vmax;
}
.blog-content p {
  font-size: calc(var(--base-fz) + 2px);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
}

.blog-author-title {
  font-size: calc(var(--base-heading-fz) + 2px);
  text-transform: capitalize;
  color: var(--darkblue);
  margin-top: var(--space-4);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Blogs/BlogContains.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,cAAc;EACd,mBAAmB;EACnB,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,cAAc;EACd,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,qBAAqB;AACvB;AACA;EACE,qCAAqC;EACrC,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,6CAA6C;EAC7C,0BAA0B;EAC1B,sBAAsB;EACtB,0BAA0B;AAC5B","sourcesContent":[".blog-content p.loading-text {\n  font-size: 2vmax;\n  display: flex;\n  justify-content: center;\n  margin: 0 auto;\n  align-items: center;\n  color: white;\n  height: 50vmax;\n  background: gray;\n}\n.blog-content {\n  width: 80vmax;\n  margin: 0 auto;\n  padding: 5vmax 0;\n  padding-right: 15%;\n}\n.blog-content .content-data p{\n  text-align: justify !important;\n}\n.blog-content  .heading-underline {\n  padding-bottom: 2vmax;\n}\n.blog-content p {\n  font-size: calc(var(--base-fz) + 2px);\n  font-weight: 400;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.78;\n  letter-spacing: normal;\n  text-align: left;\n}\n\n.blog-author-title {\n  font-size: calc(var(--base-heading-fz) + 2px);\n  text-transform: capitalize;\n  color: var(--darkblue);\n  margin-top: var(--space-4);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
