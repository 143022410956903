import { toast } from "react-toastify";

export const validators = (formData) => {
  if (!formData.fullname) {
    toast("Please Enter Name", { type: "error" });
    return false;
  }
  if (!/^[a-zA-Z]+([\s][a-zA-Z]+)*$/.test(formData?.fullname)) {
    toast("Allowing Only Alphabets in Name", { type: "error" });
    return false;
  }
  if (!formData.email) {
    toast("Please Enter Email", { type: "error" });
    return false;
  }
  if (!/^[a-zA-Z0-9].+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(formData?.email)) {
    toast("Email address must have @ and domain(.com/.in)", { type: "error" });
    return false;
  }
  if (!formData.phone) {
    toast("Please Enter Phone", { type: "error" });
    return false;
  }
  if (!/^\d{10}$/.test(formData?.phone)) {
    toast("Phone number must have 10-digits", { type: "error" });
    return false;
  }
  if (!formData.city) {
    toast("Please Enter City", { type: "error" });
    return false;
  }
  if (!/^[a-zA-Z ]+$/.test(formData?.city)) {
    toast("Allowing Only Alphabets In City", { type: "error" });
    return false;
  }
  if (!formData.user_type) {
    toast("Please Select User Type", { type: "error" });
    return false;
  }
  if (!formData.password) {
    toast("Please Enter Password", { type: "error" });
    return false;
  }
  if (formData.password.lenght<8) {
    toast("Password Should be 8 Digits.", { type: "error" });
    return false;
  }
  if (!formData.cPassword) {
    toast("Please Re-Enter Password", { type: "error" });
    return false;
  }
  if (formData.password !== formData.cPassword) {
    toast("Password Should be Match", { type: "error" });
    return false;
  }

  // for emails
  //   if (!/^[a-zA-Z0-9].+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(formData?.email)) {
  //     toast("Email address must have @ and domain(.com/.in)", { type: "error" });
  //     return false;
  //   }

  //   if (!/^\d{10}$/.test(formData?.phone)) {
  //     toast("Phone number must have 10-digits", { type: "error" });
  //     return false;
  //   }

  return true;
};
