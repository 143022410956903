import React from "react";
import { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
// import calendarIcon from "Assests/Icons/calendarIcon.svg";
// import Blog1 from "Assests/Images/blog-1.png"
// import Blog2 from "Assests/Images/blog-2.png"
// import Blog3 from "Assests/Images/blog-3.png"
// import SquareButton from "Assests/Images/SquareButton.png"
import Button from "Components/Button/BlogButton";
import "./Popularblog.css";
// import dateFormat from "dateformat";
import { fetchBlogList } from "API/get.api"
import { useNavigate } from "react-router-dom";
import BlogTitle from "Components/BlogTitle/BlogTitle";

const ParticipatingSchoolCarousel = () => {
  const navigate = useNavigate();

  const handleBlogItemClick = (blog) => {
    const { id, slug } = blog;
    navigate(`/blogs/${id}/${slug}`);
  };

  const [blog, setBlogs] = useState([]);

  useEffect(() => {
    const apiStatus = fetchBlogList();
    apiStatus
      .then((response) => {
        const popularBlogs = response.data.filter((blog) => blog.category === "popular");
        setBlogs(popularBlogs);
        // toast("fetch sucess full", { type: "success" });

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="Popularblog">
      <HeadingUnderline bold="Popular " heading="Blogs" />

      <Carousel
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={false}
        autoPlaySpeed={3000}
        transitionDuration={1000}
      >
        {blog.map((blog, index) => (
          <div className="Popularblog__card card--blog" key={index}>

            <div className="card--blog--upper">
              <div className="card--upper--img">
                <img
                  src={blog.image}
                  alt="popularblog"
                  className="Popularblog__card__image"
                />
              </div>
              <div onClick={() => handleBlogItemClick(blog)} className="blogbutton">
                {/* <p>&#8599;</p> */}
                <Button
                  height={"3vmax"}
                  background={"#f7941d"}
                  width={"3vmax"}
                  type="button"
                  color="#fff"
                />
              </div>
            </div>
            <div className="card--blog--bottom">
              <BlogTitle Title={blog.title} Date={blog.created_at} />
            </div>



            {/* <p className="icon">
              <img src={calendarIcon} alt="calendar" className="calendar" />
              {blog.created_at}{" "}
            </p>
            <h4>{blog.title}</h4> */}
          </div>
        ))}

      </Carousel>
    </div>
  );
};

export default ParticipatingSchoolCarousel;
