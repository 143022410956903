import React, { useEffect } from "react";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import PlantWarriorBanner from "Assests/Images/PlanetWarriorBanner.png";
import PlantWarrior from "Assests/Images/plantWarrior.png";
import "./PlanetWarrior.css";
import { useState } from "react";
import { fetchPlanetwarrior } from "API/get.api";
import Pagination from "Components/Pagination/Pagination";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";
import PaginationTwo from "Components/Pagination/PaginationTwo";

const PlanetWarrior = () => {
  const itemsPerPage = window.innerWidth < 600 ? 6 : 3; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [planetWarriorList, setplantWarriorList] = useState([]);
  const [recordsPerPage] = useState(6);
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = planetWarriorList.slice(indexOfFirstItem, indexOfLastItem);
  // const totalPages = Math.ceil(planetWarriorList.length / itemsPerPage);

  // const paginate = pageNumber => {
  //   setCurrentPage(pageNumber);
  // };

  useEffect(() => {
    const apiStatus = fetchPlanetwarrior();
    apiStatus
      .then((response) => {
        setplantWarriorList(response.data);
        // toast("fetch sucess full", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = planetWarriorList.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(planetWarriorList.length / recordsPerPage)

  return (
    <div className="Warrior">
      <Header />
      {/* {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="Planet Warrior"/>)} */}
      <div className="planet-warriors">
        <HeadingBanner image={PlantWarriorBanner} heading="Planet Warriors" subHeading="Empower Change. Be a Planet Warrior." />
      </div>
      <div className="warriors">
        <div className="warrior-cantain">
          <HeadingUnderline bold="Planet Warriors " heading="– Empower Change. Be a Planet Warrior." />
          <p className="About-warriors">
            In the tapestry of our planet, exceptional souls champion sustainability. Introducing Blue Nudge's 'Planet Warrior Certificate'—honoring leaders dedicated to Earth's well-being. Their relentless commitment illuminates a path to a greener future. Let's unite, celebrate, and emulate their inspiring journey. Join us in honoring these remarkable Planet Warriors. Be inspired. Be the change. Be a Planet Warrior.
          </p>
        </div>
        <div className="warrior-gallary">
          <HeadingUnderline bold="Planet " heading="Warriors" />
          <div className="warrior-gallary-container">
            {/* <div className="warrior-gallary-item"> */}
            {currentRecords?.map((warrior, index) => (
              <div className="warrior-gallary-item" key={index}>
                <div className="frame-warrior-gallary-item">
                  <img src={warrior.image} alt={warrior.name} />
                </div>
                <div className="Name">
                  <h4>{warrior.name}</h4>
                </div>
              </div>
            ))}

            {/* <div className="pagination">
              <div className="pwrapper">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => paginate(index + 1)}
                    className={currentPage === index + 1 ? "active" : ""}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div> */}


            {/* <img src={Planetwarrior1} alt="Planetwarrior1" />
              <h4>Mr. Alok Sharma - President, COP 26</h4>
            </div>
            <div className="warrior-gallary-item">
              <img src={Planetwarrior2} alt="Planetwarrior2" />
              <h4>Hon’ble Defence Minister - Shri.Rajnath Singh</h4>
            </div>
            <div className="warrior-gallary-item">
              <img src={Planetwarrior3} alt="Planetwarrior3" />
              <h4>Mr. Simon Wong - Singapore High Commissioner to India</h4>
            </div>
            <div className="warrior-gallary-item">
              <img src={Planetwarrior4} alt="Planetwarrior4" />
              <h4>Ex Governor of Maharashtra - Shri.Bhagat Singh Koshyari</h4>
            </div>
            <div className="warrior-gallary-item">
              <img src={Planetwarrior5} alt="Planetwarrior1" />
              <h4>
                Shri. Kaushal Kishore - Union Minister of Housing and Urban
                Affairs, GOI
              </h4>
            </div>
            <div className="warrior-gallary-item">
              <img src={Planetwarrior6} alt="Planetwarrior6" />
              <h4>Dr. Biswajit Saha - Director, CBSE</h4>
            </div>
            <div className="warrior-gallary-item">
              <img src={Planetwarrior7} alt="Planetwarrior7" />
              <h4>Shri. Anurag Sharma - MP, Lok Sabha</h4>
            </div>
            <div className="warrior-gallary-item">
              <img src={Planetwarrior8} alt="Planetwarrior8" />
              <h4>Ms. Shilpa Shinde, IAS - Additional Commissioner, MCD</h4>
            </div>
            <div className="warrior-gallary-item">
              <img src={Planetwarrior9} alt="Planetwarrior9" />
              <h4>Ms. Alka Sharma - Additional Commissioner, MCD</h4>
            </div> */}
            {/* </div> */}
          </div>
          <PaginationTwo nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage} />
        </div>
        <img src={PlantWarrior} alt="PlantWarrior" className="Plant" />
      </div>
      <Footer />
    </div>
  );
};
export default PlanetWarrior;
