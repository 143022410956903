import React from "react";
import rainbowGradient from "Assests/Icons/rainbowGradient.png";
import rainbowBorder from "Assests/Icons/rainbowBorder.svg";

const Button = ({
  align = "center",
  title,
  background,
  width,
  height,
  padding,
  type,
  rainbowHeight = "3px",
  fontSize = "10px",
  color = "white"
}) => {
  return (
    <div
    className="custom-btn"
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        borderRadius: "5px",
        cursor: "pointer",
        width: width,
      }}
    >
      <button 
      className="btn-primary"
        type={type}
        style={{
          //   alignItems: `${align}`,
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          padding: padding,
          background: background,
          textAlign: align,
          height: height,
          width: width,
          fontSize: fontSize,
          fontWeight: 600,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          border:"none",
          color:color
        //   letterSpacing: "1.4px",
        }}
      >
        {title}
      </button>
      <img
        style={{ width: "100%", objectFit: "cover", height: rainbowHeight }}
        src={rainbowBorder}
        alt="rainbowGradient"
      />
    </div>
  );
};

export default Button;