import React from "react";
import GoogleMapReact from "google-map-react";
import mapmarker from "Assests/Icons/map-marker.png";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function SimpleMap() {
  const defaultProps = {
    center: {
      lat: 28.5529262,
      lng: 77.121497,
    },
    zoom: 11,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "30vh", width: "100%" }}>
      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={28.552926}
          lng={77.121497}
          text={<img style={{width:"40px"}} src={mapmarker} alt="mapmarker" />}
        />
      </GoogleMapReact> */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.5605526076406!2d77.11737692695316!3d28.552925600000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ddf05237271%3A0xb1acd4845a449700!2sBlue%20Planet%20Environmental%20Solutions%20India%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1697023302291!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
