import Carousel from "react-multi-carousel";
import React from "react";
import mrs1 from 'Assests/Images/MSR Certificate.png'
import msr2 from 'Assests/Images/MSR LOA.jpg'
import msr3 from 'Assests/Images/MSR LOR.jpg'
import CertificateBorder from "Assests/Images/CertificateBorder.png";
import "./CertificateCarousel.css";

const CertificateCarouselMsr = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="CertificateCarousel">
      <img src={CertificateBorder} alt="holder" className="holder-img" />

      <Carousel
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        transitionDuration={1000}
      >
        <div className="Img-Container">
          <img
            src={mrs1}
            alt="Certificate"
            className="Certificate-Img"
          />
        </div>
        <div className="Img-Container">
          <img
            src={msr2}
            alt="Certificate"
            className="Certificate-Img"
          />
        </div>
        <div className="Img-Container">
          <img
            src={msr3}
            alt="Certificate"
            className="Certificate-Img"
          />
        </div>
      </Carousel>
    </div>
  );
};

export default CertificateCarouselMsr;
