import React,{useEffect} from "react";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import offringBanner from "Assests/Images/evsBanner.png";
import "./Offering.css";
import BlueIcon from "Assests/Icons/BlueStepIcon.png";
import YellowBook from "Assests/Icons/YellowBookIcon.png";
import BuildingIcon from "Assests/Icons/BuildingIcon2.png";
import AtomIcon from "Assests/Icons/AtomIcon.png";
import EnrollmentIcon from "Assests/Icons/EnrollmentIcon.png";
import EnrollmentIcon1 from "Assests/Icons/EnrollmentIcon2.png";
import LaptopIcon2 from "Assests/Icons/LaptopIcon2.png";
import ReverseIcon from "Assests/Icons/ReverseIcon.png";
import AsainGirl from "Assests/Images/Asian_Girl.png";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import SustainableCarousel from "Components/Carousel/MultiCarousel/SustainableCarousel/SustainableCarousel";
import "./Offeringpage3.css";
import Windmill from "Assests/Images/windmill.gif";
import BagIcon from "Assests/Images/BagIcon.png";
import WhyThisProgram from "Components/Carousel/MultiCarousel/WhyThisProgramCarousel/WhyThisProgramESP";
import ParticipatingSchoolCarousel from "Components/Carousel/MultiCarousel/ParticipatingSchoolCarousel/ParticipatingUniversityCarousel";
import Button from "Components/Button/Button";
import { useInView } from "react-intersection-observer";
import CertificateCarousel from "Components/Carousel/MultiCarousel/CertificateCarousel/CertificateCarouselEsp";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";
import { useLocation } from "react-router-dom";

const OfferingPage3 = () => {
  const [ref, inView] = useInView({ threshold: 0.5, triggerOnce: true });
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="Offering">
      <Header />
      <div className="Offering-3">
      {/* {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="Environmental Studies Program"/>)} */}
      </div>
      <div className="Offering-header">
        <HeadingBanner
          image={offringBanner}
          heading="Environmental Studies Program"
          detail="“Dive into Discovery: Our Environmental Studies Program,
          an exhilarating blend of online and hands-on learning for
          passionate students, igniting change-makers in climate
          action and sustainable practices.”"
        />
      </div>
      <div className="section1">
        {/* <img src={image} alt="background-img" className="background-img" /> */}
        <HeadingUnderline bold="The " heading="Journey" />
        <p className="section1-para">
          An exciting culmination of online and experiential learning, designed
          for students from a multitude of backgrounds interested in climate
          change mitigation efforts and those with a desire to make meaningful
          changes in the way they approach and implement Sustainable Practices.
        </p>
        <div className="card_container-3" ref={ref}>
          <div className="windmill--card WindMill-1">
            <img src={Windmill} className="windmill-1" alt="Windmill 1" />

            <div
              style={{ opacity: "0" }}
              className={`cards-1 ${inView ? "windmill--card-box cards1" : null}`}
            >
              <div className="Imgbox">
                <img
                  src={YellowBook}
                  alt="teahericon"
                  className="teachericon"
                />
              </div>
              <div className="cards-body">
                <h4 className="title">Faculty Training Program (FTP)</h4>
                <p>
                  This program stands as a cornerstone of our commitment to
                  environmental education. Led by industry experts, this
                  intensive training initiative immerses our educators in the
                  intricate realms of sustainability, waste management
                  technologies, and environmental awareness. Through hands-on
                  workshops and insightful sessions, our coordinators gain
                  invaluable practical exposure, equipping them to inspire the
                  next generation of eco-conscious leaders. By bridging the gap
                  between theory and real-world applications, we empower our
                  educators to impart not just knowledge, but a genuine passion
                  for sustainable living. With this training, they become
                  catalysts for change, shaping a future where environmental
                  awareness is not just a subject but a way of life.
                </p>
              </div>
            </div>
          </div>
          <div className="windmill--card WindMill-2">
            <img src={Windmill} className="windmill-2" alt="Windmill 2" />

            <div
              style={{ opacity: "0" }}
              className={`cards-2 ${inView ? "windmill--card-box cards2" : null}`}
            >
              <div className="Imgbox">
                <img src={BagIcon} alt="teahericon" className="teachericon" />
              </div>
              <div className="cards-body">
                <h4 className="title">Blue Nudge Ambassadors (BNA)</h4>
                <p>
                  Empowering Future Leaders: Meet our Blue Nudge Ambassadors!
                  Selected from every class, these dynamic student leaders are
                  the heartbeat of our program. They bridge the gap between
                  their classrooms and our passionate Blue Nudge team, ensuring
                  the vibrant implementation of our initiatives. These
                  Ambassadors are more than leaders; they are environmental
                  champions! With their energy and dedication, they transform
                  classrooms into hubs of sustainable action.
                </p>
              </div>
            </div>
          </div>
          <div className="windmill--card WindMill-3">
            <img src={Windmill} className="windmill-3" alt="Windmill 1" />

            <div
              style={{ opacity: "0" }}
              className={`cards-3 ${inView ? "windmill--card-box cards3" : null}`}
            >
              <div className="Imgbox">
                <img src={BlueIcon} alt="teahericon" className="teachericon" />
              </div>
              <div className="cards-body">
                <h4 className="title">Hand-on Experience</h4>
                <p>
                  Students Experience the best of both worlds with Blue Nudge's
                  Environmental Studies Program. It offers a hybrid learning
                  adventure, seamlessly blending online education with
                  real-world action. Through nine engaging sustainability
                  modules, students gain profound insights online, building a
                  strong foundation. But the real magic happens in the field,
                  where theory transforms into practice. Here, students actively
                  implement diverse activities focusing on Sustainable
                  Development Goals (SDGs), honing their skills as eco-leaders.
                  This hands-on approach fosters a deep understanding of
                  environmental challenges and innovative solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SustainableCarousel />
      <ParticipatingSchoolCarousel />
      <div className="off--pg-3 About-us">
        <img
          src={AsainGirl}
          alt="OfferingAboutUsImg"
          className="OfferingAboutUsImg"
        />
        <div className="About-us-contaian-2">
          <HeadingUnderline bold="About " heading="The Program" align="left" />
          <p style={{
            fontSize:'14px'
          }}>
            The program consists of online modules and community engagement
            activities covering issues and information on sustainability and
            climate change. It aims at empowering the youth of this country with
            the right knowledge and skills to be an active enthusiast in the
            fight against climate change.
            <br />
            <br />
            Participating in this program will also serve as a springboard for
            student’s academic and professional growth. The holistic,
            interactive and problem-solving exposure will open doors for various
            national and international opportunities and deepen student’s
            understanding of sustainability and climate change.
            <br />
            <br />
            By the end of the program, students will be equipped with a set of
            essential skills to help them stand out during their undergraduate
            application process and later to enter the professional world.
          </p>
        </div>
      </div>
      <div className="methodolyPage3">
        <HeadingUnderline bold="Methodology " heading="and Assessment" />
        <div className="methodology_card-container">
          <div className="methodology_card">
            <div className="methodology_card-img">
              <img src={EnrollmentIcon1} alt="methodalogy" />
            </div>
            <p>Online Engagement</p>
          </div>
          <div className="methodology_card">
            <div className="methodology_card-img">
              <img src={BuildingIcon} alt="methodalogy" />
            </div>
            <p>Self Assessment</p>
          </div>
          <div className="methodology_card">
            <div className="methodology_card-img">
              <img src={ReverseIcon} alt="methodalogy" />
            </div>
            <p>Performance Review</p>
          </div>
          <div className="methodology_card">
            <div className="methodology_card-img">
              <img src={LaptopIcon2} alt="methodalogy" />
            </div>
            <p>Webinar with Industry Experts</p>
          </div>
          <div className="methodology_card">
            <div className="methodology_card-img">
              <img src={AtomIcon} alt="methodalogy" />
            </div>
            <p>Community Engagement</p>
          </div>
          <div className="methodology_card">
            <div className="methodology_card-img">
              <img src={EnrollmentIcon} alt="methodalogy" />
            </div>
            <p>Reporting and Implementation</p>
          </div>
        </div>
      </div>
      <WhyThisProgram />
      <div className="section2">
        <CertificateCarousel />
        <div className="section2-content">
          <HeadingUnderline
            bold="The Environmental "
            heading="Studies Certificate"
            align={window.innerWidth>600? "left": "center"}
          />
          <p className="section2-contents">
            Elevate your impact! Dive into our Environmental Studies Program and
            gain vital skills in sustainability, waste management, and
            environmental awareness. Plus, Participating students receive a
            Planet Warrior Certificate, Blue Nudge Ambassadors (BNA) receive a
            special BNA certificate showcasing their leadership. Also, faculties
            receive a special Faculty Training certificate. Be a hero for the
            planet – join us today!
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default OfferingPage3;
