import React, { useState, useRef } from "react";
import "./ContactUs.css";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import ContactWanner from "Assests/Images/ContactUsBanner.jpg";
import contactinner from "Assests/Images/contact-us-inner.png";
import greenLocation from "Assests/Icons/green-location.png";
import greenEmail from "Assests/Icons/green-email.png";
import greenCall from "Assests/Icons/green-call.png";
import MapContainer from "Components/Map/MapContainer";
import Footer from "Components/Footer/Footer";
import { contactSave } from "API/post.api";
import { toast } from "react-toastify";
import Button from "Components/Button/Button";

const ContactUs = () => {
  const [formData, setFormData] = useState({});
  const formRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // validation
    const regEmail = new RegExp(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    if (Boolean(formData?.email) && !regEmail.test(formData?.email)) {
      toast("Email format is incorrect!", { type: "error" });
      return;
    }
    const apiStatus = contactSave(formData);
    apiStatus
      .then((response) => {
        if (response.status===201) {
          toast("Thank you for Contacting Us!", { type: "success" });
          setFormData({});
          formRef.current.reset();
        }
      })
      .catch((err) => {
        console.log(err);
        toast(err?.response?.data?.detail, { type: "error" });
      });
  };

  return (
    <div className="contact-us">
      <Header />

      <HeadingBanner
        image={ContactWanner}
        heading="Contact Us"
        detail="Connect with Us: Where Sustainability Meets Dialogue,
        and Collaborations Take Root."
      />
      <div className="contact-us-Wrapper">
        <img src={contactinner} alt="contactinner" className="banner-img" />
        <div className="content">
          <div className="left-section">
            <form ref={formRef} onSubmit={handleSubmit}>
              <h1>Contact Us</h1>
              <div className="inputWrapper">
                <p>Full Name</p>
                <input
                  type="text"
                  name="full_name"
                  autoComplete="off"
                  required
                  onChange={handleChange}
                  placeholder="Enter Your Full Name"
                />
              </div>
              <div className="inputWrapper">
                <p>Email</p>
                <input
                  type="email"
                  name="email"
                  autoComplete="off"
                  required
                  onChange={handleChange}
                  placeholder="Enter Your Email Address"
                />
              </div>
              <div className="inputWrapper">
                <p>Message</p>
                <textarea
                  rows="6"
                  name="message"
                  autoComplete="off"
                  required
                  onChange={handleChange}
                  placeholder="Enter Your Message"
                />
              </div>
              <div>
                <Button
                  height={window.innerWidth < 991 ? "auto" : "2vw"}
                  title="SEND MESSAGE"
                  background={"#f7941d"}
                  width="165px"
                  padding={window.innerWidth < 991 ? "0.7rem 1.4rem" : "3%"}
                  type="submit"
                  fontSize={
                    window.innerWidth < 991 ? "var(--base-fz)" : ".7vmax"
                  }
                />
              </div>
            </form>
          </div>
          <div className="right-section">
            <h1>Contact Us</h1>
            <div className="Container">
              <h2>
                We would be really pleased to assist you, reach out to us per
                your convenience.
              </h2>
              <div className="sameLine">
                <img src={greenLocation} alt="greenlocation" />
                <p>
                  Suite, 5D, Aria Signature Tower JW Marriott, New Delhi-110037
                </p>
              </div>
              <div className="sameLine">
                <img src={greenEmail} alt="greenEmail" />
                <p>info@blue-nudge.com</p>
              </div>
              <div className="sameLine">
                <img src={greenCall} alt="greenCall" />
                <p>011-40059100</p>
              </div>
              <div className="mapContainer">
                <MapContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
