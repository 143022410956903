import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import React from "react";
import Carousel from "react-multi-carousel";
import latestGalleryImg3 from "Assests/Images/Latest/20230828_092754 (2).jpg";
import latestGalleryImg2 from "Assests/Images/Latest/IMG_20220829_153736.jpg";
import latestGalleryImg1 from "Assests/Images/Latest/WhatsApp Image 2023-10-25 at 1.51.20 PM.jpeg";
import latestGalleryImg4 from "Assests/Images/Latest/WhatsApp Image 2023-10-27 at 11.51.37 PM.jpeg";
import latestGalleryImg5 from "Assests/Images/Latest/WhatsApp Image 2023-10-27 at 11.51.36 PM.jpeg";
import latestGalleryImg6 from "Assests/Images/Latest/WhatsApp Image 2023-10-27 at 11.51.38 PM.jpeg";
import latestGalleryImg7 from "Assests/Images/Latest/latestgallary3.77a4c000cc7be63e6f05.jpg";
import latestGalleryImg8 from "Assests/Images/Latest/latestgallary5.95227f90ca4c3d53ca14.jpg";
import latestGalleryImg9 from "Assests/Images/Latest/g_li_001.png";
import latestGalleryImg10 from "Assests/Images/Latest/g_li_002.jpg";
import latestGalleryImg11 from "Assests/Images/Latest/g_li_003.jpg";
import latestGalleryImg12 from "Assests/Images/Latest/g_li_004.jpg";
import latestGalleryImg13 from "Assests/Images/Latest/g_li_005.jpg";
import latestGalleryImg14 from "Assests/Images/Latest/g_li_006.jpg";
import latestGalleryImg15 from "Assests/Images/Latest/g_li_007.png";
import latestGalleryImg16 from "Assests/Images/Latest/g_li_008.png";
import latestGalleryImg17 from "Assests/Images/Latest/g_li_009.jpg";
import latestGalleryImg18 from "Assests/Images/Latest/g_li_010.png";
import "./LatestGalleryCarousel.css";

const LatestGalleryCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
      // partialVisibilityGutter: 120,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const CardItem = ({ image, title }) => {
    return (
      <div className="card-item">
        <img src={image || ""} alt="card" />
        <p>{""}</p>
        {/* <p>{title || ""}</p> */}
      </div>
    );
  };

  return (
    <div className="latest-gallery-carousel">
      <HeadingUnderline bold="Latest" align="left" />

      <Carousel
        draggable={false}
        responsive={responsive}
        autoPlay={true}
        infinite={true}
        arrows={false}
        autoPlaySpeed={500}
        transitionDuration={1000}
      >
        <CardItem image={latestGalleryImg1}  />
        <CardItem image={latestGalleryImg2}  />
        <CardItem image={latestGalleryImg3}  />
        <CardItem image={latestGalleryImg4}  />
        <CardItem image={latestGalleryImg5}  />
        <CardItem image={latestGalleryImg6}  />
        <CardItem image={latestGalleryImg7}  />
        <CardItem image={latestGalleryImg8}  />
        <CardItem image={latestGalleryImg9}  />
        <CardItem image={latestGalleryImg10}  />
        <CardItem image={latestGalleryImg11}  />
        <CardItem image={latestGalleryImg12}  />
        <CardItem image={latestGalleryImg13}  />
        <CardItem image={latestGalleryImg14}  />
        <CardItem image={latestGalleryImg15}  />
        <CardItem image={latestGalleryImg16}  />
        <CardItem image={latestGalleryImg17}  />
        <CardItem image={latestGalleryImg18}  />
      </Carousel>
    </div>
  );
};

export default LatestGalleryCarousel;



// title="CMS LAUNCH"
// title="CMS LAUNCH"
// title="CMS LAUNCH"
// title="CMS LAUNCH"
// title="CMS LAUNCH"