import httpPathName from "Global/Config";

export const register = async (data) => {
  const promise = await httpPathName.post(`accounts/register/`, { ...data });
  return promise;
};

export const login = async (data) => {
  const promise = await httpPathName.post(`accounts/login/`, { ...data });
  return promise;
};

export const quizAttempt = async (data, token) => {
  const promise = await httpPathName.post(
    `webapp/api/quiz-attempt/`,
    { ...data },
    {
      headers: { Authorization: `Token ${token}` },
    }
  );
  return promise;
};

export const retakeQuizAttempt = async (quizId, createdBy, token) => {
  const promise = await httpPathName.post(
    `webapp/retake/?quiz_id=${quizId}&created_by=${createdBy}`,
    {
      headers: { Authorization: `Token ${token}` },
    }
  );
  return promise;
};

export const contactSave = async (data) => {
  const promise = await httpPathName.post(`webapp/api/contact-us/`, {
    ...data,
  });
  return promise;
};
