import axios from "axios";
import httpPathName from "Global/Config";

// export const fetchTheCartApi = async (token) => {
//     const promise = await httpPathName.get(`cart/fetchCart`, {
//       headers: { "x-auth-token": token },
//     });
//     return promise;
//   };

// quiz section

// get quiz list
export const fetchQuizList = async () => {
  const promise = await httpPathName.get(`webapp/api/quiz/`);
  return promise;
};

export const fetchAllQuestionQuiz = async (id, token) => {
  const promise = await httpPathName.get(
    `webapp/api/quiz-questionare/?quiz__id=${id}`,
    {
      headers: { Authorization: `Token ${token}` },
    }
  );
  return promise;
};

export const fetchAllQuizResult = async (quizId, createdBy, token) => {
  const promise = await httpPathName.get(
    `webapp/api/quiz-attempt/?quiz_id=${quizId}&created_by=${createdBy}`,
    {
      headers: { Authorization: `Token ${token}` },
    }
  );
  return promise;
};

export const fetchBlogList = async () => {
  const promise = await httpPathName.get(`webapp/api/blogs/`);
  return promise;
};

export const fetchBlogListPastRecent = async (past) => {
  try {
    const today = new Date();
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const formattedDate = thirtyDaysAgo.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"

    const response = await httpPathName.get(
      past
        ? `webapp/api/blogs/?created_at>=${formattedDate}`
        : `webapp/api/blogs/?created_at<=${formattedDate}`
    );

    return response.data; // Assuming you want to return the data from the response
  } catch (error) {
    console.error("Error fetching blog list:", error);
    throw error; // You can handle the error as needed
  }
};

export const fetchBlogDetail = async (id) => {
  const promise = await httpPathName.get(`webapp/api/blogs/${id}/`);
  return promise;
};
export const fetchPlanetwarrior = async () => {
  const promise = await httpPathName.get(`webapp/api/planet-warriors-page/`);
  return promise;
};

export const fetchCsr = async (year) => {
  const promise = await httpPathName.get(`webapp/api/csr-story/`);
  return promise;
};

export const getTeamMember = async () => {
  const promise = await httpPathName.get(`/webapp/api/leadership/`);
  return promise;
};

export const getJourneyStory = async (month, year) => {
  const promise = await httpPathName.get(
    `/webapp/api/our-journey/?month=${month}&year=${year}`
  );

  // const promise = await axios.get(
  //   "http://52.66.108.144:81/admin_app/dashboard_counts",
  //   {
  //     headers: {
  //       Authorization: "Token 203e80921ff88a20bf0b7c0099fb99303db36b59",
  //     },
  //   }
  // );
  return promise;
};
