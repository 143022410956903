import React, { useEffect } from "react";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import offringBanner from "Assests/Images/backgrounds/banner-eco-xs-desktop.jpeg";
import Background_2 from "Assests/Images/backgrounds/bg-offering-4-kf.jpeg"
import "./Offering4.css";
import GamificationChallenges2 from "Assests/Images/icons/offerings-4/gamification-and-challenges2.png";
import ImmersiveVirtualExperiences2 from "Assests/Images/icons/offerings-4/immersive-virtual-experiences2.png";
import InteractiveLearningModules2 from "Assests/Images/icons/offerings-4/interactive-learning-modules2.png";
import StudentIcon from "Assests/Icons/StudentIcon.png";
import SustainabilityIcon from "Assests/Icons/SustainabilityIcon.png";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import SustainableCarousel from "Components/Carousel/MultiCarousel/SustainableCarousel/SustainableCarousel";
import { useLocation } from "react-router-dom";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";
// import OfferingPage3 from "./OfferingPage3";
import whyChooseUs from "Assests/videos/offering-4/why-choose-us.mp4"
import WhyChooseEcoXRclub from "Components/Carousel/MultiCarousel/WhyChooseEcoXRclub/WhyChooseEcoXRclub";
import WhyThisProgramOpFour from "Components/Carousel/MultiCarousel/WhyThisProgramCarousel/WhyThisProgramOpFour";
import ParticipatingSchoolCarouselOPFour from "Components/Carousel/MultiCarousel/ParticipatingSchoolCarousel/ParticipatingSchoolCarouselOPFour";
import CertificateCarouselEcoXRClub from "Components/Carousel/MultiCarousel/CertificateCarousel/CertificateCarouselEcoXRClub";

const OfferingPage4 = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="Offering Offering-4">
            <Header />
            <div className="Offering-1">
                {/* {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="Waste To Wealth"/>)} */}
            </div>
            <HeadingBanner
                image={offringBanner}
            // heading="EcoXRclub"
            // detail="Teaching next level “Green Immersive Learning"
            />
            <div
                className="section1"
                style={{
                    backgroundImage: `url(${Background_2})`,
                }}
            >
                {/* <img src={image} alt="background-img" className="background-img" /> */}
                <HeadingUnderline bold="Key " heading="Features" />
                <p>
                    Unlock the future of environmental education with EcoXRclub. Harnessing cutting-edge XR technology, AR, VR, 3D & 2D models, and Unreal Engine, EcoXRclub offers immersive virtual experiences that redefine how students engage with environmental issues
                </p>
                <div className="card_container">
                    <div className="card card1">
                        <div className="Imgbox">
                            <img src={ImmersiveVirtualExperiences2} alt="Immersive Virtual Experiences" className="img-fluid" />
                        </div>
                        <div className="card-body">
                            <h4>Immersive Virtual Experiences</h4>
                            <p>
                                EcoXRclub transports users to breathtaking natural
                                landscapes, allowing them to explore and interact with virtual ecosystems, wildlife, and
                                environmental phenomena in a captivating and engaging manner.
                            </p>
                        </div>
                    </div>
                    <div className="card card2">
                        <div className="Imgbox">
                            <img src={InteractiveLearningModules2} alt="Interactive Learning Modules" className="img-fluid" />
                        </div>
                        <div className="card-body">
                            <h4>Interactive Learning Modules</h4>
                            <p>
                                The platform offers a suite of educational modules that
                                cover a wide range of environmental topics, from renewable energy and sustainable
                                practices to biodiversity conservation and climate change. These modules are designed
                                to be interactive, informative, and thought-provoking.
                            </p>
                        </div>
                    </div>
                    <div className="card card3">
                        <div className="Imgbox">
                            <img
                                src={GamificationChallenges2}
                                alt="Gamification and Challenges"
                                className="img-fluid"
                            />
                        </div>
                        <div className="card-body">
                            <h4>Gamification and Challenges</h4>
                            <p>
                                EcoXRclub incorporates gamification elements, such as
                                challenges and achievements, to encourage users to actively participate in
                                environmental initiatives and adopt sustainable behaviors.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <SustainableCarousel />

            <ParticipatingSchoolCarouselOPFour />

            <div className="About-us">
                <video
                    src={whyChooseUs}
                    autoPlay
                    loop
                    className="OfferingAboutUsImg"
                >
                    Your browser does not support the video tag.
                </video>
                {/* <img
                    src={OfferingAboutUsImg}
                    alt="OfferingAboutUsImg"
                    className="OfferingAboutUsImg"
                /> */}
                <div className="About-us-contaian">
                    <HeadingUnderline bold="About " heading="The Program" align="left" />
                    <p>EcoXRclub, is a groundbreaking product that leverages the power of extended reality (XR)
                        technology, AR, VR, 3D & 2D models, and Unreal Engine Technology to revolutionize
                        environmental education and engagement. EcoXRclub is designed to immerse users in
                        interactive, virtual environments that showcase the wonders of nature, the impact of human
                        activities, and the steps we can take to protect our planet.
                    </p>
                </div>
            </div>
            <WhyChooseEcoXRclub />
            <WhyThisProgramOpFour />
            <div className="section2">
                {/* <img
          src={CertificateHolder}
          alt="CertificateHolder"
          className="CertificateHolder"
        /> */}
                <CertificateCarouselEcoXRClub />
                <div className="section2-content">
                    <HeadingUnderline
                        bold="Certificate of "
                        heading="Completion"
                        align={window.innerWidth > 600 ? "left" : "center"}
                    />
                    <p className="section2-contents">
                        By partnering with Blue Nudge and leveraging EcoXRclub, schools empower students with a
                        transformative environmental education experience. Prepare the next generation for a
                        sustainable future today.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default OfferingPage4;
