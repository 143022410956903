import React, { useState, useEffect } from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import SigininWanner from "Assests/Images/Ourjournybanner.png";
import rightChevron from "Assests/Icons/rightChevron.svg";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";
import "./Csr.css";
import { fetchCsr } from "API/get.api";
import moment from "moment";
import { toast } from "react-toastify";
const Csr = () => {
  const [date, setDate] = useState("");
  const [csrData, setCsrData] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const apiStatus = fetchCsr();
    apiStatus
      .then((response) => {
        setCsrData(response?.data);
        // toast("Contact Saved", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
        toast(err?.response?.data?.detail, { type: "error" });
      });
  }, []);

  const data = [
    {
      title: "IndusTree",
      year: 2023,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      title: "IndusTree",
      year: 2023,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      title: "IndusTree",
      year: 2023,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      title: "IndusTree",
      year: 2007,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      title: "IndusTree",
      year: 2023,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      title: "IndusTree",
      year: 2023,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ];
  const StoryCard = ({ title, year, month, description, image, another_image }) => {
    return (
      <div className="story-card">
        <div className="same-line">
          <div className="one-line">
            <p>{title}</p>
            <div className="img-wrapper">
              <img src={image} alt={title} />
              <img src={another_image} alt={title} />
            </div>

          </div>

          <p className="year">{`${month} ${year}`}</p>
        </div>
        {/* <div className="image-Wraper"></div> */}
        <span>{description}</span>
      </div>
    );
  };

  return (
    <div className="csr">
      {/* {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="CSR"/>)} */}
      <Header />
      <HeadingBanner
        image={SigininWanner}
        heading="Corporate Social
        Responsibility"
        detail="Fostering Impactful Partnerships: Empowering Communities
        through our Corporate Social Responsibility Funded Initiatives."
      />
      <div className="csr-container">
        <div className="our-story">
          <HeadingUnderline bold="Our " heading="Story" align="left" />
          {/* <h1><span>Our</span> Story</h1> */}

          {/* <div className="input-section">
            <input
              type="month"
              placeholder="Select Year"
              style={{ background: "transparent" }}
              value={date}
              name="month"
              id="month"

              onChange={(e) => setDate(e.target.value)}
            />
            <img src={rightChevron} alt="chevron" />
          </div> */}

          {/* <select>
            <option>Select Year</option>
            <option value={2021}>2021</option>
          </select> */}
        </div>
        <div className="timeline">
          {csrData?.length > 0 ? (
            <div div className="timeline-bar">
              {csrData?.map((item, i) => (
                <div
                  className={`card-container ${i % 2 === 0 ? "right-container" : "left-container"
                    }`}
                  key={i}
                >
                  <div className="dot"></div>
                  <StoryCard
                    title={item?.title}
                    year={item?.year}
                    month={item?.month}
                    description={item?.description}
                    image={item?.image}
                    another_image={item?.another_image}
                  />
                </div>
              ))
              }</div>) : (
            <div className="no-data-found">No Story</div>
          )}
          {/* <div className="card-container left-container">
            <div className="dot"></div>
            <StoryCard
              title={"IndusTree"}
              year={2008}
              description={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
              }
            />
          </div> */}
          {/* <div className="card-container right-container">
          <div className="dot"></div>
            <StoryCard
              title={"IndusTree"}
              year={2008}
              description={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
              }
            />
          </div>
          <div className="card-container left-container"> 
          <div className="dot"></div>
            <StoryCard
              title={"IndusTree"}
              year={2008}
              description={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
              }
            />
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Csr;
