import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import ButtonIcon from "Assests/Icons/button.svg";
import Carousel from "react-multi-carousel";
import React from "react";
import latestGalleryImg1 from "Assests/Images/Home Page/IMG_20221203_151646.jpg";
import latestGalleryImg2 from "Assests/Images/Home Page/WhatsApp Image 2021-12-22 at 11.25.31 (1).jpg";
import latestGalleryImg3 from "Assests/Images/Home Page/WhatsApp Image 2022-07-18 at 11.23.58 AM (1).jpg";
import pastCar2 from "Assests/Images/Home Page/WhatsApp Image 2023-07-25 at 4.41.55 PM.jpeg";
import pastCar3 from "Assests/Images/Home Page/WhatsApp Image 2023-10-18 at 3.28.21 PM.jpeg";
import pastCar4 from "Assests/Images/Home Page/WhatsApp Image 2023-10-25 at 1.51.20 PM.jpeg";
import pastCar5 from "Assests/Images/Home Page/WhatsApp Image 2023-10-27 at 11.51.36 PM.jpeg";
import pastCar6 from "Assests/Images/Home Page/WhatsApp Image 2023-10-28 at 12.14.01 AM.jpeg";
import pastCar7 from "Assests/Images/Home Page/WhatsApp Image 2023-10-28 at 12.17.23 AM.jpeg";
import "./GalleryCarousel.css";
import { useNavigate } from "react-router-dom";
import Button from "Components/Button/Button";

const GalleryCarousel = () => {
  const navigate = useNavigate();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 601 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="gallery-carousel">
      <div className="wrapper">
        <div className="content">
          <HeadingUnderline bold="Gallery" align="left" />
          <p>
            Dive into a World of Sustainability: Explore Our Green Gallery,
            Featuring Inspiring Images of Environmental Awareness and Student
            Passion. Witness the Impact of Our School and University Programs,
            Where Tomorrow's Leaders Embrace Sustainability, One Project at a
            Time. Capturing the Essence of Passionate Learning, Fostering
            Eco-conscious Minds for a Greener Tomorrow
          </p>
        </div>

        <div className="button btn btn-primary" onClick={() => navigate("/gallery")}>
          <Button
            height={window.innerWidth>991?"4vmax":"auto"}
            title="VIEW ALL"
            background={"#f7941d"}
            width={window.innerWidth>991?"15vmax":"fit-content"}
            padding={"0.5rem 1rem"}
            type="button"
            fontSize={window.innerWidth>991?"1.2vmax":"var(--base-fz)"}
            color="#fff"
          />
        </div>

        {/* <button className="btn-background" onClick={() => navigate("/gallery")}>
          <img src={ButtonIcon} alt="button" />
          <p>VIEW ALL</p>
        </button> */}
      </div>

      <Carousel
        draggable={false}
        centerMode={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={1500}
        transitionDuration={1000}
      >
        <div >
          <img src={latestGalleryImg1} alt="unicef" />
          {/* <p>CMS LAUNCH</p> */}
        </div>

        <div >
          <img src={latestGalleryImg2} alt="icici" />
          {/* <p>EDUCATION MINISTER MAHARASTRA</p> */}
        </div>

        <div >
          <img src={latestGalleryImg3} alt="delhiResearch" />
          {/* <p>ENERGY SAVING TIP #1</p> */}
        </div>

        <div >
          <img src={pastCar2} alt="mylan" />
          {/* <p>ENERGY SAVING TIP #3</p> */}
        </div>

        <div >
          <img src={pastCar3} alt="tata" />
          {/* <p>COLLECTIVE RESPONSIBILITY DRIVE</p> */}
        </div>

        <div >
          <img src={pastCar4} alt="tata" />
          {/* <p>COLLECTIVE RESPONSIBILITY DRIVE</p> */}
        </div>
        <div >
          <img src={pastCar5} alt="tata" />
          {/* <p>COLLECTIVE RESPONSIBILITY DRIVE</p> */}
        </div>
        <div >
          <img src={pastCar6} alt="tata" />
          {/* <p>COLLECTIVE RESPONSIBILITY DRIVE</p> */}
        </div>
        <div >
          <img src={pastCar7} alt="tata" />
          {/* <p>COLLECTIVE RESPONSIBILITY DRIVE</p> */}
        </div>
      </Carousel>
    </div>
  );
};

export default GalleryCarousel;
