import React from "react";
import Carousel from "react-multi-carousel";
import CertificateTwo from 'Assests/Images/certificate/offering-4/certificate-2.jpeg';
import CertificateBorder from "Assests/Images/CertificateBorder.png";
import "./CertificateCarousel.css";

const CertificateCarouselEcoXRClub = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    return (
        <div className="CertificateCarousel">
            <img src={CertificateBorder} alt="holder" className="holder-img" />

            <Carousel
                draggable={false}
                showDots={true}
                responsive={responsive}
                infinite={true}
                arrows={false}
                autoPlay={true}
                autoPlaySpeed={3000}
                transitionDuration={1000}
            >
                <div className="Img-Container">
                    <img
                        src={CertificateTwo}
                        alt="Certificate"
                        className="Certificate-Img"
                    />
                </div>
            </Carousel>
        </div>
    );
};

export default CertificateCarouselEcoXRClub;
