import { USER_DATA } from "Redux/ActionType";

const initalObj = [];

export const setUserDataReducer = (state = initalObj, action) => {
  switch (action.type) {
    case USER_DATA:
      if (!action.payload) {
        action.payload = initalObj;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};
