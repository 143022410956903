import React from "react";
import principal1 from "Assests/Images/principal1.png";
import principal2 from "Assests/Images/principal2.png";
import charu from "Assests/Images/charu.jpg";
import rupa from "Assests/Images/Principal_2.jpg";
import newCharu from "Assests/Images/new-charu.png";
import Carousel from "react-multi-carousel";
import school1 from "Assests/Images/school1.png";
import "./ParticipatingCarousel.css";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";

const ParticipatingCarousel = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1199 },
      items: 1,
      partialVisibilityGutter: 400
    },
    desktop: {
      breakpoint: { max: 1199, min: 991 },
      items: 1,
      partialVisibilityGutter: 200
    },
    tablet: {
      breakpoint: { max: 990, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const CardItem = ({ image, content, name, role, school }) => {
    return (
      <div className="card-item">
        <div className="card-img">
          <img src={image} alt="principal" />
        </div>
        <div className="content">
          <div className="wrapper">{content}</div>

          <div className="bottom">
            <div>
              <h6>{name}</h6>
              <p>
                {role},<b>{school}</b>
              </p>
            </div>
            {/* 
            <img src={school1} alt="school" /> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="participating-carousel">
      <HeadingUnderline bold="Participating " heading="Schools" />
      <Carousel
        draggable={false}
        responsive={responsive}
        partialVisible={true}
        autoPlay={true}
        infinite={true}
        arrows={false}
        autoPlaySpeed={3000}
        transitionDuration={1000}
        pauseOnHover={true}
      >
        <div>

        <CardItem
          image={newCharu}
          content={
            <div>
              <p>
                This Blue Nudge Initiative which has been brought by Blue
                Planet Environmental Solutions to the school students is I
                would say a really a wonderful initiative because I would it
                is like a chain reaction that has set that has been set up
                from students to parents and from parents to Society at Large.
                The Blue Nudge initiative of Blue Planet organization is
                already doing a wonderful job of converting waste to benches.
                The blue nudge program has really helped in sensitizing our
                students towards their roles and responsibilities to save the
                planet Earth and we from DAV organization following the Vedic
                Culture that we know it from thousands of years highlighting
                that the human existence is possible only and only if we are
                coexisting with the biotic and abiotic components but we had
                forgotten this and Blue Nudge has again brought this to the
                students. We thank Blue Nudge for nudging students to come
                back and unite with this environment.{" "}
              </p>
            </div>
          }
          name={"Ms. Charu Maini"}
          role={"Principal"}
          school={"DAV Public School, Sector-49, Gurugram"}
        />
        </div>
        <div>
        <CardItem
          image={rupa}
          content={
            <div>
              <p>
                I have come to a very clear understanding that “There is no
                such thing as ' throwing away'. When we throw anything away,
                it must go somewhere.”I can't even imagine how many trees we
                could save just by recycling newspaper waste, let alone any
                other paper waste altogether. “When you put the whole picture
                together, recycling is the best thing to do.” We cannot solve
                our problems with the same thinking we used, when we created
                them in the first place. It's high time that we stop passing
                the parcel and start acting.I can't be thankful enough to Blue
                Nudge which is a part of Blue Planet Environment Solutions for
                taking up this long lost initiative and for owning up this
                responsibility and giving us all the opportunity to do our bit
                for the environment.
              </p>
            </div>
          }
          name={"Ms. Anita Gautam,"}
          role={"Principal"}
          school={"D.A.V. Public School, Sector-14, Faridabad"}
        />
        </div>
        
        
      </Carousel>
    </div>
  );
};

export default ParticipatingCarousel;
