import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
// import rainbowGradient from "Assests/Icons/rainbowGradient.png";
import aboutUsBannerImg from "Assests/Images/aboutUsBannerImg.png";
import aboutUsBannerImg2 from "Assests/Images/about-us-banner-img-2.jpg";

import React from "react";
import "./AboutCarousel.css";

const AboutCarousel = () => {
  const OverlaySection = () => {
    return (
      <div className="overlay-section">
        <HeadingUnderline bold="About " heading="Us" align="left" />

        <p>
          Blue Nudge is an Education to Action (EdAct) platform that focuses on
          community engagement and behavioral change to address environmental
          challenges. We aim at instilling a sense of responsibility in students
          towards their planet and environment and encourage them to take up
          sustainability as a way of living.
        </p>

        <p>
          Blue Nudge intends to integrate waste management, sustainability and
          academic curriculums. It is a simple yet effective way to reach the
          basic unit of a society, a household. Through these
          students/learners/individuals connect their with families to encourage
          sustainable living and better management of waste.
        </p>
      </div>
    );
  };

  const CardItem = ({ image }) => {
    return (
      <div className="card-item">
        <div className="blank"></div>
        <div className="image-holder">
          <img src={image} alt="item" className="carousel-img" />
          {/* <img src={rainbowGradient} alt="gradient" className="gradient" /> */}
        </div>
      </div>
    );
  };

  return (
    <div className="about-carousel">
      <CardItem image={aboutUsBannerImg2} />

      <OverlaySection />
    </div>
  );
};

export default AboutCarousel;
