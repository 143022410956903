import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import ButtonIcon from "Assests/Icons/button.svg";
import React, {
  Children,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Button from "Components/Button/Button";

const QuizCarousel = ({ children }) => {
  const containerRef = useRef(null);
  const containerRightRef = useRef(null);
  const intervalRef = useRef(null);
  const [current, setCurrent] = useState(1);
  const [currentRight, setCurrentRight] = useState(1);
  const [translateX, setTranslateX] = useState(0);
  const [translateRightX, setTranslateRightX] = useState(0);
  const [isMouseHover, setIsMouseHover] = useState(false);
  const navigate = useNavigate();

  //   for infinite scrolling (upward slider)
  useEffect(() => {
    const localConatinerRef = containerRef?.current;

    const transitionEnd = () => {
      // upward slider
      if (current <= 1) {
        localConatinerRef.style.transitionDuration = "0ms";
        setTranslateX(localConatinerRef?.clientHeight * current);
      }
      if (current >= children?.length) {
        localConatinerRef.style.transitionDuration = "0ms";
        setTranslateX(localConatinerRef?.clientHeight * children?.length);
      }
    };

    localConatinerRef?.addEventListener("transitionend", transitionEnd);

    return () => {
      localConatinerRef?.removeEventListener("transitionend", transitionEnd);
    };
  }, [current, children]);

  //   for infinite scrolling (downward slider)
  useEffect(() => {
    const localConatinerRightRef = containerRightRef?.current;

    const transitionEnd = () => {
      // upward slider
      if (currentRight <= 1) {
        localConatinerRightRef.style.transitionDuration = "0ms";
        setTranslateRightX(localConatinerRightRef?.clientHeight * currentRight);
      }
      if (currentRight >= children?.length) {
        localConatinerRightRef.style.transitionDuration = "0ms";
        setTranslateRightX(
          localConatinerRightRef?.clientHeight * children?.length
        );
      }
    };

    localConatinerRightRef?.addEventListener("transitionend", transitionEnd);

    return () => {
      localConatinerRightRef?.removeEventListener(
        "transitionend",
        transitionEnd
      );
    };
  }, [currentRight, children]);

  //   position first element correctly & this will render only ones
  useLayoutEffect(() => {
    // upward slider
    // setTranslateX(containerRef?.current?.clientHeight * 1);
    // downward slider
    setTranslateRightX(containerRightRef?.current?.clientHeight * 1);
  }, []);

  //   prev, next (upward slider)
  const actionHandler = useCallback(
    (mode) => {
      const slidesLength = children?.length;
      containerRef.current.style.transitionDuration = "1200ms";

      if (mode === "prev") {
        if (current <= 1) {
          setTranslateX(0);
          setCurrent(slidesLength);
        } else {
          setTranslateX(containerRef?.current?.clientHeight * (current - 1));
          setCurrent((prev) => --prev);
        }
      } else if (mode === "next") {
        if (current >= slidesLength) {
          setCurrent(1);
          setTranslateX(
            containerRef?.current?.clientHeight * (slidesLength + 1)
          );
        } else {
          setTranslateX(containerRef?.current?.clientHeight * (current + 1));
          setCurrent((prev) => ++prev);
        }
      }
    },
    [current, children]
  );

  //   prev, next (downward slider)
  const actionRightHandler = useCallback(
    (mode) => {
      const slidesLength = children?.length;
      containerRightRef.current.style.transitionDuration = "1200ms";

      if (mode === "prev") {
        if (currentRight <= 1) {
          setTranslateRightX(0);
          setCurrentRight(slidesLength);
        } else {
          setTranslateRightX(
            containerRightRef?.current?.clientHeight * (currentRight - 1)
          );
          setCurrentRight((prev) => --prev);
        }
      } else if (mode === "next") {
        if (currentRight >= slidesLength) {
          setCurrentRight(1);
          setTranslateRightX(
            containerRef?.current?.clientHeight * (slidesLength + 1)
          );
        } else {
          setTranslateRightX(
            containerRef?.current?.clientHeight * (currentRight + 1)
          );
          setCurrentRight((prev) => ++prev);
        }
      }
    },
    [currentRight, children]
  );

  //   on mouse scroll (upward slider)
  useEffect(() => {
    const localConatinerRef = containerRef?.current;
    const onWheel = (event) => {
      if (event.deltaY === 100) {
        actionHandler("next");
      } else if (event.deltaY === -100) {
        actionHandler("prev");
      }
    };

    localConatinerRef?.addEventListener("wheel", (event) => {
      onWheel(event);
    });

    return () => {
      localConatinerRef?.removeEventListener("wheel", (event) => {
        onWheel(event);
      });
    };
    // eslint-disable-next-line
  }, [current, children]);

  //   on mouse scroll (downward slider)
  useEffect(() => {
    const localRightConatinerRef = containerRightRef?.current;
    const onWheel = (event) => {
      if (event.deltaY === 100) {
        actionRightHandler("next");
      } else if (event.deltaY === -100) {
        actionRightHandler("prev");
      }
    };

    localRightConatinerRef?.addEventListener("wheel", (event) => {
      onWheel(event);
    });

    return () => {
      localRightConatinerRef?.removeEventListener("wheel", (event) => {
        onWheel(event);
      });
    };
    // eslint-disable-next-line
  }, [currentRight, children]);

  //   autoplay && stop on mouse hover
  useEffect(() => {
    if (!isMouseHover) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(() => {
        actionHandler("next");
        actionRightHandler("prev");
      }, 2500);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [actionHandler, actionRightHandler, isMouseHover]);

  const slides = useMemo(() => {
    let elemLength = children?.length;
    if (elemLength > 0) {
      let items = Children.map(children, (child, index) => (
        <li key={index} className="slide">
          {child}
        </li>
      ));

      return [
        <li key={elemLength + 1} className="slide">
          {children[elemLength - 1]}
        </li>,
        ...items,
        <li key={elemLength + 2} className="slide">
          {children[0]}
        </li>,
      ];
    }

    return (
      <li key={elemLength + 2} className="slide">
        {children[0]}
      </li>
    );
  }, [children]);

  const rightSlides = useMemo(() => {
    let elemLength = children?.length;
    if (elemLength > 0) {
      let items = Children.map(children, (child, index) => (
        <li key={index} className="slide">
          {child}
        </li>
      ));

      return [
        <li key={elemLength + 2} className="slide">
          {children[0]}
        </li>,
        ...items.reverse(),
        <li key={elemLength + 1} className="slide">
          {children[elemLength - 1]}
        </li>,
      ];
    }

    return (
      <li key={elemLength + 1} className="slide">
        {children[elemLength - 1]}
      </li>
    );
  }, [children]);

  return (
    <div className="quizs">
      {window.innerWidth < 600 ? (<div className="quiz-contain"
        style={{
          width: "90vw",
          margin: "0 auto",
          // marginTop: "2.5vh",
          marginBottom: "2.5vh",
          paddingTop: '6vh'

        }}>
        <div className="left">
          <HeadingUnderline bold="Blue Nudge " heading="Quiz" align="left" />

          <p style={{
            marginBottom: "1.5rem"
          }}>
            <b>Take part in these short Blue Nudge Quiz</b> - a fun way to
            enhance our knowledge about sustainability
          </p>

          <div className="btn btn-primary" onClick={() => navigate("/quiz")}>
            <Button
              height={window.innerWidth < 991 ? "auto" : "4vmax"}
              title="EXPLORE ALL"
              background={"#f7941d"}
              width={window.innerWidth < 991 ? "fit-content" : "15vmax"}
              padding={window.innerWidth < 991 ? "0.5rem 1rem" : "4%"}
              type="button"
              fontSize={window.innerWidth < 991 ? "calc(var(--base-fz) - 2px)" : "10px"}
              color="#fff"
            />
          </div>
        </div>
      </div>) : null}
      <div className="quiz">
        <div className="wrapper">
          {window.innerWidth < 600 ? null : (<div className="left">
            <HeadingUnderline bold="Blue Nudge " heading="Quiz" align="left" />

            <p>
              <b>Take part in these short Blue Nudge Quiz</b> - a fun way to
              enhance our knowledge about sustainability
            </p>

            <div className="btn btn-primary" onClick={() => navigate("/quiz")}>
              <Button
                height={window.innerWidth < 991 ? "auto" : "4vmax"}
                title="EXPLORE ALL"
                background={"#f7941d"}
                width={window.innerWidth < 991 ? "fit-content" : "15vmax"}
                padding={window.innerWidth < 991 ? "0.5rem 1rem" : "4%"}
                type="button"
                fontSize={window.innerWidth < 991 ? "calc(var(--base-fz) - 2px)" : "10px"}
                color="#fff"
              />
            </div>
            {/* 
          <button className="btn-background" onClick={() => navigate("/quiz")}>
            <img src={ButtonIcon} alt="button" />
            <p>EXPLORE ALL</p>
          </button> */}
          </div>)}
          <div
            onMouseEnter={() => setIsMouseHover(true)}
            onMouseLeave={() => setIsMouseHover(false)}
            className="right"
            ref={containerRef}
            style={{
              transform: `translate3D(0, -${translateX}px, 0)`,
            }}
          >
            {slides}
          </div>

          <div
            onMouseEnter={() => setIsMouseHover(true)}
            onMouseLeave={() => setIsMouseHover(false)}
            className="super-right"
            ref={containerRightRef}
            style={{
              transform: `translate3D(0, -${translateRightX}px, 0)`,
            }}
          >
            {rightSlides}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(QuizCarousel);
