// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy {
}
.privacy .container {
  max-width: 80%;
  margin: 0 auto;
  padding: 2rem 0;
}
.privacy .container p {
  margin: 0.5rem 0;
}
.privacy .container h3 {
  margin: 0.5rem 0;
  margin-top: 1rem;
  color: #024769;
  font-size: 1.4rem;
  font-weight: 550;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Privacy/privacy.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,cAAc;EACd,cAAc;EACd,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".privacy {\n}\n.privacy .container {\n  max-width: 80%;\n  margin: 0 auto;\n  padding: 2rem 0;\n}\n.privacy .container p {\n  margin: 0.5rem 0;\n}\n.privacy .container h3 {\n  margin: 0.5rem 0;\n  margin-top: 1rem;\n  color: #024769;\n  font-size: 1.4rem;\n  font-weight: 550;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
