import React, { useEffect } from "react";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import OfferingAboutUsImg from "Assests/Images/aboutUsimg.png";
import offringBanner from "Assests/Images/Wtwbanner.png";
import Background from "Assests/Images/Offering_background.png";
import Background_2 from "Assests/Images/Offering_background_2.jpg"
import "./Offering.css";
import TeacherIcon from "Assests/Images/Teacher.png";
import StudentIcon from "Assests/Icons/StudentIcon.png";
import WhyThisProgram from "Components/Carousel/MultiCarousel/WhyThisProgramCarousel/WhyThisProgram";
import SustainabilityIcon from "Assests/Icons/SustainabilityIcon.png";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import CertificateCarousel from "Components/Carousel/MultiCarousel/CertificateCarousel/CertificateCarousel";
import SustainableCarousel from "Components/Carousel/MultiCarousel/SustainableCarousel/SustainableCarousel";
import MethodologyCarousel from "Components/Carousel/MultiCarousel/MethodologyCarousel/MethodologyCarousel";
import ParticipatingSchoolCarousel from "Components/Carousel/MultiCarousel/ParticipatingSchoolCarousel/ParticipatingSchoolCarousel";
import { useLocation } from "react-router-dom";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";

const Offering = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="Offering">
      <Header />
      <div className="Offering-1">
        {/* {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="Waste To Wealth"/>)} */}
      </div>
      <HeadingBanner
        image={offringBanner}
        heading="Waste To Wealth -  Green Steps"
        detail="Empowering students through paper waste 
        recycling by focusing on behavioural change and enriching their knowledge with valuable sustainability 
        modules."
      />
      <div
        className="section1"
        style={{
          backgroundImage: `url(${Background_2})`,
        }}
      >
        {/* <img src={image} alt="background-img" className="background-img" /> */}
        <HeadingUnderline bold="The " heading="Journey" />
        <p>
          An exciting culmination of online and experiential learning,designed
          for students from a multitude of backgrounds interested in climate
          change mitigation efforts and those with a desire to make meaningful
          changes in the way they approach and implement Sustainable Practices.
        </p>
        <div className="card_container">
          <div className="card card1">
            <div className="Imgbox">
              <img src={TeacherIcon} alt="teahericon" className="teachericon" />
            </div>
            <div className="card-body">
              <h4>Training of Teachers</h4>
              <p>
                Teacher training workshops enhance environmental knowledge in
                teachers and select Blue Nudge Ambassadors from each class. A
                green nudge by a single teacher can influence the habits of many
                students which further ripples to society.
              </p>
            </div>
          </div>
          <div className="card card2">
            <div className="Imgbox">
              <img src={StudentIcon} alt="teahericon" className="teachericon" />
            </div>
            <div className="card-body">
              <h4>Blue Nudge Ambassadors</h4>
              <p>
                Selected Blue Nudge Ambassadors from each class are responsible
                for the collection of paper waste from respective classmates,
                which is recycled by the Blue Nudge team. The Ambassadors are
                student leaders connecting their class and the Blue Nudge team.
              </p>
            </div>
          </div>
          <div className="card card3">
            <div className="Imgbox">
              <img
                src={SustainabilityIcon}
                alt="teahericon"
                className="teachericon"
              />
            </div>
            <div className="card-body">
              <h4>Sustainability Modules</h4>
              <p>
                Blue Nudge Modules on sustainability are self-paced online
                modules designed for schools. Students are given access to the
                Modules upon collecting the required amount of paper waste.
              </p>
            </div>
          </div>
        </div>
      </div>

      <SustainableCarousel />

      <ParticipatingSchoolCarousel />
      
      <div className="About-us">
        <img
          src={OfferingAboutUsImg}
          alt="OfferingAboutUsImg"
          className="OfferingAboutUsImg"
        />
        <div className="About-us-contaian">
          <HeadingUnderline bold="About " heading="The Program" align="left" />
          <p>
            The Waste to Wealth-Green Steps initiative endeavors to bring
            pro-planet behavioral changes in trailblazers of tomorrow, whose
            every action supports a healthy and thriving planet.
            <br />
            <br />
            The programme resonates with the goal of Mission LiFE- an India led
            campaign announced by Hon’ble Prime Minister of India Shri Narendra
            Modi at UNFCC COP26 - Nudging individual and collective action
            towards ‘Lifestyle for Environment.’
            <br />
            <br />
            It integrates different techniques to enable real-time interactions,
            peer learning, designated student ambassadors, waste segregation at
            source and online learning to reach its goal of creating a
            sustainable future today and tomorrow.
          </p>
        </div>
      </div>
      <MethodologyCarousel />
      <WhyThisProgram />
      <div className="section2">
        {/* <img
          src={CertificateHolder}
          alt="CertificateHolder"
          className="CertificateHolder"
        /> */}
        <CertificateCarousel />
        <div className="section2-content">
          <HeadingUnderline
            bold="Waste To Wealth  "
            heading="Certificates"
            align={window.innerWidth > 600 ? "left" : "center"}
          />
          <p className="section2-contents">
            Each participating school, teacher, Blue Nudge Ambassador and
            student will be rewarded with a “Waste to Wealth-Green Steps”
            Certificate upon completion of the programme requirement. It will be
            a recognition of the effort and knowledge acquired by the student,
            teacher and school during the course activities.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Offering;
