import Carousel from "react-multi-carousel";
import React from "react";
import W2W1 from 'Assests/Images/W2W-BNA New.jpg';
import W2W2 from 'Assests/Images/W2W-TOT New.jpg';
import W2W3 from 'Assests/Images/W2W-COP New.jpg';
import W2W4 from 'Assests/Images/W2W-School Certificate.jpg';
import CertificateBorder from "Assests/Images/CertificateBorder.png";
import "./CertificateCarousel.css";

const CertificateCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="CertificateCarousel">
      <img src={CertificateBorder} alt="holder" className="holder-img" />

      <Carousel
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        transitionDuration={1000}
      >
        <div className="Img-Container">
          <img
            src={W2W1}
            alt="Certificate"
            className="Certificate-Img"
          />
        </div>
        <div className="Img-Container">
          <img
            src={W2W2}
            alt="Certificate"
            className="Certificate-Img"
          />
        </div>
        <div className="Img-Container">
          <img
            src={W2W3}
            alt="Certificate"
            className="Certificate-Img"
          />
        </div>
        <div className="Img-Container">
          <img
            src={W2W4}
            alt="Certificate"
            className="Certificate-Img"
          />
          </div>
      </Carousel>
    </div>
  );
};

export default CertificateCarousel;
