// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.latest-gallery-carousel {
  margin-left: 10vmax;
}

.latest-gallery-carousel .heading-underline {
  margin-bottom: 3vmax;
}

.latest-gallery-carousel li.react-multi-carousel-item:hover .card-item {
  width: 95%;
  transition: all 1000ms;
}

.latest-gallery-carousel .card-item {
  width: 80%;
  height: 23vmax;
  position: relative;
}

.latest-gallery-carousel .card-item:hover p {
  display: block;
}

.latest-gallery-carousel .card-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1vmax;
}

.latest-gallery-carousel .card-item p {
  display: none;
  position: absolute;
  bottom: 0;
  color: white;
  font-weight: 600;
  font-size: 1vmax;
  padding: 1vmax;
  width: 100%;
  /* background-image: linear-gradient(to bottom, transparent, #f7941d); */
  border-bottom-left-radius: 1vmax;
  border-bottom-right-radius: 1vmax;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Carousel/MultiCarousel/LatestGalleryCarousel/LatestGalleryCarousel.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,WAAW;EACX,wEAAwE;EACxE,gCAAgC;EAChC,iCAAiC;AACnC","sourcesContent":[".latest-gallery-carousel {\n  margin-left: 10vmax;\n}\n\n.latest-gallery-carousel .heading-underline {\n  margin-bottom: 3vmax;\n}\n\n.latest-gallery-carousel li.react-multi-carousel-item:hover .card-item {\n  width: 95%;\n  transition: all 1000ms;\n}\n\n.latest-gallery-carousel .card-item {\n  width: 80%;\n  height: 23vmax;\n  position: relative;\n}\n\n.latest-gallery-carousel .card-item:hover p {\n  display: block;\n}\n\n.latest-gallery-carousel .card-item img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  border-radius: 1vmax;\n}\n\n.latest-gallery-carousel .card-item p {\n  display: none;\n  position: absolute;\n  bottom: 0;\n  color: white;\n  font-weight: 600;\n  font-size: 1vmax;\n  padding: 1vmax;\n  width: 100%;\n  /* background-image: linear-gradient(to bottom, transparent, #f7941d); */\n  border-bottom-left-radius: 1vmax;\n  border-bottom-right-radius: 1vmax;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
