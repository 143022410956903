import React from "react";
import "./WhyThisProgram.css";
import Carousel from "react-multi-carousel";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";

const WhyThisProgramCarouseleps = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="WhyThisProgramCarousel">
      <HeadingUnderline bold="Why " heading="This Program?" align="left" />

      <Carousel
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        transitionDuration={1000}
        partialVisbile={true}
      >
        <div className="Cards">
          <h4>01.</h4>
          <p>
          Master the technical and human skills necessary for effective leadership.
          </p>
        </div>
        <div className="Cards">
          <h4>02.</h4>
          <p>
          Learn proactively with our hands-on methodology and real case applications.
          </p>
        </div>
        <div className="Cards">
          <h4>03.</h4>
          <p>
          Explore the latest knowledge through our inter disciplinary modulesship.
          </p>
        </div>
        <div className="Cards">
          <h4>04.</h4>
          <p>
          Build a solid network with from diverse fields and nationalities.
          </p>
        </div>
        <div className="Cards">
          <h4>05.</h4>
          <p>
          Learn and implement on the ground with people and analyse the challenges.
          </p>
        </div>
        <div className="Cards">
          <h4>06.</h4>
          <p>
          Be a part of development webinars and workshops to get into the depths of sustainability and climate change.
          </p>
        </div>
        <div className="Cards">
          <h4>07.</h4>
          <p>
          Become a climate conscious citizen of the society and play your part in building a better world!
          </p>
        </div>
      </Carousel>
    </div>
  );
};

export default WhyThisProgramCarouseleps;
