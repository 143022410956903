// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  display: none;
}

.pagination .pwrapper button {
  padding: 0.3vw 0.5vmax;
  margin: 0 0.5vmax;
  border: unset;
  color: var(--darkblue);
  font-weight: 600;
  border-radius: 5px;
  background: transparent;
}

.pagination .pwrapper button.active {
  background: var(--orange);
  color: white;
}

.paginationTwo {
  margin-top: var(--space-4);
  display: flex;
  gap: var(--space-1);
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
}


.paginationTwo .page-item .page-link {
  background: transparent;
  border: 0;
  height: 30px;
  width: 30px;
  font-weight: 500;
  border-radius: 5px;
}

.paginationTwo .page-item.active .page-link {
  background-color: var(--orange);
  color: var(--white, #fff);
}

.paginationTwo .page-item:not(.active):hover .page-link {
  background-color: var(--orange);
  color: var(--white, #fff);
}`, "",{"version":3,"sources":["webpack://./src/Components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,UAAU;AACZ;;;AAGA;EACE,uBAAuB;EACvB,SAAS;EACT,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;EAC/B,yBAAyB;AAC3B","sourcesContent":[".pagination {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  display: none;\n}\n\n.pagination .pwrapper button {\n  padding: 0.3vw 0.5vmax;\n  margin: 0 0.5vmax;\n  border: unset;\n  color: var(--darkblue);\n  font-weight: 600;\n  border-radius: 5px;\n  background: transparent;\n}\n\n.pagination .pwrapper button.active {\n  background: var(--orange);\n  color: white;\n}\n\n.paginationTwo {\n  margin-top: var(--space-4);\n  display: flex;\n  gap: var(--space-1);\n  align-items: center;\n  justify-content: center;\n  list-style: none;\n  padding: 0;\n}\n\n\n.paginationTwo .page-item .page-link {\n  background: transparent;\n  border: 0;\n  height: 30px;\n  width: 30px;\n  font-weight: 500;\n  border-radius: 5px;\n}\n\n.paginationTwo .page-item.active .page-link {\n  background-color: var(--orange);\n  color: var(--white, #fff);\n}\n\n.paginationTwo .page-item:not(.active):hover .page-link {\n  background-color: var(--orange);\n  color: var(--white, #fff);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
