import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import blog1 from "Assests/Images/blog1.png";
import blog2 from "Assests/Images/blog2.png";
import blog3 from "Assests/Images/blog3.png";
import ButtonIcon from "Assests/Icons/button.svg";
import "../Blogs/Blogs.css";
import calendarIcon from "Assests/Icons/calendarIcon.svg";
import React from "react";
import Button from "Components/Button/Button";
import { useNavigate } from "react-router-dom";
import { fetchBlogList } from "API/get.api";
import { useEffect, useState } from "react";

const Blogs = () => {
  const navigate = useNavigate();
  const id = "1";
  const slug = "beyond-the-classroom-why-social-internships-matter";
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    const apiStatus = fetchBlogList();
    apiStatus
      .then((response) => {
        const recentBlogs = response.data.filter(
          (blog) => blog.category === "recent"
        );
        setBlogs(recentBlogs);
        // toast("fetch sucess full", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="blogs">
      <HeadingUnderline bold="Blogs " />

      <div className="parent">
        {/* {window.innerWidth < 600 ?():(
      blogs.map((blog) => (
          <div className="under" >
            <img src={blog.image} alt="blog" className="Horizontalimg" />
            <p className="icon">
              <img src={calendarIcon} alt="calendar" />
              {blog.date}
            </p>
            <p>
              <b>{blog.title}</b>
             
            </p>
            <button onClick={() => navigate(`/blogs/${blog.id}/${blog.slug}`)}>
              READ MORE
            </button>
          </div>
         */}
        {window.innerWidth < 576 ? (
          // If the screen width is less than 576 pixels, render only the first blog
          <div className="under">
            <img src={blogs[0]?.image} alt="blog" className="Horizontalimg" />
            <p className="icon">
              <img src={calendarIcon} alt="calendar" />
              {blogs[0]?.date}
            </p>
            <p>
              <b>{blogs[0]?.title}</b>
              {/* {blogs[0].content} */}
            </p>
            <button
              onClick={() => navigate(`/blogs/${blogs[0].id}/${blogs[0].slug}`)}
            >
              READ MORE
            </button>
          </div>
        ) : (
          // If the screen width is 576 pixels or more, render the entire list of blogs
          blogs.map((blog) => (
            <div className="under" key={blog.id}>
              <img src={blog.image} alt="blog" className="Horizontalimg" />
              <p className="icon">
                <img src={calendarIcon} alt="calendar" />
                {blog.date}
              </p>
              <p>
                <b>{blog.title}</b>
                {/* {blog.content} */}
              </p>
              <button
                onClick={() => navigate(`/blogs/${blog.id}/${blog.slug}`)}
              >
                READ MORE
              </button>
            </div>
          ))
        )}

        {/* <div className="under">
          <img src={blog1} alt="blog" className="Horizontalimg" />
          <p className="icon">
            <img src={calendarIcon} alt="calendar" />
            10th May 2023
          </p>
          <p>
            <b>Beyond the Classroom: </b>Why Social Internships Matter
          </p>
          <button onClick={() => navigate(`/blogs/${id}/${slug}`)}>
            READ MORE
          </button>
        </div>

        <div className="right">
          <div className="side">
            <img src={blog2} alt="blog" />
            <div className="wrapper">
              <p className="icon">
                <img
                  src={calendarIcon}
                  alt="calendar"
                  className="Horizontalimg"
                />
                10th May 2023
              </p>
              <p>
                <b>Breaking Barriers: </b>Overcoming Obstacles to Behaviour
                Change for Climate Action
              </p>
              <button onClick={() => navigate(`/blogs/${id}/${slug}`)}>
                READ MORE
              </button>
            </div>
          </div>

          <div className="side">
            <img src={blog3} alt="blog" />
            <div className="wrapper">
              <p className="icon">
                <img
                  src={calendarIcon}
                  alt="calendar"
                  className="Horizontalimg"
                />
                16th May 2023
              </p>
              <p>
                <b>Sustainability Starts from Within: </b>The Value of Environ-
                mentally Aware Employees in Corporations
              </p>
              <button onClick={() => navigate(`/blogs/${id}/${slug}`)}>
                READ MORE
              </button>
            </div>
          </div>
        </div> */}
      </div>

      <div
        className="blog-btn-background btn btn-primary"
        onClick={() => navigate("/blogs")}
      >
        <Button
          height={window.innerWidth < 991 ? "auto" : "2.3vw"}
          title="VIEW ALL"
          background={"#f7941d"}
          width={window.innerWidth < 991 ? "fit-cotent" : "12vmax"}
          padding={"0.5rem 1rem"}
          type="button"
          fontSize={window.innerWidth < 991 ? "var(--base-fz)" : "0.7vmax"}
          color="#fff"
        />
      </div>

      {/* <button className="btn-background">
        <img src={ButtonIcon} alt="button" />
        <p>VIEW ALL</p>
      </button> */}
    </div>
  );
};

export default Blogs;
