import React from "react";
import "./WhyThisProgram.css";
import Carousel from "react-multi-carousel";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";

const WhyThisProgramOpFour = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    return (
        <div className="WhyThisProgramCarousel">
            <HeadingUnderline bold="Explore More " heading="about EcoXRclub" align="left" />

            <Carousel
                draggable={false}
                showDots={true}
                responsive={responsive}
                infinite={true}
                arrows={false}
                autoPlay={true}
                autoPlaySpeed={3000}
                transitionDuration={1000}
                partialVisbile={true}
            >
                <div className="Cards">
                    <h4>01.</h4>
                    <p><b>Six Theme-Based Learning Tracks: </b>
                        Dive into climate change, waste management, energy conservation, biodiversity, conscious
                        consumption, and water conservation to gain comprehensive environmental knowledge.</p>
                </div>
                <div className="Cards">
                    <h4>02.</h4>
                    <p><b>Virtual Industry Visits: </b>
                        Experience sustainable practices firsthand with virtual tours of global industries, inspiring
                        environmental action.
                    </p>
                </div>
                <div className="Cards">
                    <h4>03.</h4>
                    <p>
                        <b>Global Webinars: </b>
                        Engage in interactive discussions with sustainability experts and thought leaders to expand
                        knowledge and insight.
                    </p>
                </div>
                <div className="Cards">
                    <h4>04.</h4>
                    <p> <b>Overseas Educational Tours: </b>
                        Embark on international field trips focused on environmental sustainability to broaden horizons
                        and deepen learning.
                    </p>
                </div>
                <div className="Cards">
                    <h4>05.</h4>
                    <p> <b>Eco Lab: </b>
                        Participate in hands-on projects in an eco-friendly laboratory setting to cultivate practical skills in
                        environmental conservation.
                    </p>
                </div>
                
            </Carousel>
        </div>
    );
};

export default WhyThisProgramOpFour;
