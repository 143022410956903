// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileTopBar{
    height:7vh;
    width: 100vw;
    box-shadow: 6px 8px 16px 0px rgba(2, 2, 2, 0.08);
}
.Top-title {
    width: 100vw;
    flex-basis: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    transform: translateX(40%);
    margin-top: 3vmax;
}
.MobileTopBar .overlay-back-btn {
    /* margin-top: 0.6vmax; */
    width: 40px;
    height: 40px;
    /* background: aqua; */
    border: solid 2px;
    transform: translateY(25%);
    margin:auto 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/mobileTopBar/MobileTopBar.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,gDAAgD;AACpD;AACA;IACI,YAAY;IACZ,eAAe;IACf,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,0BAA0B;IAC1B,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,iBAAiB;IACjB,0BAA0B;IAC1B,aAAa;AACjB","sourcesContent":[".MobileTopBar{\n    height:7vh;\n    width: 100vw;\n    box-shadow: 6px 8px 16px 0px rgba(2, 2, 2, 0.08);\n}\n.Top-title {\n    width: 100vw;\n    flex-basis: 90%;\n    margin: 0 auto;\n    display: flex;\n    align-items: center;\n    transform: translateX(40%);\n    margin-top: 3vmax;\n}\n.MobileTopBar .overlay-back-btn {\n    /* margin-top: 0.6vmax; */\n    width: 40px;\n    height: 40px;\n    /* background: aqua; */\n    border: solid 2px;\n    transform: translateY(25%);\n    margin:auto 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
