import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { SIGN_OUT } from "./ActionType";
import { setUserDataReducer } from "./Reducer/set.reducer";

const appReducer = combineReducers({
  setUserDataReducer,
});

const RootReducers = (state, action) => {
  if (action.type === SIGN_OUT) {
    state = undefined;
    storage.removeItem("persist:root");
    localStorage.removeItem("token");
  }

  return appReducer(state, action);
};

export default RootReducers;
