import React from "react";
import "./ClientCarousel.css";
// import Carousel from "react-multi-carousel";
import Unicef from "Assests/Icons/unicef.png";
import Icici from "Assests/Icons/icici.png";
import DelhiResearch from "Assests/Icons/delhiResearch.png";
import Mylan from "Assests/Icons/mylan.png";
import Tata from "Assests/Icons/tata.png";
import Indgo from "../../../../Assests/Images/IndiGo-Logo.png";
import bsg from "Assests/Images/bsg-logo.png";
import National_security_guard_logo from "Assests/Images/National_security_guard_logo.png";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";

const ClientCarousel = () => {
  const dataLogos = [
    {
      id: 1,
      logo: Unicef,
      altText: "Unicef Logo",
    },
    {
      id: 2,
      logo: Icici,
      altText: "ICICI Logo",
    },
    {
      id: 3,
      logo: DelhiResearch,
      altText: "Delhi Research Implementation and Innovation",
    },
    {
      id: 4,
      logo: Mylan,
      altText: "Mylan Logo",
    },
    {
      id: 5,
      logo: Tata,
      altText: "Tata Motors Logo",
    },
    {
      id: 6,
      logo: Indgo,
      altText: "Indigo Logo",
    },
    {
      id: 7,
      logo: bsg,
      altText: "Bharat Soka Gakkai",
    },
    {
      id: 8,
      logo: National_security_guard_logo,
      altText: "National Security Guard Logo",
    },
  ];

  return (
    <div className="client-carousel">
      <HeadingUnderline bold="Our " heading="Clients" />

      <div className="client-logo-container-main">
        <div className="client-logo-container">
          {dataLogos.map((item) => (
            <img
              key={item.id}
              src={item.logo}
              alt={item.altText}
              className="clientimg"
            />
          ))}
        </div>

        <div className="client-logo-container">
          {dataLogos.map((item) => (
            <img
              key={item.id}
              src={item.logo}
              alt={item.altText}
              className="clientimg"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientCarousel;
