import React from "react";
import Carousel from "react-multi-carousel";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import ComprehensiveStudyMaterials from "Assests/Images/icons/offerings-4/Why-choose-us/Comprehensive-study-materials2.png"
import ActivitiesAssessments from "Assests/Images/icons/offerings-4/Why-choose-us/activities-and-assessments2.png"
import DiverseCareerOpportunities from "Assests/Images/icons/offerings-4/Why-choose-us/diverse-career-opportunities2.png"
import GlobalConnectivity from "Assests/Images/icons/offerings-4/Why-choose-us/global-connectivity2.png"
import GradeAlignedCurriculum from "Assests/Images/icons/offerings-4/Why-choose-us/grade-aligned-curriculum.png"
import InnovativeLearningExperiences from "Assests/Images/icons/offerings-4/Why-choose-us/innovative-learning-experiences2.png"
import InstructorLedPrograms from "Assests/Images/icons/offerings-4/Why-choose-us/instructor-led-programs2.png"
import PersonalDevelopmentLeadership from "Assests/Images/icons/offerings-4/Why-choose-us/personal-development-and-leadership2.png"

const WhyChooseEcoXRclub = () => {
    const responsive = {
        desktoplG: {
            breakpoint: { max: 3000, min: 992 },
            items: 6,
            slidesToSlide: 1, // optional, default to 1.
        },
        desktop: {
            breakpoint: { max: 991, min: 768 },
            items: 4,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 767, min: 465 },
            items: 2,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    return (
        <div className="MethodologyCarousel">
            <HeadingUnderline bold="Why Choose" heading=" EcoXRclub?" />

            <Carousel
                draggable={false}
                showDots={true}
                responsive={responsive}
                infinite={true}
                arrows={false}
                autoPlay={true}
                autoPlaySpeed={3000}
                transitionDuration={1000}
            >
                <div className="card">
                    <div className="imgcontainer">
                        <img src={InnovativeLearningExperiences} alt="Innovative Learning Experiences" />
                    </div>
                    <h4>
                        Innovative Learning Experiences
                    </h4>
                </div>
                <div className="card">
                    <div className="imgcontainer">
                        <img src={DiverseCareerOpportunities} alt="Diverse Career Opportunities" />
                    </div>
                    <h4>
                        Diverse Career Opportunities
                    </h4>
                </div>
                <div className="card">
                    <div className="imgcontainer">
                        <img src={GlobalConnectivity} alt="Global Connectivity" />
                    </div>
                    <h4>
                        Global Connectivity
                    </h4>
                </div>
                <div className="card">
                    <div className="imgcontainer">
                        <img src={PersonalDevelopmentLeadership} alt="Personal Development and Leadership" />
                    </div>
                    <h4>
                        Personal Development and Leadership
                    </h4>
                </div>
                <div className="card">
                    <div className="imgcontainer">
                        <img src={GradeAlignedCurriculum} alt="Grade-aligned Curriculum" />
                    </div>
                    <h4>
                        Grade-aligned Curriculum
                    </h4>
                </div>
                <div className="card">
                    <div className="imgcontainer">
                        <img src={InstructorLedPrograms} alt="Instructor-led Programs" />
                    </div>
                    <h4>
                        Instructor-led Programs
                    </h4>
                </div>
                <div className="card">
                    <div className="imgcontainer">
                        <img src={ComprehensiveStudyMaterials} alt="Comprehensive Study Materials" />
                    </div>
                    <h4>
                        Comprehensive Study Materials
                    </h4>
                </div>
                <div className="card">
                    <div className="imgcontainer">
                        <img src={ActivitiesAssessments} alt="Activities and Assessments" />
                    </div>
                    <h4>
                        Activities and Assessments
                    </h4>
                </div>
            </Carousel>
        </div>
    );
};

export default WhyChooseEcoXRclub;
