import React from "react";
// import rainbowGradient from "Assests/Icons/rainbowGradient.png";
import rainbowBorder from "Assests/Icons/rainbowBorder.svg";
import arrow from "Assests/Images/BlogArrow.png"

const BlogButton = ({
  align = "center",
  width,
  height,
  type="button",
  rainbowHeight = "3px",
  color = "white"
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        borderRadius: "5px",
        cursor: "pointer",
        width: width,
      }}
    >
      <button
        type={type}
        style={{
          //   alignItems: `${align}`,
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          // padding: "4%",
          background: "#f7941d",
          textAlign: align,
          height: height,
          width: width,
          fontWeight: 600,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          border:"none",
          color:color
        //   letterSpacing: "1.4px",
        }}
      >
        {/* <img
        style={{ width: width/2, objectFit: "cover", height: height/2    }}
        src={arrow}
        alt="rainbowGradient" */}
      {/* /> */}
       <p>&#8599; </p>
      </button>
      <img
        style={{ width: "100%", objectFit: "cover", height: rainbowHeight }}
        src={rainbowBorder}
        alt="rainbowGradient"
      />
    </div>
  );
};

export default BlogButton;
