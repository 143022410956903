import React from "react";
import "./WhyThisProgram.css";
import Carousel from "react-multi-carousel";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";

const WhyThisProgramCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="WhyThisProgramCarousel">
      <HeadingUnderline bold="Why " heading="This Program?" align="left" />

      <Carousel
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        transitionDuration={1000}
        partialVisbile={true}
      >
        <div className="Cards">
          <h4>01.</h4>
          <p>
          Integrates environmental education into the school curriculum.
          </p>
        </div>
        <div className="Cards">
          <h4>02.</h4>
          <p>
          Real-time workshops and training for teachers and students.
          </p>
        </div>
        <div className="Cards">
          <h4>03.</h4>
          <p>
          Nudges participants to see waste as value.
          </p>
        </div>
        <div className="Cards">
          <h4>04.</h4>
          <p>
          Appointment of Blue Nudge Ambassadors instills a sense of leadership and responsibility among the students.
          </p>
        </div>
        <div className="Cards">
          <h4>05.</h4>
          <p>
          Encourages waste segregation at source.
          </p>
        </div>
        <div className="Cards">
          <h4>06.</h4>
          <p>
          Student access to interdisciplinary sustainability modules with the latest statistics and models.
          </p>
        </div>
        <div className="Cards">
          <h4>07.</h4>
          <p>
          Creates climate-conscious citizens and agents of change.
          </p>
        </div>
        <div className="Cards">
          <h4>08.</h4>
          <p>
          Programme Certification for school, teachers and students.
          </p>
        </div>
      </Carousel>
    </div>
  );
};

export default WhyThisProgramCarousel;
