import React from "react";
import "./ParticipatingSchoolCarousel.css";
import Carousel from "react-multi-carousel";
import SchoolIcon1 from "Assests/Icons/SchoolIcon1.png";
import SchoolIcon2 from "Assests/Icons/SchoolIcon2.png";
import SchoolIcon3 from "Assests/Icons/SchoolIcon3.png";
import SchoolIcon4 from "Assests/Icons/SchoolIcon4.png";
import SchoolIcon5 from "Assests/Icons/SchoolIcon5.png";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";

const ParticipatingSchoolCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="School-carousel">
      <HeadingUnderline bold="Participating " heading="Schools" />

      <Carousel
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        transitionDuration={1000}
        partialVisbile={true}
      >
        <div className="school-carousel-logo">
          <img src={SchoolIcon1} alt="SchoolIcon1" />
        </div>
        <div className="school-carousel-logo">
          <img src={SchoolIcon2} alt="SchoolIcon2" />
        </div>
        <div className="school-carousel-logo">
          <img src={SchoolIcon3} alt="SchoolIcon3" />
        </div>
        <div className="school-carousel-logo">
          <img src={SchoolIcon4} alt="SchoolIcon4" />
        </div>
        <div className="school-carousel-logo">
          <img src={SchoolIcon5} alt="SchoolIcon5" />
        </div>
      </Carousel>
    </div>
  );
};

export default ParticipatingSchoolCarousel;
