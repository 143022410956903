import React, { useEffect } from "react";
import "./Partners.css";
import partnersBanner from "Assests/Images/partnersBanner.png";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import PartnerCarousel from "Components/Carousel/3DCarousel/PartnerCarousel/PartnerCarousel";
import Footer from "Components/Footer/Footer";
import { useLocation } from "react-router-dom";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";

const Partners = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="about-us partners">
      {/* <Header /> */}
      {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="Partners"/>)}
      <HeadingBanner
        image={partnersBanner}
        heading="Partners"
        detail="Fostering Sustainability Through Collaboration:
        Our Sustainable Partnerships, Planet’s Guardians."
      />

      <div className="top-industry">
        <HeadingUnderline
          bold="Top Industry "
          heading="Partners"
          align="left"
        />

        <p>
        At Blue Nudge, we believe in the power of collaboration to drive sustainable 
        innovation within our industry. Through strategic partnerships with leading 
        organizations, we aim to create a future where environmental stewardship and 
        business excellence coexist harmoniously. By joining forces with industry pioneers, 
        we share knowledge, expertise, and resources, fostering a collaborative environment 
        that fuels groundbreaking solutions.<br/><br /> Together, we embark on a journey to revolutionize 
        our sector, integrating sustainable practices, reducing carbon footprints, 
        and championing eco-conscious initiatives. Our industry partnerships exemplify 
        our commitment to forging a greener, more responsible future, shaping an inclusive 
        landscape where every player 
        contributes to a world that thrives economically, socially, and environmentally.
        </p>
      </div>

      <PartnerCarousel />

      <Footer />
    </div>
  );
};

export default Partners;
