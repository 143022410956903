import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import React, { useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import rightChevron from "Assests/Icons/rightChevron.svg";
import giftBoxMan from "Assests/Icons/giftBoxMan.png";
import journeyImage from "Assests/Images/ourjoubanner.png";
import "./OurJourney.css";
import { useEffect } from "react";
import { getJourneyStory } from "API/get.api";
import moment from "moment";
import { useLocation } from "react-router-dom";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";
import noData from "Assests/Images/no-data.webp";

const OurJourney = () => {
  const [isAutoplay, setisAutoplay] = useState(true);
  const [date, setDate] = useState("");
  const [journeyData, setJourneyData] = useState([]);
  const carouselRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1023, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const handleClick = (uid) => {
    // console.log("uiidd", uid)
    carouselRef.current.goToSlide(uid);

    // setDate("");
  };

  const handleChange = (e) => {
    // let month = e.target.value?.split(" ")[0];
    // let year = e.target.value?.split(" ")[1];
    setDate(e.target.value);
    const month = moment(new Date(e.target.value)).format("MMMM").toLowerCase();
    const year = moment(new Date(e.target.value)).format("YYYY");
    const apiStatus = getJourneyStory(
      month === "invalid date" ? "" : month,
      year === "Invalid date" ? "" : year
    );
    apiStatus
      .then((response) => {
        // console.log(response)
        if (response.data.length === 0) {
          setJourneyData([]);
        } else {
          const apiStatus = getJourneyStory("", "");
          apiStatus
            .then((response) => {
              const sortedRes = response?.data?.sort((a, b) => a.id - b.id);
              // console.log(sortedRes)
              setJourneyData(sortedRes);
              // console.log(response.data)
            })
            .catch((err) => {
              console.log(err);
            });

          setTimeout(() => {
            carouselRef.current.goToSlide(response.data[0]?.id - 1);
          }, [1000]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SliderCard = ({ month, year, image, title, content, uid }) => {
    return (
      <>
        <div className={`slider-card`}>
          <div onClick={() => handleClick(uid)} className="text-content">
            <h6>{month || ""}</h6>
            <h2>{year || ""}</h2>
          </div>
          <div className="line-with-dot">
            <div className="dot"></div>
          </div>
        </div>

        <div className="event-occured">
          <div className="event-occured-img">
            <img
              src={image}
              alt="event"
              onMouseEnter={() => setisAutoplay(false)}
              onMouseLeave={() => setisAutoplay(true)}
            />
          </div>
          <HeadingUnderline bold={title} />
          <p className="desc">{content}</p>
        </div>
      </>
    );
  };

  useEffect(() => {
    const apiStatus = getJourneyStory("", "");
    apiStatus
      .then((response) => {
        const sortedRes = response?.data?.sort((a, b) => a.id - b.id);
        // console.log(sortedRes)
        setJourneyData(sortedRes);
        // console.log(response.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // for fetching the late rendered slider elements for the first time
  // useEffect(() => {
  //   const interval = setTimeout(() => {
  //     if (
  //       document.querySelectorAll(
  //         ".react-multi-carousel-item.react-multi-carousel-item--active"
  //       )[2]
  //     ) {
  //       document
  //         .querySelectorAll(
  //           ".react-multi-carousel-item.react-multi-carousel-item--active"
  //         )[2]
  //         .classList?.add("center-element");
  //     }
  //   }, 0);

  //   return () => clearInterval(interval);
  // }, []);

  console.log(journeyData)

  return (
    <div className="our-journey">
      <Header />

      <HeadingBanner
        image={journeyImage}
        heading="Our Journey"
        detail="At Blue Nudge, our journey is a tapestry of transformative moments. From our inception, we have 
        championed the cause of environmental consciousness."
      />

      <div className="section-holder">
        <div className="section">
          <div className="head-section">
            <div>
              <HeadingUnderline bold="Our " heading="Story" align="left" />
              <p className="desc">
                We started small, but our dedication knew no bounds. Year after
                year, we have reached new milestones, nudging students across
                schools and universities toward sustainable living. Today, our
                impact resonates in the hearts of countless individuals. In less
                than a decade, we have become synonymous with environmental
                awareness, inspiring change, one nudge at a time"
              </p>
            </div>

            {/* <div className="input-section">
              <input
                type="month"
                placeholder="Select Year"
                value={date}
                name="month"
                id="month"
                onBlur={() => {
                  document.getElementById("month").type = "month";
                }}
                onFocus={() => {
                  document.getElementById("month").type = "month";
                }}
                onChange={handleChange}
              />
            </div> */}
          </div>

          <div className="body-section">
            {journeyData?.length === 0 ? (
              <div className="no-data-found">
                <img src={noData} alt="" className="img-fluid" width="80" />
                <h3 className="title">No Data Found</h3>
              </div>
            ) : (
              <>
                <Carousel
                  ref={carouselRef}
                  autoPlay={false}
                  pauseOnHover={true}
                  draggable={false}
                  responsive={responsive}
                  infinite={true}
                  arrows={true}
                  autoPlaySpeed={2500}
                  transitionDuration={1000}
                  showDots={true}
                  beforeChange={() => {
                    if (
                      document.querySelectorAll(
                        ".react-multi-carousel-item.react-multi-carousel-item--active"
                      )[2]
                    ) {
                      document
                        .querySelectorAll(
                          ".react-multi-carousel-item.react-multi-carousel-item--active"
                        )[2]
                        .classList.remove("center-element");
                    }
                  }}
                  afterChange={(previousSlide, { currentSlide, onMove }) => {
                    const interval = setTimeout(() => {
                      if (
                        document.querySelectorAll(
                          ".react-multi-carousel-item.react-multi-carousel-item--active"
                        )[2]
                      ) {
                        document
                          .querySelectorAll(
                            ".react-multi-carousel-item.react-multi-carousel-item--active"
                          )[2]
                          .classList.add("center-element");
                      }
                    }, 0);

                    return () => clearInterval(interval);
                  }}
                >
                  {journeyData && journeyData.length > 0
                    ? journeyData.map((item, idx) => (
                        <SliderCard
                          key={idx}
                          month={item.month}
                          year={item.year}
                          image={item.image}
                          title={item.title}
                          content={item.content}
                          uid={item.id - 1}
                        />
                      ))
                    : ""}
                </Carousel>
              </>
            )}

            <img src={giftBoxMan} alt="gift" className="overlay-image" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OurJourney;
