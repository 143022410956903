import React, { useState, useRef } from "react";
import "./Signin.css";
// import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import SigininWanner from "Assests/Images/signin-Wanner.png";
import loginlogo from "Assests/Icons/mainLogo.svg";
// import ButtonIcon from "Assests/Icons/button.svg";
import facebook from "Assests/Icons/facebook.svg";
import linkedIn from "Assests/Icons/linkedIn.svg";
import twitter from "Assests/Icons/twitter.svg";
import instagram from "Assests/Icons/instagram.svg";
import { register, login } from "API/post.api";
import { useDispatch } from "react-redux";
import { setUserDataAction } from "Redux/Actions/set.action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import Button from "Components/Button/Button";
import BackButton from "Components/BackBtn/BackButton";
import { validators } from "./Validator";

const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef(null);
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("active");
  const [signup, setSignup] = useState(query);
  const [signData, setSignData] = useState({});
  const [formData, setFormData] = useState({});

  const handleSignChange = (e) => {
    setSignData({ ...signData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!signData.email) {
      toast("Please Enter Email or Phone", { type: "error" });
      return;
    }
    if (!signData.password) {
      toast("Please Enter Password", { type: "error" });
      return;
    }
    const apiStatus = login(signData);
    apiStatus
      .then((response) => {
        dispatch(setUserDataAction(response?.data?.user));
        localStorage.setItem("token", response?.data?.key);
        toast("Logged In Successful", { type: "success" });
        navigate("/home");
      })
      .catch((err) => {
        console.log(err);
        toast(err?.response?.data?.detail, { type: "error" });
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!validators(formData)) {
      return;
    }
    const apiStatus = register(formData);
    apiStatus
      .then((response) => {
        setFormData({});
        formRef.current.reset();
        setSignup(false);
        toast("Signed Up Successful", { type: "success" });
        // setTimeout(() => {
        //   window.location.reload()
        // }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="sign-in">
      <div className="sign-in-wrapper">
        <BackButton />
        <img src={SigininWanner} alt="contactinner" className="banner-img" />
        <div className="content">
          <div className="left-section">
            <img src={loginlogo} alt="loginlogo" />
            <h1>Gentle Nudges Towards</h1>
            <h1 style={{ color: " #f7941d" }}>Sustainable Behaviour</h1>
          </div>
          <div className="right-section">
            {!signup ? (
              <form onSubmit={handleSubmit}>
                <h1>Sign In</h1>
                <div className="inputWrapper">
                  <p>Email or Phone Number</p>
                  <input
                    type="text"
                    name="email"
                    onChange={(e) => handleSignChange(e)}
                    placeholder="Enter Email or Phone No."
                  />
                </div>
                <div className="inputWrapper">
                  <p>Password</p>
                  <input
                    type="password"
                    name="password"
                    onChange={(e) => handleSignChange(e)}
                    placeholder="Enter Password"
                  />
                </div>
                <div className="btn-wrapper">
                  <div onClick={handleSubmit}>
                    <Button
                      height={window.innerWidth < 991 ? "auto" : "2.5vw"}
                      title="SIGN IN"
                      background={"#f7941d"}
                      width={"100%"}
                      padding={
                        window.innerWidth < 991 ? "0.7rem 1.4rem" : "3.5%"
                      }
                      type="submit"
                      fontSize={
                        window.innerWidth < 991 ? "var(--base-fz)" : ".8vmax"
                      }
                    />
                  </div>
                  {/* <button type="submit" className="btn-background">
                    <img src={ButtonIcon} alt="button" />
                    SIGN IN
                  </button> */}
                  <p className="open-account">
                    Don’t have an account?{" "}
                    <span
                      onClick={() => setSignup(true)}
                      style={{
                        color: " #f7941d",
                        cursor: "pointer",
                        fontSize: "1vmax",
                        fontWeight: "600",
                      }}
                    >
                      SIGN UP
                    </span>
                  </p>
                  <p className="lms-click">
                    To login to the LMS{" "}
                    <a
                      className="lms-click"
                      style={{ textDecoration: "none" }}
                      href="https://lms.blue-nudge.com/login"
                      target="blank"
                    >
                      {" "}
                      <b
                        style={{
                          color: " #f7941d",
                          cursor: "pointer",
                          fontSize: "1vmax",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        click here
                      </b>
                    </a>
                  </p>
                </div>
              </form>
            ) : (
              <form ref={formRef} onSubmit={handleSignUpSubmit}>
                <h1>Sign Up</h1>
                <div className="inputWrapper">
                  <p>Full Name</p>
                  <input
                    name="fullname"
                    type="text"
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Your Full Name"
                  />
                </div>
                <div className="inputWrapper">
                  <p>Email</p>
                  <input
                    name="email"
                    type="email"
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Email"
                  />
                </div>
                <div className="inputWrapper">
                  <p>Phone Number</p>
                  <input
                    name="phone"
                    type="text"
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Phone Number"
                  />
                </div>
                <div className="inputWrapper">
                  <p>City</p>
                  <input
                    name="city"
                    type="text"
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter City"
                  />
                </div>
                <div className="inputWrapper">
                  <p>Select User Type</p>
                  <select name="user_type" onChange={(e) => handleChange(e)}>
                    <option hidden value={""}>
                      Select
                    </option>
                    <option value={"admin"}>Admin</option>
                    <option value={"learners"}>Learners</option>
                  </select>
                </div>
                <div className="inputWrapper">
                  <p>Password</p>
                  <input
                    name="password"
                    type="password"
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Password"
                  />
                </div>
                <div className="inputWrapper">
                  <p>Confirm Password</p>
                  <input
                    name="cPassword"
                    type="password"
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Confirm Password"
                  />
                </div>
                <div className="btn-wrapper">
                  <div onClick={handleSignUpSubmit}>
                    <Button
                      height={"2.5vw"}
                      title="SIGN UP"
                      background={"#f7941d"}
                      width={"100%"}
                      padding={"3.5%"}
                      type="submit"
                      fontSize=".8vmax"
                    />
                  </div>
                  {/* <button type="submit" className="btn-background">
                    <img src={ButtonIcon} alt="button" />
                    SIGN UP
                  </button> */}
                  <p className="open-account">
                    Already have an account?{" "}
                    <span
                      onClick={() => setSignup(false)}
                      style={{
                        color: " #f7941d",
                        cursor: "pointer",
                        fontSize: "1vmax",
                        fontWeight: "600",
                      }}
                    >
                      SIGN IN
                    </span>
                  </p>
                </div>
              </form>
            )}
          </div>
        </div>
        <div className="banner-social">
          <a
            href="https://www.facebook.com/bluenudge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://twitter.com/NudgeBlue"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} alt="twitter" />
          </a>
          <a
            href="https://www.linkedin.com/company/blue-nudge/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedIn} alt="linkedIn" />
          </a>
          <a
            href="https://www.instagram.com/bluenudge/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Signin;
