import React, { useState, useEffect, useMemo } from "react";
import BannerSlider from "Components/Carousel/VerticalCarousel/BannerSlider";
import "./Home.css";
import Header from "Components/Header/Header";
import OfferingCarousel from "Components/Carousel/MultiCarousel/OfferingCarousel/OfferingCarousel";
import InformationCarousel from "Components/Carousel/MultiCarousel/InformationCarousel/InformationCarousel";
import AboutCarousel from "Components/Carousel/MultiCarousel/AboutCarousel/AboutCarousel";
import ClientCarousel from "Components/Carousel/MultiCarousel/ClientCarousel/ClientCarousel";
import Quiz from "Components/Carousel/VerticalCarousel/QuizCarousel";
import ParticipatingCarousel from "Components/Carousel/MultiCarousel/ParticipatingCarousel/ParticipatingCarousel";
import Blogs from "Components/Blogs/Blogs";
import GalleryCarousel from "Components/Carousel/MultiCarousel/GalleryCarousel/GalleryCarousel";
import Footer from "Components/Footer/Footer";
import newwanner1 from "Assests/Images/Group-1-_1_.webp";
import newwanner2 from "Assests/Images/Group-767-_1_.webp";
import newwanner3 from "Assests/Images/headerimg3-1.jpg";
import quizImg1 from "Assests/Images/quizImg1.png";
import quizImg2 from "Assests/Images/quizImg2.png";
import quizImg3 from "Assests/Images/quizImg3.png";
import quizImg4 from "Assests/Images/quizImg4.png";
import QuizPopUp from "Components/PopUp/Quiz/QuizPopUp";
import { fetchQuizList, fetchAllQuestionQuiz } from "API/get.api";
import { toast } from "react-toastify";
import Button from "Components/Button/Button";
import TestimonialCarousal2 from "Components/Carousel/3DCarousel/TestimonialCarousel/TestimonialCarousal2";
import tabBanner1 from "Assests/Images/banner/banner-md-1.jpg";
import tabBanner2 from "Assests/Images/banner/banner-md-2.jpg";
import tabBanner3 from "Assests/Images/banner/banner-md-3.jpg";
import BannerEcoXRDesktop from "Assests/Images/banner/banner-eco-xs-desktop-2.jpg"
import BannerEcoXRMobile from "Assests/Images/banner/banner-eco-xs-mobile-2.png"
// import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [model, setModel] = useState(false);
  const [quizList, setQuizList] = useState([]);
  const [question, setQuestion] = useState([]);
  const [activeQ, setActiveQ] = useState(null);
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  let updatedQuiz = [];

  // quiz updated item

  for (let i = 0; i < quizList?.length; i += 2) {
    if (quizList[i] && quizList[i + 1]) {
      updatedQuiz.push([quizList[i], quizList[i + 1]]);
    } else if (quizList[i]) {
      updatedQuiz.push([quizList[i], quizList[0]]);
    }
  }

  useEffect(() => {
    const apiStatus = fetchQuizList();
    apiStatus
      .then((response) => {
        setQuizList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const quizModelOpen = (index, item) => {
    if (!token) {
      // toast("Sign In to Take Quiz", { type: "warning" });
      navigate("/signin")
      return;
    }
    setActiveQ(index);
    const apiStatus = fetchAllQuestionQuiz(item.id, token);
    apiStatus
      .then((response) => {
        setQuestion(response.data);
        if (response.data.length > 0) {
          setModel(true);
        } else {
          toast("You Already Attempt Questions", { type: "error" });
        }
        // toast("Logged In Successful", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
        toast(err?.response?.data?.detail, { type: "error" });
      });
  };

  const QuizCard = ({ image, item, backgroundColor, index }) => {
    return (
      <div className="quiz-card" style={{ backgroundImage: backgroundColor }}>
        <img src={item.picture || image} alt="quiz" />
        <h6>{item?.quiz_title || "No Title"}</h6>
        <p>{item?.description || "No Title"}</p>

        <div
          className="btn btn-primary"
          style={{ marginTop: "1rem" }}
          onClick={() => quizModelOpen(index, item)}
        >
          <Button
            height={window.innerWidth < 991 ? "auto" : "2vw"}
            title="TAKE QUIZ"
            background={"white"}
            width={window.innerWidth < 991 ? "fit-content" : "7vmax"}
            padding={window.innerWidth < 991 ? "0.7rem 1.4rem" : "3.5%"}
            type="button"
            fontSize={
              window.innerWidth < 991 ? "calc(var(--base-fz) - 4px)" : ".8vmax"
            }
            color="#024769"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="home-page">
      <Header />

      <BannerSlider>

        <div>
          <div
            className="overlay-text"
            style={{
              // bottom: "15vmax",
              top: "90%",
            }}
          ></div>

          <picture>
            <source media="(min-width:991px)" srcSet={BannerEcoXRDesktop} />
            <img src={BannerEcoXRMobile} alt="" className="w-100" />
          </picture>
        </div>

        {/* <div>
          <div className="overlay-text">
            <p>Introducing</p>
            <p>
              <b>Blue Nudge</b>
            </p>
          </div>

          <picture>
            <source media="(min-width:991px)" srcSet={newwanner1} />
            <img src={tabBanner1} alt="" className="w-100" />
          </picture>
        </div> */}

        <div>
          <div className="overlay-text">
            <p>Nudging Individuals To Build</p>
            <p>
              <b
                style={{
                  width: "70%",
                  display: "flex",
                }}
              >
                Climate Conscious Communities
              </b>
            </p>
          </div>

          <picture>
            <source media="(min-width:991px)" srcSet={newwanner2} />
            <img src={tabBanner2} alt="" className="w-100" />
          </picture>
        </div>

        <div>
          <div
            className="overlay-text"
            style={{
              // bottom: "15vmax",
              top: "90%",
            }}
          ></div>

          <picture>
            <source media="(min-width:991px)" srcSet={newwanner3} />
            <img src={tabBanner3} alt="" className="w-100" />
          </picture>
        </div>


      </BannerSlider>

      <InformationCarousel />


      <AboutCarousel />

      <ClientCarousel />

      <OfferingCarousel />

      <Quiz>
        {updatedQuiz?.length > 0 &&
          updatedQuiz?.map((item, idx) => (
            <div key={idx} className="wrapper">
              {item[0] && (
                <QuizCard
                  image={(idx + 1) % 2 === 0 ? quizImg3 : quizImg1}
                  item={item[0]}
                  index={idx * 2}
                  backgroundColor={
                    (idx + 1) % 2 === 0
                      ? "linear-gradient(rgb(255, 188, 217), rgb(236, 12, 110))"
                      : "linear-gradient(179deg, rgb(182, 255, 128) 0%, rgb(132, 185, 92) 111%)"
                  }
                />
              )}

              {item[1] && (
                <QuizCard
                  image={(idx + 1) % 2 === 0 ? quizImg4 : quizImg2}
                  item={item[1]}
                  index={idx * 2}
                  backgroundColor={
                    (idx + 1) % 2 === 0
                      ? "linear-gradient(rgb(255, 229, 105) -9%, rgb(255, 117, 24) 129%)"
                      : "linear-gradient(rgb(255, 242, 180) 40%, rgb(255, 212, 2) 130%)"
                  }
                />
              )}
            </div>
          ))}
      </Quiz>

      <ParticipatingCarousel />

      <GalleryCarousel />

      <Blogs />

      <TestimonialCarousal2 />

      <Footer />
      {model && (
        <QuizPopUp
          model={model}
          setModel={setModel}
          question={question}
          setQuestion={setQuestion}
          quizList={quizList}
          activeQ={activeQ}
          setActiveQ={setActiveQ}
        />
      )}
    </div>
  );
};

export default Home;
