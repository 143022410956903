import React from "react";
import roundSliderImage1 from "Assests/Images/roundSlideImage1.png";
import roundSliderImage2 from "Assests/Images/roundSliderImage2.png";
import roundSliderImage3 from "Assests/Images/roundSliderImage3.png";
import "./PartnerCarousel.css";

const PartnerCarousel = () => {
  const arr = [
    {
      img: roundSliderImage1,
      title: "ALBA Group",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    },
    {
      img: roundSliderImage2,
      title: "ALBA Group",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    },
    {
      img: roundSliderImage3,
      title: "ALBA Group",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    },
    {
      img: roundSliderImage1,
      title: "ALBA Group",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    },
    {
      img: roundSliderImage2,
      title: "ALBA Group",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    },
    {
      img: roundSliderImage3,
      title: "ALBA Group",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    },
    {
      img: roundSliderImage1,
      title: "ALBA Group",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    },
    {
      img: roundSliderImage2,
      title: "ALBA Group",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    },
  ];
  // const cellCount = arr?.length;
  // const wrapperHeight = cellCount * 100;
  // const parentWidth = 360 - 5 * cellCount;
  // const childWidth = parentWidth - 40;
  // const childHeight = 380 - 10 * cellCount;

  const cellCount = arr?.length;
  const wrapperHeight = cellCount * 120;
  const parentWidth = 660 - 5 * cellCount;
  const childWidth = parentWidth - 40;
  const childHeight = 520 - 10 * cellCount;

  const translateZCalculated = Math.round(
    parentWidth / 2 / Math.tan(Math.PI / cellCount)
  );

  return (
    <div className="partner-carousel" style={{ height: wrapperHeight/19 + "vmax" }}>
      <div className="scene" style={{ width:`${parentWidth/19}vmax` , height: 0 }}>
        <div
          className="carousel"
          style={{
            transform: `rotateY(0deg) translateZ(-${translateZCalculated}px)`,
          }}
        >
          {arr && cellCount > 0
            ? arr.map((curr, idx) => {
                return (
                  <div
                    key={idx}
                    className="carousel__cell"
                    style={{
                      width: `${childWidth/19}vmax`,
                      height: `${childHeight/19}vmax`,
                      transform: `rotateY(${
                        (360 / cellCount) * idx
                      }deg) translateZ(${translateZCalculated/19}vmax)`,
                    }}
                  >
                    <img src={curr?.img} alt="sliderImg" />
                    <div className="wrapper">
                      <h6>{curr?.title}</h6>
                      {/* <p>{curr?.desc}</p> */}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default PartnerCarousel;
