import React from "react";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import BlogBanner from "Assests/Images/BlogBanner.png";
// import HorizontalBlog from "Components/Blogs/Blogs";
import PopularBlog from "Components/Carousel/MultiCarousel/Popularblog/PopularBlog";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
// import calendarIcon from "Assests/Icons/calendarIcon.svg";
import dateFormat from "dateformat";
import Button from "Components/Button/BlogButton";
import "./Blog.css";
import { fetchBlogList } from "API/get.api";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "Components/Pagination/Pagination";
import NoImg from "Assests/Images/NoImg.png";
import BlogDetail from "Pages/Blogs/BlogContains";
import RecentBlog from "Components/Carousel/VerticalCarousel/RecentBlog";
import BlogTitle from "Components/BlogTitle/BlogTitle";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";


const Blogs = () => {
  const [BlogList, setBlogList] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleBlogItemClick = (blog) => {
    const { id, slug } = blog;
    setSelectedBlog(blog);
    navigate(`/blogs/${id}/${slug}`);
  };

  useEffect(() => {
    const apiStatus = fetchBlogList();
    apiStatus
      .then((response) => {
        const pasrBlogs = response.data.filter((blog) => blog.category === "past");
        // console.log(pasrBlogs);
        setBlogList(pasrBlogs);
        // toast("fetch sucess full", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log(BlogList)

  // const items = ['Item 1', 'Item 2', 'Item 3'];
  return (
    <div className="Blogs">
      <Header />
      {/* {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="Blogs"/>)} */}
      <HeadingBanner
        image={BlogBanner}
        heading="Blogs"
        detail="Dive into our Blogs: Illuminating Sustainability, Waste Management, Environmental Awareness, and Innovations - Nourishing Minds, Inspiring Actions, and Cultivating a Greener Tomorrow."
      />
      <div className="rectenBlog">
        <RecentBlog />


      </div>
      <PopularBlog />
      <div className="blog-container">
        <HeadingUnderline bold="Past " heading="Blogs" align="left" />
        <div className="Blog-List">
          {BlogList.map((blog, index) => (
            <>

              <div className="Blog-item card--blog" key={index}>

                <div className="card--blog--upper">
                  <div className="card--upper--img">
                    <img
                      src={blog?.image || NoImg} // Assuming there's an 'image' property in the API response
                      alt="popularblog"
                      className="Popularblog__card__image"
                    />
                  </div>
                  <div className="blogbutton"
                    onClick={() => {
                      handleBlogItemClick(blog);
                      <BlogDetail bloG={blog} />;
                    }}
                  >
                    <Button
                      height={"3vmax"}
                      background={"#f7941d"}
                      width={"3vmax"}
                      type="button"
                      color="#fff"
                    />
                  </div>
                </div>
                <div className="card--blog--bottom">
                  <BlogTitle Title={blog.title} Date={blog.created_at} />
                </div>
              </div>
            </>
          ))}
        </div>
        {/* <Pagination /> */}
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
