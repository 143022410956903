import { SIGN_OUT,USER_DATA } from "Redux/ActionType";

export const signOutAction = () => {
  return {
    type: SIGN_OUT,
  };
};


export const setUserDataAction = (data) => {
  return {
    type: USER_DATA,
    payload: data,
  };
};
