import React, { useEffect, useState } from "react";
import GalleryCarousel from "Components/Carousel/3DCarousel/GalleryCarousel/newGalleryCarousel";
import LatestGalleryCarousel from "Components/Carousel/MultiCarousel/LatestGalleryCarousel/LatestGalleryCarousel";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import Pagination from "Components/Pagination/Pagination";
import pastCar13 from "Assests/Images/Past/IMG-20221122-WA0003.jpg";
import pastCar14 from "Assests/Images/Past/IMG-20230406-WA0001.jpg";
import pastCar17 from "Assests/Images/Past/IMG-20230406-WA0025.jpg";
import pastCar19 from "Assests/Images/Past/IMG-20230411-WA0003.jpg";
import pastCar9 from "Assests/Images/Past/IMG-20230411-WA0033.jpg";
import pastCar20 from "Assests/Images/Past/IMG-20230426-WA0025.jpg";
import pastCar7 from "Assests/Images/Past/IMG-20230512-WA0037.jpg";
import pastCar8 from "Assests/Images/Past/IMG-20230519-WA0022.jpg";
import pastCar5 from "Assests/Images/Past/IMG-20230523-WA0005.jpg";
import pastCar10 from "Assests/Images/Past/IMG-20230605-WA0000.jpg";
import pastCar11 from "Assests/Images/Past/IMG-20230607-WA0087.jpg";
import pastCar12 from "Assests/Images/Past/IMG-20230630-WA0003.jpg";
import pastCar1 from "Assests/Images/Past/IMG-20230630-WA0007.jpg";
import pastCar2 from "Assests/Images/Past/IMG-20230712-WA0020.jpg";
import pastCar15 from "Assests/Images/Past/IMG-20230726-WA0013.jpg";
import pastCar16 from "Assests/Images/Past/IMG-20230814-WA0006.jpg";
import pastCar3 from "Assests/Images/Past/IMG-20230921-WA0014.jpg";
import pastCar18 from "Assests/Images/Past/IMG-20231003-WA0013.jpg";
import pastCar4 from "Assests/Images/Past/IMG-20231003-WA0025.jpg";
import pastCar6 from "Assests/Images/Past/IMG-20231020-WA0004.jpg";
import pastCar21 from "Assests/Images/Past/IMG-20231020-WA0015.jpg";
import pastCar22 from "Assests/Images/Past/IMG_5525.jpg";
import pastCar23 from "Assests/Images/Past/IMG_5530.jpg";
import pastCar24 from "Assests/Images/Past/WhatsApp Image 2023-10-28 at 12.13.56 AM.jpeg";
import pastCar25 from "Assests/Images/Past/WhatsApp Image 2023-10-28 at 12.13.57 AM (1).jpeg";
import pastCar26 from "Assests/Images/Past/WhatsApp Image 2023-10-28 at 12.13.57 AM.jpeg";
import pastCar27 from "Assests/Images/Past/WhatsApp Image 2023-10-28 at 12.13.58 AM (1).jpeg";
import pastCar28 from "Assests/Images/Past/WhatsApp Image 2023-10-28 at 12.13.58 AM.jpeg";
import pastCar29 from "Assests/Images/Past/WhatsApp Image 2023-10-28 at 12.13.59 AM.jpeg";
import pastCar30 from "Assests/Images/Past/WhatsApp Image 2023-10-28 at 12.14.00 AM (1).jpeg";
import pastCar31 from "Assests/Images/Past/WhatsApp Image 2023-10-28 at 12.14.00 AM.jpeg";
import pastCar32 from "Assests/Images/Past/WhatsApp Image 2023-10-28 at 12.14.01 AM.jpeg";
import pastCar33 from "Assests/Images/Past/WhatsApp Image 2023-10-28 at 12.17.23 AM.jpeg";
import pastCar34 from "Assests/Images/Past/pastimg1.325f78bbfd7cd3e116a6.jpg";
import pastCar35 from "Assests/Images/Past/pastimg11.0ec42b67b6168d00b6d4.jpg";
import pastCar36 from "Assests/Images/Past/pastimg2.6e1d0491d374a90dec84.jpg";
import pastCar37 from "Assests/Images/Past/pastimg3.4eb89fea3653c74975b7.jpg";
import pastCar38 from "Assests/Images/Past/pastimg4.811c007ddc0fc766c6c5.jpg";
import pastCar39 from "Assests/Images/Past/pastimg8.b61005821c94f4748aa8.jpg";
import facebook from "Assests/Icons/facebook.svg";
import linkedIn from "Assests/Icons/linkedIn.svg";
import twitter from "Assests/Icons/twitter.svg";
import instagram from "Assests/Icons/instagram.svg";
import "./Gallery.css";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";
import { useLocation } from "react-router-dom";

const pastImages = [
  pastCar1,
  pastCar2,
  pastCar3,
  pastCar4,
  pastCar5,
  pastCar6,
  pastCar7,
  pastCar8,
  pastCar9,
  pastCar10,
  pastCar11,
  pastCar12,
  pastCar13,
  pastCar14,
  pastCar15,
  pastCar16,
  pastCar17,
  pastCar18,
  pastCar19,
  pastCar20,
  pastCar21,
  pastCar22,
  pastCar23,
  pastCar24,
  pastCar25,
  pastCar26,
  pastCar27,
  pastCar28,
  pastCar29,
  pastCar30,
  pastCar31,
  pastCar32,
  pastCar33,
  pastCar34,
  pastCar35,
  pastCar36,
  pastCar37,
  pastCar38,
  pastCar39,
];

const Gallery = () => {
  const { pathname } = useLocation();

  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (image) => {
    setSelectedImage(image);
    setCurrentIndex(pastImages.indexOf(image));
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  const goToNext = () => {
    const newIndex = (currentIndex + 1) % pastImages.length;
    setSelectedImage(pastImages[newIndex]);
    setCurrentIndex(newIndex);
  };

  const goToPrevious = () => {
    const newIndex = (currentIndex - 1 + pastImages.length) % pastImages.length;
    setSelectedImage(pastImages[newIndex]);
    setCurrentIndex(newIndex);
  };

  const prevIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      // style={{ transform: 'rotate(180deg)' }}
    >
      <path d="M12 18l-6-6 6-6" stroke="#fff" />
    </svg>
  );

  const nextIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      style={{ transform: "rotate(180deg)" }}
    >
      <path d="M12 18l-6-6 6-6" stroke="#fff" />
    </svg>
  );

  // Pagernation

  const itemsPerPage = 12; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = pastImages.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(pastImages.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function section1() {
    return (
      <div className="wrapper">
        <HeadingUnderline bold="Gallery" />

        <p className="para">
          Dive into a World of Sustainability: Explore Our Green Gallery,
          Featuring Inspiring Images of Environmental Awareness and Student
          Passion. Witness the Impact of Our School and University Programs,
          Where Tomorrow’s Leaders Embrace Sustainability, One Project at a
          Time. Capturing the Essence of Passionate Learning, Fostering
          Eco-conscious Minds for a Greener Tomorrow.
        </p>

        <GalleryCarousel />

        <div className="banner-social">
          <a
            href="https://www.facebook.com/bluenudge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://twitter.com/NudgeBlue"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} alt="twitter" />
          </a>
          <a
            href="https://www.linkedin.com/company/blue-nudge/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedIn} alt="linkedIn" />
          </a>
          <a
            href="https://www.instagram.com/bluenudge/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
        </div>
      </div>
    );
  }

  function section2() {
    return (
      <>
        <LatestGalleryCarousel />
      </>
    );
  }

  function section3() {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = pastImages.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <div className="past">
        <HeadingUnderline bold="Past" />

        <div className="wrapper">
          {currentItems.map((image, index) => (
            <div key={index} onClick={() => openModal(image)}>
              <img src={image} alt={`past-${index}`} />
              {/* You can add details or any other elements for each image */}
            </div>
          ))}
        </div>
        <div className="pagination">
          <div className="pwrapper">
            <button
              style={{ display: "inline-block" }}
              onClick={() => {
                if (currentPage > 1) {
                  paginate(currentPage - 1);
                }
              }}
            >
              Prev
            </button>
            {currentPage > 1 ? <span>...</span> : null}
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                // onClick={() => paginate(index + 1)}  // since now we are using prev and next button
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
            {currentPage < totalPages ? <span>...</span> : null}
            <button
              style={{ display: "inline-block" }}
              onClick={() => {
                if (currentPage < totalPages) {
                  paginate(currentPage + 1);
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
        {selectedImage && (
          <div
            className="modal"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
              padding: "1vmax 0",
              zIndex: 9999,
            }}
          >
            <span className="close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                style={{
                  position: "absolute",
                  top: "2vmax",
                  right: "2vmax",
                  cursor: "pointer",
                }}
                onClick={closeModal}
              >
                <path d="M24 8L8 24M8 8l16 16" stroke="#fff" />
              </svg>
            </span>
            <button
              className="prev-btn"
              onClick={goToPrevious}
              style={{
                border: "none",
                height: "10vmax",
                width: "10vmax",
                backgroundColor: "transparent",
              }}
            >
              {prevIcon}
            </button>

            <img
              src={selectedImage}
              alt="Full Screen"
              style={{ height: "80vh", width: "80vw", objectFit: "contain" }}
            />

            <button
              className="next-btn"
              onClick={goToNext}
              style={{
                border: "none",
                height: "10vmax",
                width: "10vmax",
                backgroundColor: "transparent",
              }}
            >
              {nextIcon}
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="gallery-page">
      <div className="header-holder">
        <Header />
        {/* {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="Gallary"/>)} */}
      </div>

      <div className="section-1">{section1()}</div>

      <div className="section-2">{section2()}</div>

      <div className="section-3">{section3()}</div>

      <Footer />
    </div>
  );
};

export default Gallery;
