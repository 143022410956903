import React from "react";
import "./MissionVision.css";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import aboutBanner from "Assests/Images/visionMisionbanner.png";
// import eye from "Assests/Images/Vision.gif";
import visionIcon from "Assests/Images/vision-new.gif";
import objectiveIcon from "Assests/Images/target-new.gif";
import rightArrow from "Assests/Icons/rightArrow.svg";
// import target from "Assests/Images/Target.gif";
import targetBackground from "Assests/Icons/targetBackground.svg";
import progressBar from "Assests/Icons/progressBar.svg";
import { useInView } from "react-intersection-observer";
import TestimonialCarousel2 from "Components/Carousel/3DCarousel/TestimonialCarousel/TestimonialCarousal2";
import Footer from "Components/Footer/Footer";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";

const MissionVision = () => {
  const [ref, inView] = useInView({ threshold: 0.5, triggerOnce: true });

  return (
    <div className="about-us mission-vision">
      <Header />
      {/* {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="Mission Vision"/>)} */}
      <HeadingBanner
        image={aboutBanner}
        heading="Vision & Mission"
        detail="Driving Change, Sustaining Futures: Our Mission,
        Planet’s Vision."
      />

      <div className="mission-vision-display" ref={ref}>
        <div className="left">
          <img
            src={visionIcon}
            alt="eye"
            style={{ opacity: 0 }}
            className={`gif-img ${inView ? "small" : null}`}
          />

          <div
            className={inView ? "heading-underline" : null}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: `left`,
              opacity: 0,
            }}
          >
            <p
              style={{
                color: "var(--darkblue)",
               
                margin: "unset",
                lineHeight: "unset",
                fontWeight: "500",
              }}
            >
              <b className="heading">Vision</b>
            </p>
            <p
              style={{
                width: "50px",
                height: "4px",
                backgroundColor: "var(--orange)",
                borderRadius: "5px",
              }}
            ></p>
          </div>

          <p style={{ opacity: 0 }} className={inView ? "para" : null}>
          Our vision is a world where youth lead the charge towards a
            sustainable future, where every action supports a healthy and
            thriving planet.
          </p>

          <img
            src={progressBar}
            alt="bar"
            style={{ opacity: 0 }}
            className={inView ? "bar" : null}
          />
        </div>

        <img
          src={rightArrow}
          alt="arrow"
          style={{ opacity: 0 }}
          className={inView ? "arrow" : null}
        />

        <div className="right">
          <img
            src={objectiveIcon}
            alt="target"
            style={{ opacity: 0 }}
            className={`gif-img-2 ${inView ? "small" : null}`}
          />

          <div
            className={inView ? "heading-underline" : null}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: `left`,
              opacity: 0,
            }}
          >
            <p
              style={{
                color: "var(--darkblue)",
                
                margin: "unset",
                lineHeight: "unset",
                fontWeight: "500",
              }}
            >
              <b className="heading">Mission</b>
            </p>
            <p
              style={{
                width: "50px",
                height: "4px",
                backgroundColor: "var(--orange)",
                borderRadius: "5px",
              }}
            ></p>
          </div>

          <p style={{ opacity: 0 }} className={inView ? "para" : null}>
          Our mission is to lead a global movement of pro-planet advocates by 
          inspiring and empowering <b>50 million individuals by 2030</b> to take 
          measurable actions towards sustainability, and by collaborating with 
          like-minded organizations and communities to create a more just and 
          sustainable world for future generations.
            <img src={targetBackground} alt="target" />
          </p>

          <img
            src={progressBar}
            alt="bar"
            style={{ opacity: 0 }}
            className={inView ? "bar" : null}
          />
        </div>
      </div>

      <TestimonialCarousel2 />

      <Footer />
    </div>
  );
};

export default MissionVision;
