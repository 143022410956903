import React from "react";
import BackButton from "Components/BackBtn/BackButton";
import "Components/mobileTopBar/MobileTopBar.css"
import BackButton1 from "Assests/Icons/Back-buttton.svg";

const MobileTopBar = ({Heading}) => {
    return(
    <div className="MobileTopBar" 
        style={{
            backgroundColor: "#fff",
            display: "flex"
        }}
    >
        <div className="topbar">
            {/* <img src={BackButton1} alt="backbutton" className="backbutton" /> */}
          <BackButton />

        <div className="Top-title">
           <h4>{Heading}</h4> 
        </div>   
        </div>
    </div>
    );
};
export default MobileTopBar;