import React, {
  Children,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import facebook from "Assests/Icons/facebook.svg";
import linkedIn from "Assests/Icons/linkedIn.svg";
import twitter from "Assests/Icons/twitter-X-white.svg";
import instagram from "Assests/Icons/instagram.svg";

const BannerSlider = ({ children }) => {
  const containerRef = useRef(null);
  const intervalRef = useRef(null);
  const [current, setCurrent] = useState(1);
  const [translateX, setTranslateX] = useState(0);
  const [isMouseHover, setisMouseHover] = useState(false);

  //   for infinite scrolling
  useEffect(() => {
    const localConatinerRef = containerRef?.current;
    const transitionEnd = () => {
      if (current <= 1) {
        localConatinerRef.style.transitionDuration = "0ms";
        setTranslateX(localConatinerRef?.clientHeight * current);
      }

      if (current >= children?.length) {
        localConatinerRef.style.transitionDuration = "0ms";
        setTranslateX(localConatinerRef?.clientHeight * children?.length);
      }
    };

    localConatinerRef?.addEventListener("transitionend", transitionEnd);

    return () => {
      localConatinerRef?.removeEventListener("transitionend", transitionEnd);
    };
  }, [current, children]);

  //   position first element correctly & this will render only ones
  useLayoutEffect(() => {
    setTranslateX(containerRef?.current?.clientHeight * 1);
  }, []);

  //   prev, next
  const actionHandler = useCallback(
    (mode) => {
      const slidesLength = children?.length;
      containerRef.current.style.transitionDuration = "3000ms";

      if (mode === "prev") {
        if (current <= 1) {
          setTranslateX(0);
          setCurrent(slidesLength);
        } else {
          setTranslateX(containerRef?.current?.clientHeight * (current - 1));
          setCurrent((prev) => --prev);
        }
      } else if (mode === "next") {
        if (current >= slidesLength) {
          setCurrent(1);
          setTranslateX(
            containerRef?.current?.clientHeight * (slidesLength + 1)
          );
        } else {
          setTranslateX(containerRef?.current?.clientHeight * (current + 1));
          setCurrent((prev) => ++prev);
        }
      }
    },
    [current, children]
  );

  //   on mouse scroll
  useEffect(() => {
    const localConatinerRef = containerRef?.current;
    const onWheel = (event) => {
      if (event.deltaY === 100) {
        actionHandler("next");
      } else if (event.deltaY === -100) {
        actionHandler("prev");
      }
    };

    localConatinerRef?.addEventListener("wheel", (event) => {
      onWheel(event);
    });

    return () => {
      localConatinerRef?.removeEventListener("wheel", (event) => {
        onWheel(event);
      });
    };
    // eslint-disable-next-line
  }, [current, children]);

  //   autoplay && stop on mouse hover
  useEffect(() => {
    if (!isMouseHover) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(() => {
        actionHandler("next");
      }, 3000);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [actionHandler, isMouseHover]);

  const slides = useMemo(() => {
    let elemLength = children?.length;
    if (elemLength > 0) {
      let items = Children.map(children, (child, index) => (
        <li key={index} className="slide">
          {child}
        </li>
      ));

      return [
        <li key={elemLength + 1} className="slide">
          {children[elemLength - 1]}
        </li>,
        ...items,
        <li key={elemLength + 2} className="slide">
          {children[0]}
        </li>,
      ];
    }

    return (
      <li key={elemLength + 2} className="slide">
        {children[0]}
      </li>
    );
  }, [children]);

  return (
    <>
      <div className="banner-root">
        <div
          className="banner-slider"
          ref={containerRef}
          style={{
            transform: `translate3D(0, -${translateX}px, 0)`,
          }}
        // onMouseEnter={() => {
        //   setisMouseHover(true);
        // }}
        // onMouseLeave={() => {
        //   setisMouseHover(false);
        // }}
        >
          {slides}
        </div>
        <div className="banner-social">
          <a
            href="https://www.facebook.com/bluenudge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://twitter.com/NudgeBlue"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} alt="twitter" />
          </a>
          <a
            href="https://www.linkedin.com/company/blue-nudge/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedIn} alt="linkedIn" />
          </a>
          <a
            href="https://www.instagram.com/bluenudge/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
        </div>

        <div className="banner-scroll">
          <div className="dot-wrapper">
            <div className="dot"></div>
          </div>
          <div className="para">SCROLL</div>
        </div>
      </div>
    </>
  );
};

export default BannerSlider;
