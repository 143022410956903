import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import ButtonIcon from "Assests/Icons/button.svg";
import "./OfferingCarousel.css";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import pastCar7 from "Assests/Images/msrbackgroundforhome.jpg";
import pastCar8 from "Assests/Images/evsbackgroudforhome.jpg";
import pastCar9 from "Assests/Images/w2wbackgrondforhome.jpg";
import { useNavigate } from "react-router-dom";
import Button from "Components/Button/Button";

const OfferingCarousel = () => {
  const navigate = useNavigate();
  // const arr = [{}, {}, {}, {}, {}, {}];
  // const cellCount = arr?.length;
  // const wrapperHeight = cellCount * 120;
  // const parentWidth = 860 - 5 * cellCount;
  // const childWidth = parentWidth - 40;
  // const childHeight = 520 - 10 * cellCount;

  // const translateZCalculated = Math.round(
  //   parentWidth / 2 / Math.tan(Math.PI / cellCount)
  // );

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items:1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const CardItem = ({ backgroundImg, title, index, content }) => {
    return (
      <div className="card-item" style={{ backgroundImage: backgroundImg }}>
        <div className="upper">
          <h6>{title}</h6>
          <div className="linebreak"></div>
        </div>
        <div className="contain">
        {content}
        </div>
        {/* <p>
          The program aims to provide holistic experiential education related to
          sustainability and climate change. Participating in this program will
          serve as a springboard for student’s academic and professional growth.
          The interactive and problem-solving exposure will open doors for
          various national and international opportunities and deepen student’s
          understanding of sustainability and climate change.
        </p>

        <p>
          MSR covers a range of topics related to sustainability, including the
          impact of climate change, the importance of community engagement, and
          the need for behavioral change among individuals. Through a
          combination of lectures, discussions, and hands-on activities, the
          program helps learners gain a deeper understanding of these topics and
          develop the skills they need to make a positive impact on the
          environment.
        </p>

        <p>
          Although the program is not time bound, it is designed to be completed
          in 3 - 6 months, and is suitable for a wide range of learners,
          including students, professionals, and community members.
        </p> */}

        <div className="bottom btn btn-primary"
          onClick={() => navigate(`/offering/page${index}`)}
        >
          <Button
            height={"auto"}
            title="KNOW MORE"
            background={"#f7941d"}
            width={"fit-content"}
            padding={"0.5rem 1rem"}
            type="button"
            color="#fff"
          />
        </div>

        {/* <button
          className="btn-background"
          onClick={() => navigate("/offering/page1")}
        >
          <img src={ButtonIcon} alt="button" />
          <p>KNOW MORE</p>
        </button> */}
      </div>
    );
  };

  return (
    <div className="offering-carousel">
      <HeadingUnderline bold="Our " heading="Offerings" />

      <p>
        Discover a Greener World with Blue Nudge! Our Initiatives: 'My Social
        Responsibility,' 'Environmental Studies Program,' and 'Waste to Wealth -
        Green Steps' empower students in schools and universities. Dive into the
        realm of sustainability, where learning meets action! Join us in nudging
        a sustainable future, one behaviour at a time. Let us make every step
        count!
      </p>

      {/* <div
        className="offering-carousel-parent"
        style={{ height: wrapperHeight + "px" }}
      >
        <div
          className="offering-carousel-scene"
          style={{ width: parentWidth, height: 0 }}
        >
          <div
            className="offering-carousel-section"
            style={{
              transform: `rotateY(0deg) translateZ(-${translateZCalculated}px)`,
            }}
          >
            {arr && cellCount > 0
              ? arr.map((curr, idx) => {
                  return (
                    <div
                      key={idx}
                      className="carousel__cell"
                      style={{
                        width: childWidth,
                        height: childHeight,
                        transform: `rotateY(${
                          (360 / cellCount) * idx
                        }deg) translateZ(${translateZCalculated}px)`,
                      }}
                    >
                      <CardItem />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div> */}

      <Carousel
        draggable={false}
        centerMode={window.innerWidth < 600 ? false : true}
        pauseOnHover={true}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        partialVisbile={false}
        autoPlaySpeed={1500}
        transitionDuration={2000}
        showDots={true}
        dotListClass="dots-style-1"
        className="offering-carousel-section"
      >
        <CardItem
          backgroundImg={`linear-gradient(to bottom, rgba(255, 212, 2, 0.85), rgba(2, 71, 105, 0.85) 147%),url(${pastCar9})`}
          title="Waste To Wealth - Green Steps"
          index={1}
          content={
            <div>
              <p>
                The Waste to Wealth-Green Steps initiative endeavors to bring
                pro-planet behavioral changes in trailblazers of tomorrow, whose
                every action supports a healthy and thriving planet.
              </p>
              <p>
                The programme resonates with the goal of Mission LiFE- an India
                led campaign announced by Hon’ble Prime Minister of India Shri
                Narendra Modi at UNFCC COP26 - Nudging individual and collective
                action towards ‘Lifestyle for Environment.’
              </p>
              <p>
                It integrates different techniques to enable real-time
                interactions, peer learning, designated student ambassadors,
                waste segregation at source and online learning to reach its
                goal of creating a sustainable future today and tomorrow.
              </p>
            </div>
          }
        />
        <CardItem
          backgroundImg={`linear-gradient(
            to bottom,
            rgba(236, 12, 110, 0.85),
            rgba(2, 71, 105, 0.85) 147%
          ),url(${pastCar7})`}
          title="My Social Responsibility (MSR)"
          index={2}
          content={
            <div>
              <p>
                The program aims to provide holistic experiential education
                related to sustainability and climate change. Participating in
                this program will serve as a springboard for student’s academic
                and professional growth. </p>
                <p>The interactive and problem-solving
                exposure will open doors for various national and international
                opportunities and deepen student’s understanding of
                sustainability and climate change.
              </p>
              <p>
                MSR covers a range of topics related to sustainability,
                including the impact of climate change, the importance of
                community engagement, and the need for behavioural change among
                individuals. Through a combination of lectures, discussions, and
                hands-on activities, the program helps learners gain a deeper
                understanding of these topics and develop the skills they need
                to make a positive impact on the environment.
              </p>
              <p>
                Although the program is time bound, it is designed to be
                completed in 1 - 2 months, and is suitable for a wide range of
                learners, including students, professionals, and community
                members.
              </p>
            </div>
          }
          // content={
          //   <div>
          //     <p>
          //       My Social Responsibility is an activity-centric, hybrid
          //       community engagement program for students who are committed to
          //       the common good, feel responsible for the future, and are
          //       passionate about building a sustainable world. It is a paid
          //       program that aims at encouraging behavioral change and
          //       instilling a sense of accountability amongst individuals,
          //       towards their planet.
          //     </p>
          //     <p>
          //       Participating in this program will serve as a springboard for
          //       students’ academic and professional growth. The holistic,
          //       interactive, and problem-solving exposure will open doors for
          //       various national and international opportunities and deepen
          //       students’ understanding of sustainability and climate change.
          //     </p>
          //     <p>
          //       By the end of the program, students will be equipped with a set
          //       of essential skills to help them stand out during their
          //       undergraduate application process and later to enter the
          //       professional world. The program is structured to have a
          //       substantial impact on the young minds to pursue sustainability,
          //       as a way of living.
          //     </p>
          //   </div>
          // }
        />
        <CardItem
          backgroundImg={`linear-gradient(
            to bottom,
            rgba(247, 148, 29, 0.85),
            rgba(2, 71, 105, 0.85) 185%
          ),
    url(${pastCar8})`}
          title="Environmental Studies Program"
          index={3}
          content={
            <div>
              <p>
                Blue Nudge’s Environmental Studies and Behaviour Change program
                is an activity-centric community engagement program for students
                who feel responsible for the future and are passionate about
                building a sustainable world. </p>
                <p>The program consists of online
                learning modules and community engagement activities by focusing
                on different Sustainable Development Goals (SDGs).
              </p>
              <p>
                The modules cover nine topics that provide them with a holistic
                idea of sustainability and the activities provide an on-ground
                exposure to students. During their community engagement hours,
                students also extensively participate in a many activities.
              </p>
            </div>
          }
          // content={
          //   <div>
          //     <p>
          //       The program consists of online modules and community engagement
          //       activities covering issues and information on sustainability and
          //       climate change. It aims at empowering the youth of this country
          //       with the right knowledge and skills to be an active enthusiast
          //       in the fight against climate change.
          //     </p>
          //     <p>
          //       Participating in this program will also serve as a springboard
          //       for student’s academic and professional growth. The holistic,
          //       interactive and problem-solving exposure will open doors for
          //       various national and international opportunities and deepen
          //       student’s understanding of sustainability and climate change.
          //     </p>
          //     <p>
          //       By the end of the program, students will be equipped with a set
          //       of essential skills to help them stand out during their
          //       undergraduate application process and later to enter the
          //       professional world.
          //     </p>
          //   </div>
          // }
        />
      </Carousel>
    </div>
  );
};

export default OfferingCarousel;
