import React from "react";

const HeadingUnderline = ({ bold, heading, align = "center" }) => {
  return (
    <div
      className="heading-underline"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: `${align}`,
        gap: "5px",
        marginBottom: "5px",
      }}
    >
      <p 
      className="heading"
        style={{
          color: "var(--darkblue)",
          fontSize: window.innerWidth>991? "1.8rem" : "1.3rem",
          margin: "unset",
          lineHeight: "unset",
          fontWeight: "500",
        }}
      >
        <b style={{  fontSize: window.innerWidth>991?"1.8rem":"1.3rem" }}>{bold}</b>
        {heading}
      </p>
      <p
        style={{
          width: "50px",
          height: "3px",
          backgroundColor: "var(--orange)",
          borderRadius: "5px",
        }}
      ></p>
    </div>
  );
};

export default HeadingUnderline;
