import React from "react";
import footerBackground from "Assests/Icons/footerBackground.png";
import footerFacebook from "Assests/Icons/footerFacebook.svg";
import footerTwitter from "Assests/Icons/twitter-X.svg";
import footerLinkedIn from "Assests/Icons/footerLinkedIn.svg";
import footerInstagram from "Assests/Icons/footerInstagram.svg";
import mainLogo from "Assests/Icons/mainLogo.svg";
import locationIcon from "Assests/Icons/locationIcon.svg";
import mailIcon from "Assests/Icons/mailIcon.svg";
import phoneIcon from "Assests/Icons/phoneIcon.svg";
import calendarBlueIcon from "Assests/Icons/calendarBlueIcon.svg";
import clockIcon from "Assests/Icons/clockIcon.svg";
import mobileOutlet from "Assests/Icons/mobileOutlet.png";
import "./Footer.css";
import { ProfileSmaller } from "./TwitterProfile";
import { useNavigate } from "react-router-dom";
import { memo } from "react";

const Footer = () => {
  const navigate = useNavigate();
  const d = new Date();
  let year = d.getFullYear();

  return (
    <div className="footer">
      <img src={footerBackground} alt="footer" className="gradient" />

      <div className="wrapper">
        <div className="left">
          <div className="upper">
            <div className="first">
              <img src={mainLogo} alt="logo" />
              <p>
                Blue Nudge is an Education to Action (EdAct) platform that
                focuses on community engagement and behavioral change to address
                environmental challenges. We aim at instilling a sense of
                responsibility in students towards their planet and environment
                and encourage them to take up sustainability as a way of living.
              </p>
            </div>

            <div className="second">
              <h6>COMPANY</h6>
              <p className="underline"></p>
              <ul>
                <li onClick={() => navigate("/about/us")}>About Us </li>
                <li onClick={() => navigate("/offering/page1")}>Offerings </li>
                {/* <li onClick={() => navigate("/about/partners")}>Partners </li> */}
                <li onClick={() => navigate("/our-journey")}>Our Journey</li>
                <li onClick={() => navigate("/gallery")}>Gallery </li>
                <li onClick={() => navigate("/blogs")}>Blogs</li>
                <li onClick={() => navigate("/privacy-policy")}>
                  Privacy Policy
                </li>
              </ul>
            </div>

            <div className="third">
              <h6>CONTACT US</h6>
              <p className="underline"></p>
              <ul>
                <li>
                  <img src={locationIcon} alt="location" />
                  Suite, 5D, Aria Signature Tower JW Marriott, New Delhi-110037
                </li>

                <li>
                  <a href="tel:011-40059100" style={{ color: "white" }}>
                    <img src={phoneIcon} alt="phone" />
                    011-40059100
                  </a>
                </li>
                <li>
                  <a href="mailto:011-40059100" style={{ color: "white" }}>
                    <img src={mailIcon} alt="mail" />
                    info@blue-nudge.com
                  </a>
                </li>
                <li>
                  <img src={calendarBlueIcon} alt="calendar" />
                  Monday — Saturday
                </li>
                <li>
                  <img src={clockIcon} alt="clock" />
                  09:30 AM — 06:30 PM
                </li>
              </ul>
            </div>
          </div>
          <div className="bottom">
            <p>© {year || ""} Blue Nudge. All Rights Reserved.</p>
            <div className="footer-social-links">
              <a
                href="https://www.facebook.com/bluenudge"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={footerFacebook} alt="facebook" />
              </a>
              <a
                href="https://twitter.com/NudgeBlue"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={footerTwitter} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/blue-nudge/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={footerLinkedIn} alt="linkedIn" />
              </a>
              <a
                href="https://www.instagram.com/bluenudge/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={footerInstagram} alt="instagram" />
              </a>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="outlet">
            <img src={mobileOutlet} alt="outlet" />
            <div className="profile-smaller">
              <div className="whitespace"></div>
              {/* <ProfileSmaller /> */}
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbluenudge&tabs=timeline&width=255&height=490&small_header=true&adapt_container_width=false&hide_cover=false&show_facepile=false&appId"
                
                width={window.innerWidth>1350 ? 208: '100%' }
                height="490"
                style={{
                  border: "none",
                  overflow: "hidden"
                }}
                title="Facebook Widget"
                // scrolling="no"
                frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);
