import React from "react";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import HeadingBanner from "Components/HeadingBanner/HeadingBanner";
import OfferingAboutUsImg from "Assests/Images/OfferingAboutUs.png";
import GolobImg from "Assests/Images/Golob.png";
import offringBanner from "Assests/Images/msrbanner.png";
import CertificateCarousel from "Components/Carousel/MultiCarousel/CertificateCarousel/CertificateCarouselmrs";
import "./Offering.css";
import "./Offeringpage2.css";
import { useState,useEffect } from "react";
import YellowBook from "Assests/Icons/YellowBookIcon.png";
import BlueIcon from "Assests/Icons/BlueStepIcon.png";
import RedIcon from "Assests/Icons/RedStepIcon.png";
import HeadingUnderline from "Components/HeadingUnderline/HeadingUnderline";
import SustainableCarousel from "Components/Carousel/MultiCarousel/SustainableCarousel/SustainableCarousel";
import MethodolgyIcon1 from "Assests/Icons/MethologyIcon.png";
import MethodolgyIcon2 from "Assests/Icons/MethologyGolobIcon2.png";
import MethodolgyIcon3 from "Assests/Icons/LaptopIcon.png";
import WhyThisProgram from "Components/Carousel/MultiCarousel/WhyMSR/WhyThisProgram";
import ParticipatingSchoolCarousel from "Components/Carousel/MultiCarousel/ParticipatingSchoolCarousel/ParticipatingSchoolCarousel";
import MobileTopBar from "Components/mobileTopBar/MobileTopBar";
import { useLocation } from "react-router-dom";

const OfferingPage2 = () => {
  const { pathname } = useLocation();
  // const [activeSection, setActiveSection] = useState("recommendation");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // const toggleSection = (section) => {
  //   setActiveSection(activeSection === section ? null : section);
  // };
  return (
    <div className="Offering">
      <Header />
      <div className="Offering-2">
      {/* {window.innerWidth>600? (<Header /> ): (<MobileTopBar Heading="My Social Responsibility"/>)} */}
      </div>
      <HeadingBanner
        image={offringBanner}
        heading="My Social Responsibility (MSR)"
        detail="A self-paced community engagement and sustainability program"
      />
      <div className="section1">
        {/* <img src={image} alt="background-img" className="background-img" /> */}
        <HeadingUnderline bold="The " heading="Journey" />
        <p className="section-1-p">
          An exciting culmination of online and experiential learning,designed
          for students from a multitude of backgrounds interested in climate
          change mitigation efforts and those with a desire to make meaningful
          changes in the way they approach and implement Sustainable Practices.
        </p>
        <div className="card_container">
          <div className="card card-1">
            <div className="Imgbox">
              <img src={YellowBook} alt="teahericon" className="teachericon" />
            </div>
            <div className="card-body">
              <h4>Learner</h4>
              <p>
                Expand your horizons of learning and awareness through
                assessments and community engagement.
              </p>
            </div>
          </div>
          <div className="card card-2">
            <div className="Imgbox">
              <img src={RedIcon} alt="teahericon" className="teachericon" />
            </div>
            <div className="card-body">
              <h4>Master</h4>
              <p>
                Further, put your knowledge to implementation and bring about
                change through surveys and interactions with decision makers.
              </p>
            </div>
          </div>
          <div className="card card-3">
            <div className="Imgbox">
              <img src={BlueIcon} alt="teahericon" className="teachericon" />
            </div>
            <div className="card-body">
              <h4>Preacher</h4>
              <p>
                Take up campaigning and collaborate with institutions to become
                a Planet Warrior and tell the world your story!
              </p>
            </div>
          </div>
        </div>
      </div>
      <SustainableCarousel />
      <ParticipatingSchoolCarousel />
      <div className="About-us">

      
        <iframe
          src="https://www.youtube.com/embed/GIcORly3Mw0?si=G7lyBWRNdWa7CsTD"
          title="Embedded Video"
          allowfullscreen
          className="OfferingAboutUsIframe"
        ></iframe>
        <div className="About-us-contaian-1">
          <HeadingUnderline bold="About " heading="The Program" align="left" />
          <p>
            My Social Responsibility is an activity-centric, hybrid community
            engagement program for students who are committed to the common
            good, feel responsible for the future, and are passionate about
            building a sustainable world. It is a paid program that aims at
            encouraging behavioral change and instilling a sense of
            accountability amongst individuals, towards their planet.
            <br />
            <br />
            Participating in this program will serve as a springboard for
            students’ academic and professional growth. The holistic,
            interactive, and problem-solving exposure will open doors for
            various national and international opportunities and deepen
            students’ understanding of sustainability and climate change.
            <br />
            <br />
            By the end of the program, students will be equipped with a set of
            essential skills to help them stand out during their undergraduate
            application process and later to enter the professional world. The
            program is structured to have a substantial impact on the young
            minds to pursue sustainability, as a way of living.
          </p>
        </div>
      </div>
      <div className="Methodology">
        <HeadingUnderline bold="Who Can " heading="Be a Part?" />
        <div className="MethodolodyCardContainer">
          <div className="Methodology-card Methodology-card1">
            <div className="img-contianer">
              <img
                src={MethodolgyIcon1}
                alt="MethodolgyIcon1"
                className="MethodolgyIcon1"
              />
            </div>
            <p>
              Students Aiming for higher studies in top universities around the
              world.
            </p>
          </div>
          <div className="Methodology-card Methodology-card2">
            <div className="img-contianer">
              <img
                src={MethodolgyIcon2}
                alt="MethodolgyIcon1"
                className="MethodolgyIcon1"
              />
            </div>
            <p>
              Young Enthusiasts wanting to be a part of a Sustained Community
              Intervention
            </p>
          </div>
          <div className="Methodology-card Methodology-card3">
            <div className="img-contianer">
              <img
                src={MethodolgyIcon3}
                alt="MethodolgyIcon1"
                className="MethodolgyIcon1"
              />
            </div>
            <p>
              Individuals looking for extensive in-person learning experiences.
            </p>
          </div>
        </div>
      </div>
      <WhyThisProgram />
      <div className="section2">
        {/* <div className="img-container">
          <img src={GolobImg} alt="GolobImg" className="GolobImg" />
        </div> */}

        <CertificateCarousel />

        {/* <div className="section2-content-2">
          <HeadingUnderline bold="What do " heading="you get?" align="left" />

          <div className="section-wrapper">
            <div
              className={`section-container ${
                activeSection === "recommendation" ? "active" : ""
              }`}
              onClick={() => toggleSection("recommendation")}
            >
              <h4 className="section-title">
                Recommendation for university admission process-
              </h4>
              {activeSection === "recommendation" && (
                <p className="section2-contents">
                  Get a personalized Letter of Recommendation, mentioning your
                  efforts, skills, progress and learnings, from one of the
                  fastest growing waste management companies.
                </p>
              )}
            </div>

            <div
              className={`section-container ${
                activeSection === "certificates" ? "active" : ""
              }`}
              onClick={() => toggleSection("certificates")}
            >
              <h4 className="section-title">Certificates and Medals</h4>
              {activeSection === "certificates" && (
                <p className="section2-contents">
                  Get a personalized Letter of Recommendation, mentioning your
                  efforts, skills, progress and learnings, from one of the
                  fastest growing waste management companies.
                </p>
              )}
            </div>

            <div
              className={`section-container ${
                activeSection === "skills" ? "active" : ""
              }`}
              onClick={() => toggleSection("skills")}
            >
              <h4 className="section-title">Essential Set of Skills</h4>
              {activeSection === "skills" && (
                <p className="section2-contents">
                  Get a personalized Letter of Recommendation, mentioning your
                  efforts, skills, progress and learnings, from one of the
                  fastest growing waste management companies.
                </p>
              )}
            </div>
          </div>
        </div> */}
        <div className="section2-content-2">
        <HeadingUnderline bold="What do " heading="you get?" align="left" />
          <p className="section2-contents">
          Elevate your impact! Dive into our Social Responsibility Program and gain vital skills in sustainability, waste management, and environmental awareness. Plus, receive a Personalized Letter of Recommendation (LOR), a Letter of Achievement, a Planet Warrior Certificate, and a medal. Be a hero for the planet – join us today!

          </p>
        </div>
        
      </div>
      <Footer />
    </div>
  );
};
export default OfferingPage2;
